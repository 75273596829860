import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store/store';

export const searchSlice = createSlice({
  name: 'search',
  initialState: null as null | string,
  reducers: {
    setSearch: (state, { payload }: PayloadAction<string>) => payload,
    removeSearch: () => null,
  },
});
export const { setSearch: setSearchActionCreator, removeSearch: removeSearchActionCreator } = searchSlice.actions;
export const selectSearch = (state: RootState): string | null => state.search;
export default searchSlice.reducer;
