import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import searchRecuder from '../reducers/SearchBar';
import loggedUserReducer from '../reducers/LoggedUser';

export interface State {
  search: string;
  timelineCategory: string;
  relatedSource: string;
}

export const store = configureStore({
  reducer: {
    search: searchRecuder,
    loggedUser: loggedUserReducer,
  },
  devTools: true,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
