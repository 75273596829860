import React from 'react';
import { Modal } from 'react-bootstrap';
import styles from './TimelineModalMedia.module.css';

export type TimelineModalMediaProps = {
  id: string;
  //   active: string;
  show?: boolean;
  title: string;
  link: string;
  img: any;
  description: string;
  date: string;
  onHide: () => void;
};

export const TimelineModalMedia: React.FC<TimelineModalMediaProps> = (props: TimelineModalMediaProps) => {
  return (
    <Modal
      {...props}
      className={styles.modal}
      size={props.description == '' ? undefined : 'lg'}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className={styles.upBar} closeButton>
        {props.link.includes('amazon') ? (
          <Modal.Title>Amazon Product</Modal.Title>
        ) : (
          <Modal.Title>{props.title}</Modal.Title>
        )}
      </Modal.Header>
      <Modal.Body>
        <div className={styles.mediaContainer}>
          <img src={props.img} className={styles.imgMedia} />
          <div className={styles.infoContainer}>
            {props.title == '' ? null : (
              <div>
                <h4 style={{ marginTop: '5px' }}>Title:</h4>
                {props.title}{' '}
              </div>
            )}
            {props.date == '' ? null : (
              <div>
                <h4 style={{ marginTop: '20px' }}>Date:</h4> {props.date}{' '}
              </div>
            )}
            {props.description == '' ? null : (
              <div>
                <h4 style={{ marginTop: '10px' }}>Description:</h4>
                {props.description}
              </div>
            )}
            <div className={styles.mediaButton}>
              {props.link.includes('amazon') || props.link.includes('amzn') ? (
                <a href={props.link} className={styles.modalButtonAmazon} target="_blank" rel="noreferrer">
                  Buy now
                </a>
              ) : (
                <a href={props.link} className={styles.modalButton} target="_blank" rel="noreferrer">
                  Open
                </a>
              )}
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
