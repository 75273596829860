import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import { useLocation } from 'react-router-dom';
import search from '../../assets/img/search-in-folder.svg';
import styles from './EmailNotRegistered.module.css';
import { Button } from 'react-bootstrap';

export function EmailNotRegistered(): JSX.Element {
  const location = useLocation();
  useEffect(() => {
    if (window.location.hostname === 'estory.io') ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  return (
    <div className={styles.container}>
      <div className="heading animated fadeInUp" data-wow-duration="1000ms" data-wow-delay="300ms">
        <div>
          <div>
            <h2>The email address you have entered has not been registered with eStory</h2>
          </div>
        </div>

        <div className="text-center our-services">
          <div className="row">
            <div className="col-sm-12 animated fadeInDown" data-wow-duration="1000ms" data-wow-delay="300ms">
              <div>
                <img src={search} width="120" />
              </div>
              <div className={styles.serviceInfo}>
                <h3>You need to register your email address to activate your account.</h3>
                <Button variant="success" data-scroll className="btn btn-start animated fadeInUpBig" href="/login">
                  Register
                </Button>
                <Button variant="success" data-scroll className="btn btn-start2 animated fadeInUpBig" href="/home">
                  Back to eStory
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
