import React, { useEffect, useRef, useState } from 'react';
import { useContainerDimensions } from '../../../hooks/useContainerDimensions';
import useKeyPress from '../../../hooks/useKeyPress';
import { Event_Interface } from '../../../interfaces/Event.interface';
import { generateDateString } from '../../../utils/date.util';
import { EventCard } from '../EventCard/EventCard';
import styles from './EventDisplay.module.scss';
import { NavigationIndicator } from '../NavigationIndicator/NavigationIndicator';
import { NavigationLine } from '../NavigationLine/NavigationLine';
import { generateDateTranslate, initiateEvents, left } from '../util';

interface EventsDisplayProps {
  events: Event_Interface[];
}

export enum Position {
  RIGHT,
  LEFT,
}
export const EventsDisplay: React.FunctionComponent<EventsDisplayProps> = (props: EventsDisplayProps) => {
  const [selectedKey, setSelectedKey] = useState(0);
  const [hoverKey, setHoverKey] = useState<null | number>(null);
  const componentRef = useRef<HTMLDivElement>(null);
  const { width } = useContainerDimensions(componentRef);
  const [events] = useState<{ event: Event_Interface; date: Date; position: number }[]>(initiateEvents(props.events));
  const LEFT = useKeyPress('ArrowLeft');
  const RIGHT = useKeyPress('ArrowRight');
  const [touchStart, setTouchStart] = React.useState(0);
  const [touchEnd, setTouchEnd] = React.useState(0);
  const [touchStartY, setTouchStartY] = React.useState(0);
  const [touchEndY, setTouchEndY] = React.useState(0);

  const getDeviceType = () => {
    const ua = navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
      return 'tablet';
    }
    if (
      /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)
    ) {
      return 'mobile';
    }
    return 'desktop';
  };
  const leftWithCardIncluded = function (position: number): number {
    return left(width, 15, 400, true, position);
  };
  const leftWithoutCardIncluded = function (position: number): number {
    return left(width, 15, 400, false, position);
  };

  const position = function (eventKey: number): Position | undefined {
    let position: Position | undefined = undefined;
    if (eventKey > selectedKey) position = Position.RIGHT;
    else if (eventKey < selectedKey) position = Position.LEFT;
    return position;
  };

  function handleTouchStart(e: React.TouchEvent) {
    window.addEventListener('touchmove', function (e) {
      e.preventDefault();
    }); // mobile
    // document.body.style.overflowY = 'hidden';
    setTouchStart(e.targetTouches[0].clientX);
    setTouchStartY(e.targetTouches[0].clientY);
  }

  function handleTouchMove(e: React.TouchEvent) {
    if (touchStartY - touchEndY > 1000 || touchStartY - touchEndY < -1000) {
      // window.removeEventListener('touchmove', function (e) {
      //   e.preventDefault();
      // });
      //document.body.style.overflowY = 'scroll';
    }
    if (touchStart - touchEnd < 10 || touchStart - touchEnd > -10) {
      // window.removeEventListener('touchmove', function (e) {
      //   e.preventDefault();
      // });
      //document.body.style.overflowY = 'scroll';
    }
    setTouchEnd(e.targetTouches[0].clientX);
    setTouchEndY(e.targetTouches[0].clientY);
  }

  function handleTouchEnd(e: React.TouchEvent) {
    if (touchStart - touchEnd > 50) {
      nextEvent();
    }
    if (touchStart - touchEnd < -50) {
      previousEvent();
    }
  }

  useEffect(() => {
    if (LEFT) {
      previousEvent();
    } else if (RIGHT) {
      nextEvent();
    }
  }, [LEFT, RIGHT]);

  function previousEvent() {
    selectedKey - 1 >= 0 && setSelectedKey(selectedKey - 1);
  }

  function nextEvent() {
    selectedKey + 1 < events.length && setSelectedKey(selectedKey + 1);
  }

  return (
    <div className={styles.componentRef} ref={componentRef}>
      <div className={styles.bgClickActionContainer}>
        <div
          className={styles.bgClickActionLeft}
          onClick={() => previousEvent()}
          onTouchStart={() => null}
          onTouchMove={() => null}
          onTouchEnd={() => previousEvent()}
        ></div>
        <div className={styles.bgClickActionRight} onClick={() => nextEvent()}></div>
      </div>
      {props.events.length > 0 ? (
        <div className={styles.displayPreviewContainer}>
          <div
            className={styles.containerEvent}
            onTouchStart={(touchStartEvent) => handleTouchStart(touchStartEvent)}
            onTouchMove={(touchMoveEvent) => handleTouchMove(touchMoveEvent)}
            onTouchEnd={(touchEndEvent) => handleTouchEnd(touchEndEvent)}
          >
            <div className={styles.bgClickActionContainer}>
              <div className={styles.bgClickActionLeft} onClick={() => previousEvent()}></div>
              <div
                className={styles.bgClickActionRight}
                onClick={() => nextEvent()}
                onTouchStart={() => null}
                onTouchMove={() => null}
                onTouchEnd={() => nextEvent()}
              ></div>
            </div>
            {events.map((event, key) => {
              return getDeviceType() == 'mobile' ? (
                <div className={styles.event} key={key} style={{ left: '0px' }}>
                  <EventCard {...event.event} selected={key === selectedKey} position={position(key)} />
                </div>
              ) : (
                <div className={styles.event} key={key} style={{ left: `${leftWithCardIncluded(event.position)}px` }}>
                  <EventCard {...event.event} selected={key === selectedKey} position={position(key)} />
                </div>
              );
            })}
          </div>

          <NavigationLine cursorPosition={leftWithoutCardIncluded(events[selectedKey].position)}>
            {events.length === 1
              ? events.map((event, key) => {
                  return (
                    <>
                      <NavigationIndicator
                        keyNumber={key}
                        isSelected={selectedKey === key}
                        isBeforeSelected={selectedKey > key}
                        left={50 + leftWithoutCardIncluded(event.position)}
                        onClick={() => setSelectedKey(key)}
                        onHover={(e) => (e.type === 'mouseenter' ? setHoverKey(key) : setHoverKey(null))}
                      />
                      {selectedKey === key && (
                        <div
                          key={key}
                          className={styles.dotDateContainer}
                          style={{
                            left: `${50 + leftWithoutCardIncluded(event.position)}px`,
                            transform: `translate(${generateDateTranslate(props.events[selectedKey])}px, 20px) `,
                          }}
                        >
                          <div className={styles.dotDate}>
                            {generateDateString(
                              props.events[selectedKey].year,
                              props.events[selectedKey].month,
                              props.events[selectedKey].day,
                              props.events[selectedKey].display_roman_numbers,
                            )}
                          </div>
                        </div>
                      )}
                      {hoverKey === key && (
                        <div
                          key={key}
                          className={styles.dotDateContainer}
                          style={{
                            left: `${leftWithoutCardIncluded(event.position)}px`,
                            transform: `translate(${generateDateTranslate(props.events[hoverKey])}px, 20px)`,
                          }}
                        >
                          <div className={styles.dotDate}>
                            {generateDateString(
                              props.events[hoverKey].year,
                              props.events[hoverKey].month,
                              props.events[hoverKey].day,
                              props.events[hoverKey].display_roman_numbers,
                            )}
                          </div>
                        </div>
                      )}
                    </>
                  );
                })
              : events.map((event, key) => {
                  return (
                    <>
                      <NavigationIndicator
                        keyNumber={key}
                        isSelected={selectedKey === key}
                        isBeforeSelected={selectedKey > key}
                        left={leftWithoutCardIncluded(event.position)}
                        onClick={() => setSelectedKey(key)}
                        onHover={(e) => (e.type === 'mouseenter' ? setHoverKey(key) : setHoverKey(null))}
                      />
                      {selectedKey === key && (
                        <div
                          key={key}
                          className={styles.dotDateContainer}
                          style={{
                            left: `${leftWithoutCardIncluded(event.position)}px`,
                            transform: `translate(${generateDateTranslate(props.events[selectedKey])}px, 20px)`,
                          }}
                        >
                          <div className={styles.dotDate}>
                            {generateDateString(
                              props.events[selectedKey].year,
                              props.events[selectedKey].month,
                              props.events[selectedKey].day,
                              props.events[selectedKey].display_roman_numbers,
                            )}
                          </div>
                        </div>
                      )}
                      {hoverKey === key && (
                        <div
                          key={key + events.length}
                          className={styles.dotDateContainer}
                          style={{
                            left: `${leftWithoutCardIncluded(event.position)}px`,
                            transform: `translate(${generateDateTranslate(props.events[hoverKey])}px, 20px)`,
                          }}
                        >
                          <div className={styles.dotDate}>
                            {generateDateString(
                              props.events[hoverKey].year,
                              props.events[hoverKey].month,
                              props.events[hoverKey].day,
                              props.events[hoverKey].display_roman_numbers,
                            )}
                          </div>
                        </div>
                      )}
                    </>
                  );
                })}
          </NavigationLine>
        </div>
      ) : (
        <h1 className={styles.noEvent}>No Events for this timeline</h1>
      )}
    </div>
  );
};
