import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import styles from './Footer.module.css';
import { faFacebookF, faTwitter } from '@fortawesome/free-brands-svg-icons';

export function Footer(): JSX.Element {
  const dt = new Date();
  return (
    <div>
      <div className={styles.footerSocialNetwork}>
        <p>© {dt.getFullYear()}, eStory Limited.</p>
        <a
          href="https://www.facebook.com/eStory.io"
          className={`${styles.footerSocialIcon} ${styles.footerSocialIconFacebook}`}
        >
          <FontAwesomeIcon icon={faFacebookF} />
        </a>
        <a
          href="https://twitter.com/_eStory_"
          className={`${styles.footerSocialIcon} ${styles.footerSocialIconTwitter}`}
        >
          <FontAwesomeIcon icon={faTwitter} />
        </a>
      </div>
      <div className={styles.footerLinks}>
        <a className={styles.footerLink} href="mailto:team@estory.io">
          Contact
        </a>
        <p>|</p>
        <a className={styles.footerLink} href="/terms-of-service" target="_blank">
          Term of Service
        </a>
        <p>|</p>
        <a className={styles.footerLink} href="/privacy-policy" target="_blank">
          Privacy Policy
        </a>
      </div>
    </div>
  );
}
