import React, { useState, useEffect } from 'react';
import { Event_Interface } from '../../../interfaces/Event.interface';
import { ImageChoice } from '../../../components/ImagePicker/ImagePicker';
import styles from './CardEventCreation.module.css';
import { set } from 'lodash';
import { romanToNumber, convertToRoman } from '../../../utils/date.util';

export type CardEventCreationProps = {
  timelineId: number;
  title: string;
  events: Event_Interface[];
  event?: Event_Interface;
  addEvent: (
    id_timeline: number,
    title: string,
    description: string,
    day: number,
    month: number,
    year: number,
    display_roman_numbers: boolean,
    x_position: number,
    y_position: number,
    img: string,
  ) => void;
  modifyEvent: (
    id_event: number,
    title: string,
    description: string,
    day: number,
    month: number,
    year: number,
    display_roman_numbers: boolean,
    x_position: number,
    y_position: number,
    img: string,
  ) => void;
  setIndex: (index: number) => void;
};

export const CardEventCreation: React.FunctionComponent<CardEventCreationProps> = (props: CardEventCreationProps) => {
  const [title, setTitle] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [day, setDay] = useState<number>(0);
  const [month, setMonth] = useState<number>(0);
  const [year, setYear] = useState<number | undefined>(undefined);
  const [yearDisplay, setYearDisplay] = useState<string>('');
  const [imgUrl, setImgUrl] = useState<string>('');
  const [christTime, setChristTime] = useState<string>('');
  const [display_roman_numbers, setdisplay_roman_numbers] = useState<boolean>(false);
  const [x_position, setXPosition] = useState<number>(0);
  const [y_position, setYPosition] = useState<number>(0);
  const [mode, setMode] = useState<boolean>(true);
  const setImage = (image: string) => {
    setImgUrl(image);
  };
  function addToPreview() {
    props.addEvent(
      props.timelineId,
      title,
      description,
      !!day ? day : Number(0),
      !!month ? month : Number(0),
      !!year ? year : Number(0),
      display_roman_numbers,
      x_position,
      y_position,
      imgUrl,
    );
    setMode(true);
    setYearDisplay('');
    setYear(undefined);
  }
  function saveChanges() {
    props.setIndex(-1);
    setYearDisplay('');
    setYear(undefined);
    setMode(true);
  }

  function bce() {
    setMode(false);
    setChristTime('BCE');
  }
  function ad() {
    setMode(true);
    setChristTime('AD');
  }

  const handleYearDisplay = (value: string) => {
    if (value === '') {
      setYear(undefined);
      setYearDisplay('');
      setdisplay_roman_numbers(false);
      if (!mode) {
        ad();
      }
      return;
    }
    if (value === '-') {
      setYearDisplay('-');
      setYear(undefined);
      setdisplay_roman_numbers(false);
      if (mode) {
        bce();
      }
      return;
    }
    if (/^(-?[0-9]{0,4}$)/.test(value) && !isNaN(Number(value))) {
      setdisplay_roman_numbers(false);
      setYearDisplay(value);
      setYear(Number(value));
      if (mode && value.startsWith('-')) {
        bce();
      } else if (!mode && !value.startsWith('-')) {
        ad();
      }
      return;
    }
    if (/^(-?[XVI]{0,5}$)/.test(value)) {
      const yearInt: number | undefined = romanToNumber(value);
      const negative: boolean = value.startsWith('-');
      if (yearInt !== undefined) {
        setdisplay_roman_numbers(true);
        setYearDisplay(value);
        setYear(yearInt);
        if (mode && negative) {
          bce();
        } else if (!mode && !negative) {
          ad();
        }
      }
    }
  };
  useEffect(() => {
    if (year === undefined) {
      christTime === 'BCE' ? setYearDisplay('-') : setYearDisplay('');
    }
    if (year !== undefined) {
      if (display_roman_numbers) {
        if (year > 0 && christTime === 'BCE') {
          const yearInt = romanToNumber('-' + yearDisplay);
          if (yearInt !== undefined) {
            setYear(yearInt);
            setYearDisplay('-' + yearDisplay);
          } else {
            setYearDisplay('');
            setYear(undefined);
          }
        }
        if (year < 0 && christTime === 'AD') {
          const yearInt = romanToNumber(yearDisplay.slice(1));
          if (yearInt !== undefined) {
            setYear(yearInt);
            setYearDisplay(yearDisplay.slice(1));
          } else {
            setYearDisplay('');
            setYear(undefined);
          }
        }
      } else {
        if (year > 0 && christTime === 'BCE') {
          setYear(-year);
          setYearDisplay('-' + year);
        }
        if (year < 0 && christTime === 'AD') {
          setYear(-year);
          setYearDisplay((-year).toString());
        }
      }
    }
  }, [christTime]);

  const ChristTimeField = () => (
    <div className={styles.monthFieldContainer}>
      <div className={styles.buttonDiv}>
        <button className={mode ? styles.BceButton : styles.BceButtonSelected} value={'BCE'} onClick={() => bce()}>
          BCE
        </button>
        <button className={mode ? styles.AdButtonSelected : styles.AdButton} value={'AD'} onClick={() => ad()}>
          AD
        </button>
      </div>
    </div>
  );
  const MonthField = () => (
    <div className={styles.monthFieldContainer}>
      <select
        id="month"
        className={styles.field3}
        placeholder={'MM'}
        defaultValue={month}
        onChange={(ev: React.ChangeEvent<HTMLSelectElement>) =>
          ev.target.value !== String(0) ? setMonth(Number(ev.target.value)) : setMonth(0)
        }
      >
        <option className={styles.options} value={'0'}></option>
        {[...Array(12)].map((_, index) => (
          <option
            key={index + 1}
            className={styles.options}
            value={index + 1}
            onChange={(ev: React.ChangeEvent<HTMLOptionElement>) => setMonth(Number(ev.target.value))}
          >
            {index + 1}
          </option>
        ))}
      </select>
      <label className={styles.label2} htmlFor="month">
        Month
      </label>
    </div>
  );

  const DayField = () => (
    <div className={styles.dayFieldContainer}>
      <select
        id="day"
        className={styles.field3}
        placeholder={'DD'}
        defaultValue={day}
        onChange={(ev: React.ChangeEvent<HTMLSelectElement>) => setDay(Number(ev.target.value))}
      >
        <option className={styles.options} value={'0'}></option>
        {[...Array(31)].map((_, index) => (
          <option
            key={index + 1}
            className={styles.options}
            value={index + 1}
            onChange={(ev: React.ChangeEvent<HTMLOptionElement>) => setDay(Number(ev.target.value))}
          >
            {index + 1}
          </option>
        ))}
      </select>
      <label className={styles.label2} htmlFor="day">
        Day
      </label>
    </div>
  );

  useEffect(() => {
    if (props.events) {
      setTitle('');
      setDescription('');
      setDay(0);
      setMonth(0);
      setChristTime('');
      setYear(0);
      setImgUrl('');
      setdisplay_roman_numbers(false);
      setXPosition(0);
      setYPosition(0);
    }
    if (props.event) {
      setTitle(!!props.event ? props.event.title : '');
      setDescription(!!props.event ? props.event.description : '');
      setImgUrl(!!props.event ? props.event.img : '');
      !!props.event ? setDay(props.event.day) : setDay(0);
      !!props.event ? setMonth(Number(props.event.month)) : setMonth(0);
      !!props.event ? setYear(Number(props.event.year)) : setYear(0);
      setdisplay_roman_numbers(!!props.event ? props.event.display_roman_numbers : false);
      // setXPosition(0);
      // setYPosition(0);
      !!props.event ? setXPosition(props.event.x_position) : setXPosition(0);
      !!props.event ? setYPosition(props.event.y_position) : setYPosition(0);
    }
  }, [props.events, props.event]);

  useEffect(() => {
    if (year) {
      if (isNaN(Number(year)) || year === 0) {
        setYear(undefined);
        setYearDisplay('');
      } else {
        setYear(year);
        setYearDisplay(year.toString());
      }
    }
  }, [year]);

  return (
    <div className={styles.eventAddContainer}>
      <div className={styles.cardContainer}>
        <div className={styles.card}>
          <div className={styles.date}>
            <div className={styles.dateFieldContainer}>
              <div>
                <input
                  className={styles.field4}
                  maxLength={5}
                  id="century"
                  value={yearDisplay}
                  required
                  onChange={(event) => {
                    handleYearDisplay(event.target.value);
                  }}
                />
                <label className={styles.label3} htmlFor="year">
                  Year/Century
                </label>
              </div>
            </div>
            <ChristTimeField />
            {!display_roman_numbers && <MonthField />}
            {!display_roman_numbers && <DayField />}
          </div>
          <div className={styles.eventTitle}>
            <input
              className={styles.field2}
              type="text"
              id="eventtitle"
              value={title}
              maxLength={60}
              onChange={(ev: React.ChangeEvent<HTMLInputElement>) => setTitle(ev.target.value)}
              required
            />
            <label className={styles.label} htmlFor="eventtitle">
              Event title (max 60 char.)
            </label>
          </div>
          <div className={styles.descriptionEventArea}>
            <textarea
              className={styles.field2}
              id="eventdescription"
              value={description}
              maxLength={300}
              onChange={(ev: React.ChangeEvent<HTMLTextAreaElement>) => setDescription(ev.target.value)}
              required
            />
            <label className={styles.label2} htmlFor="eventdescription">
              Event description (max 300 char.)
            </label>
          </div>
          <div className={styles.imageEventChoice}>
            <ImageChoice imgUrl={imgUrl} setImage={(image) => setImage(image)} title={title} />
          </div>
        </div>
      </div>
      <div className={styles.buttonAddEvent}>
        {!!props.event ? (
          title !== '' && year !== 0 && year !== undefined && !isNaN(year) ? (
            <div className={styles.modifyEventInPreview}>
              <button className={styles.buttonCancel} onClick={() => saveChanges()}>
                Cancel
              </button>
              <button
                className={styles.buttonModifyEvent}
                onClick={() => {
                  if (props.event) {
                    props.modifyEvent(
                      Number(props.event?.id),
                      title,
                      description,
                      !!day ? Number(day) : Number(0),
                      !!month ? Number(month) : Number(0),
                      !!year ? Number(year) : Number(0),
                      display_roman_numbers,
                      x_position,
                      y_position,
                      imgUrl,
                    );
                    saveChanges();
                  }
                }}
              >
                Save changes
              </button>
            </div>
          ) : (
            <div className={styles.modifyEventInPreview}>
              <button className={styles.buttonCancel} onClick={() => props.setIndex(-1)}>
                Cancel
              </button>
              <button className={styles.buttonModifyEvent} disabled>
                Save changes
              </button>
            </div>
          )
        ) : title !== '' && year !== 0 && year !== undefined ? (
          <button className={styles.buttonAddToPreview} onClick={() => addToPreview()}>
            Add to preview
          </button>
        ) : (
          <button className={styles.buttonAddToPreview} disabled>
            Add to preview
          </button>
        )}
      </div>
    </div>
  );
};
