import React from 'react';
import loadingImg from '../../assets/img/Logo_eStory.png';
import styles from './LoadingAI.module.css';
import { patienceQuotes } from '../../utils/famousQuotes';
import { getImageFromAuthor } from '../../utils/famousQuotes';

export type LoadingAIProps = {
  show: boolean;
};

export const LoadingAI: React.FunctionComponent<LoadingAIProps> = (props: LoadingAIProps) => {
  if (!props.show) {
    return null;
  }

  const randomQuote = patienceQuotes[Math.floor(Math.random() * patienceQuotes.length)];
  const famousPeopleImages = getImageFromAuthor(randomQuote.author);

  return (
    <div className={`${styles.loadingContainer} ${props.show ? styles.show : styles.hide}`}>
      <div className={styles.loadingContent}>
        <div className={styles.container}>
          <div>
            <h1> Please wait, your timeline is being generated </h1>
            <h2> This might take a few seconds, please be patient... </h2>
            <div className={styles.pulse}>
              <img src={loadingImg} className={styles.imgLoading} />
            </div>
            <div className={styles.quoteContainer}>
              <div className={styles.imgContainer}>
                <img src={famousPeopleImages} alt="quote image" className={styles.img} />
              </div>
              <div className={styles.textContainer}>
                <p className={styles.quote}>&quot;{randomQuote.quote}&quot;</p>
                <p className={styles.author}> - {randomQuote.author}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
