import React from 'react';
import styles from './ConfirmModal.module.css';
import { Modal, Button } from 'react-bootstrap';

export type ConfirmModalProps = {
  text: string;
  show: boolean;
  closeButtonText: string;
  confirmButtonText: string;
  handleClose: () => void;
  handleConfirm: () => void;
};

export const ConfirmModal: React.FunctionComponent<ConfirmModalProps> = (props: ConfirmModalProps) => {
  const { show, handleClose, handleConfirm, closeButtonText, confirmButtonText, text } = props;

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton className={styles.header}>
          <Modal.Title className={styles.title}>{text}</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button onClick={handleConfirm} className={styles.confirmButton}>
            {confirmButtonText}
          </Button>
          <Button onClick={handleClose} className={styles.cancelButton}>
            {closeButtonText}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
