/* eslint-disable react/prop-types */
import React, { useState, useRef, useEffect } from 'react';
import styles from './CategorySelect.module.css';
import { BiCaretDown } from 'react-icons/bi';
import { AiOutlineClose } from 'react-icons/ai';
import { listCategoryBD, listCategoryDisplayed } from '../../../utils/categories';

export type CategoryProps = {
  categories: string[];
  onSetCategory: (categories: string[]) => void;
};

export const CategorySelect: React.FunctionComponent<CategoryProps> = (props: CategoryProps) => {
  const [hide, setHide] = useState<boolean>(false);
  const [possibleCategories, modifyPossibleCategories] = useState<Array<string>>([
    'Famous people',
    'Historical episodes',
    'Sports and entertainment',
    'Science and technologies',
    'Art and literature',
    'Places',
    'Quirky',
    'Other',
  ]);

  const addCategory = (value: string) => {
    possibleCategories.splice(possibleCategories.indexOf(value), 1);
    modifyPossibleCategories(possibleCategories.slice(0));
    for (let i = 0; i < listCategoryDisplayed.length; i++) {
      if (value === listCategoryDisplayed[i]) {
        const updatedCategories = [...props.categories, listCategoryBD[i]];
        props.onSetCategory(updatedCategories);
        break;
      }
    }
  };

  const removeCategory = (value: string) => {
    const updatedCategories = props.categories.filter((cat) => {
      for (let i = 0; i < listCategoryBD.length; i++) {
        if (value === listCategoryBD[i] || value === listCategoryBD[i] + ' ' || value === ' ' + listCategoryBD[i]) {
          if (cat === value) {
            if (!possibleCategories.includes(listCategoryDisplayed[i])) {
              possibleCategories.push(listCategoryDisplayed[i]);
              modifyPossibleCategories(possibleCategories.slice(0));
            }
            return false;
          }
        }
      }
      return true;
    });
    props.onSetCategory(updatedCategories);
  };

  const TimelineCategoriesList = () => (
    <>
      {props.categories.length === 0 ? (
        <p className={styles.rien}>Choose from the list</p>
      ) : (
        <>
          {props.categories.map((category) => {
            let CategoryToDisplay;
            for (let i = 0; i < listCategoryBD.length; i++) {
              if (category === listCategoryBD[i]) {
                CategoryToDisplay = listCategoryDisplayed[i];
                break;
              }
            }
            if (CategoryToDisplay) {
              return (
                <div className={styles.category} key={category}>
                  <button className={styles.button} value={category} onClick={() => removeCategory(category)}>
                    {CategoryToDisplay}
                    <AiOutlineClose />
                  </button>
                </div>
              );
            }
          })}
        </>
      )}
    </>
  );

  const CategoriesToAddList = () => (
    <>
      {possibleCategories
        .filter(
          (category) =>
            !listCategoryBD.some(
              (bdCategory) =>
                props.categories.includes(bdCategory) &&
                category === listCategoryDisplayed[listCategoryBD.indexOf(bdCategory)],
            ),
        )
        .map((category, index) => (
          <div className={styles.category} key={index}>
            <button className={styles.button} value={category} onClick={() => addCategory(category)}>
              {category}
            </button>
          </div>
        ))}
    </>
  );
  const node = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (node.current && !node.current.contains(event.target as Node)) {
      hideCategoriesToAdd();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const hideOrShowCategoriesToAdd = () => {
    setHide(!hide);
  };

  const showCategoriesToAdd = () => {
    setHide(true);
  };

  const hideCategoriesToAdd = () => {
    setHide(false);
  };

  return (
    <div className={styles.categoriesContainer} ref={node}>
      <div className={styles.myCategories}>
        <div className={styles.categoryAdded} onClick={() => showCategoriesToAdd()}>
          <TimelineCategoriesList />
        </div>
        <div className={styles.categoryIcon} onClick={() => hideOrShowCategoriesToAdd()}>
          <BiCaretDown />
        </div>
      </div>
      {hide ? (
        <div className={styles.categoriesToAdd}>
          <CategoriesToAddList />
        </div>
      ) : null}
    </div>
  );
};
