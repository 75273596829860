import React, { useEffect, useState } from 'react';
import article from '../../../assets/img/newspaper.svg';
import movie from '../../../assets/img/movie.svg';
import book from '../../../assets/img/book.svg';
import styles from './MediaStep.module.css';
import { AddArticle } from './AddArticle';
import { deleteMedia, postMedia } from '../../../api/media.api';
import { Media_Interface } from '../../../interfaces/Media.interface';
import { BsX } from 'react-icons/bs';
import { AddBook } from './AddBook';
import { AddMovie } from './AddMovie';
import { Media } from '../../../models/Media.model';

export type MediaStepProps = {
  id_timeline: number;
  title: string;
  medias: Array<Media_Interface>;
  addMedia: (media: Media_Interface) => void;
  removeMedia: (id: bigint) => void;
};

export class ArticleItem {
  title: string;
  url: string;
  urlToImage: string;
  description: string;
  date: string;
  source: string;

  constructor(title: string, url: string, urlToImage: string, description: string, date: string, source: string) {
    this.title = title;
    this.url = url;
    this.urlToImage = urlToImage;
    this.description = description;
    this.date = date;
    this.source = source;
  }
}

export class MovieItem {
  title: string;
  url: string;
  urlToImage: string;
  description: string;
  date: string;
  source: string;

  constructor(title: string, url: string, urlToImage: string, description: string, date: string, source: string) {
    this.title = title;
    this.url = url;
    this.urlToImage = urlToImage;
    this.description = description;
    this.date = date;
    this.source = source;
  }
}

export class BookItem {
  title: string;
  url: string;
  urlToImage: string;
  description: string;
  date: string;
  source: string;

  constructor(title: string, url: string, urlToImage: string, description: string, date: string, source: string) {
    this.title = title;
    this.url = url;
    this.urlToImage = urlToImage;
    this.description = description;
    this.date = date;
    this.source = source;
  }
}

export const MediaStep: React.FunctionComponent<MediaStepProps> = (props: MediaStepProps) => {
  const [mySource, setMySource] = useState<string>('');
  const [articles, setArticles] = useState<Array<Media_Interface>>([]);
  const [movies, setMovies] = useState<Array<Media_Interface>>([]);
  const [books, setBooks] = useState<Array<Media_Interface>>([]);
  const [modalArticleShow, setModalArticleShow] = useState<boolean>(false);
  const [modalMovieShow, setModalMovieShow] = useState<boolean>(false);
  const [modalBookShow, setModalBookShow] = useState<boolean>(false);

  useEffect(() => {
    props.medias?.map((media: Media_Interface) => {
      if (media.type === 'article') {
        articles.push(media);
        setArticles(articles.slice(0));
      } else {
        if (media.type === 'book') {
          books.push(media);
          setBooks(books.slice(0));
        } else {
          movies.push(media);
          setMovies(movies.slice(0));
        }
      }
    });
  }, []);

  const setMovie = () => {
    setMySource('Movie');
    setModalMovieShow(true);
  };
  const setBook = () => {
    setMySource('Book');
    setModalBookShow(true);
  };
  const setArticle = () => {
    setMySource('Article');
    setModalArticleShow(true);
  };

  const postArticle = async (
    id: number,
    title: string,
    link: string,
    description: string,
    date: string,
    img: string,
    source: string,
  ) => {
    await postMedia(id, title, link, img, description, date, 'article', source).then((result) => {
      articles.push(result);
      setArticles(articles.slice(0));
      props.addMedia(new Media(result.id, title, link, img, description, date, 'article', source));
    });
  };

  const removeArticle = (id: number) => {
    deleteMedia(Number(id)).then((result) => {
      props.removeMedia(result.id);
      articles.splice(articles.indexOf(result), 1);
      setArticles(articles.slice(0));
    });
  };

  const postMovie = async (
    id: number,
    title: string,
    link: string,
    description: string,
    date: string,
    img: string,
    source: string,
  ) => {
    await postMedia(id, title, link, img, description, date, 'movie', source).then((result) => {
      movies.push(result);
      setMovies(movies.slice(0));
      props.addMedia(new Media(result.id, title, link, img, description, date, 'movie', source));
    });
  };

  const removeMovie = (id: number) => {
    deleteMedia(Number(id)).then((result) => {
      props.removeMedia(result.id);
      movies.splice(movies.indexOf(result), 1);
      setMovies(movies.slice(0));
    });
  };

  const postBook = async (
    id: number,
    title: string,
    link: string,
    description: string,
    date: string,
    img: string,
    source: string,
  ) => {
    await postMedia(id, title, link, img, description, date, 'book', source).then((result) => {
      books.push(result);
      setBooks(books.slice(0));
      props.addMedia(new Media(result.id, title, link, img, description, date, 'book', source));
    });
  };

  const removeBook = (id: number) => {
    deleteMedia(Number(id)).then((result) => {
      props.removeMedia(result.id);
      books.splice(books.indexOf(result), 1);
      setBooks(books.slice(0));
    });
  };

  return (
    <div className={styles.blockForm}>
      <div className={styles.title}>
        <h3 className={styles.h3}>List the movies, books and articles</h3>
        <h3 className={styles.h3}>you recommend about this story</h3>
      </div>
      <div className={styles.boxes}>
        <div className={styles.box} id="movie">
          <button type="button" className={styles.button} onClick={() => setMovie()}>
            <h4 className={styles.h4}>Add a movie</h4>
            <img className={styles.image2} src={movie} alt="Movie Icon" />
          </button>
        </div>
        <div className={styles.box} id="book">
          <button type="button" className={styles.button} onClick={() => setBook()}>
            <h4 className={styles.h4}>Add a book</h4>
            <img className={styles.image} src={book} alt="Book Icon" />
          </button>
        </div>
        <div className={styles.box} id="article">
          <button type="button" className={styles.button} onClick={() => setArticle()}>
            <h4 className={styles.h4}>Add an article</h4>
            <img className={styles.image2} src={article} alt="Article Icon"></img>
          </button>
        </div>
      </div>
      <div className={styles.boxes}>
        <div className={styles.box2}>
          {movies.map((element: Media_Interface) => (
            // eslint-disable-next-line react/jsx-key
            <div className={styles.mediaChoice}>
              <a className={styles.mediaTitle} href={element.link} target="_blank" rel="noreferrer">
                {element.link.includes('amazon') ? element.link : element.title ? element.title : element.link}
              </a>
              <BsX className={styles.deleteMedia} onClick={() => removeMovie(Number(element.id))} />
            </div>
          ))}
        </div>
        <div className={styles.box2}>
          {books.map((element: Media_Interface) => (
            // eslint-disable-next-line react/jsx-key
            <div className={styles.mediaChoice}>
              <a className={styles.mediaTitle} href={element.link} target="_blank" rel="noreferrer">
                {element.link.includes('amazon') ? element.link : element.title ? element.title : element.link}
              </a>
              <BsX className={styles.deleteMedia} onClick={() => removeBook(Number(element.id))} />
            </div>
          ))}
        </div>
        <div className={styles.box2}>
          {articles.map((element: Media_Interface) => (
            // eslint-disable-next-line react/jsx-key
            <div className={styles.mediaChoice}>
              <a className={styles.mediaTitle} href={element.link} target="_blank" rel="noreferrer">
                {element.link.includes('amazon') ? element.link : element.title ? element.title : element.link}
              </a>
              <BsX className={styles.deleteMedia} onClick={() => removeArticle(Number(element.id))} />
            </div>
          ))}
        </div>
      </div>
      <AddMovie
        title={props.title}
        item={mySource}
        show={modalMovieShow}
        onHide={() => setModalMovieShow(false)}
        onAddItem={(element: MovieItem) => {
          if (mySource === 'Movie') {
            postMovie(
              props.id_timeline,
              element.title,
              element.url,
              element.description,
              element.date,
              element.urlToImage,
              element.source,
            );
          }
        }}
      />
      <AddBook
        title={props.title}
        item={mySource}
        show={modalBookShow}
        onHide={() => setModalBookShow(false)}
        onAddItem={(element: BookItem) => {
          if (mySource === 'Book') {
            postBook(
              props.id_timeline,
              element.title,
              element.url,
              element.description,
              element.date,
              element.urlToImage,
              element.source,
            );
          }
        }}
      />
      <AddArticle
        title={props.title}
        item={mySource}
        show={modalArticleShow}
        onHide={() => setModalArticleShow(false)}
        onAddItem={(element: ArticleItem) => {
          if (mySource === 'Article') {
            postArticle(
              props.id_timeline,
              element.title,
              element.url,
              element.description,
              element.date,
              element.urlToImage,
              element.source,
            );
          }
        }}
      />
    </div>
  );
};
