import axios, { AxiosRequestConfig } from 'axios';
import { MediaOpenGraph_Interface } from '../interfaces/MediaOpenGraph.interface';

export async function getUrlInfo(link: string): Promise<MediaOpenGraph_Interface> {
  return new Promise((resolve, reject) => {
    try {
      const url = `${process.env.REACT_APP_SERV_HOST}/opengraphscraper`;

      // solution temporaire : la route de l'api de prod ne marche pas donc on redirige vers l'api de test pour l'instant.
      // erreur sur l'api de prod : 'error while loading shared libraries: libdbus-glib-1.so.2: cannot open shared object file: No such file or directory'

      const config: AxiosRequestConfig = {
        method: 'get',
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true,
        params: { url: link },
      };
      axios.get(url, config).then((result) => {
        const ogInfo: MediaOpenGraph_Interface = result.data.data;
        resolve(ogInfo);
      });
    } catch (err) {
      reject(err);
    }
  });
}
