/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import styles from './TypeSelect.module.css';
import { BiCaretDown } from 'react-icons/bi';
import { AiOutlineClose } from 'react-icons/ai';

export type TypeProps = {
  types: string;
  onSetType: (type: string) => void;
};

const fictionType = (type: string) => {
  let typeFiction = type;
  if (type === 'Adventure') {
    typeFiction = '🤠 Adventure';
  }
  if (type === 'Romance') {
    typeFiction = '💞 Romance';
  }
  if (type === 'Comedy') {
    typeFiction = '🤣 Comedy';
  }
  if (type === 'Drama') {
    typeFiction = '🎭 Drama';
  }
  if (type === 'Mystery') {
    typeFiction = '🧐 Mystery';
  }
  if (type === 'Fantasy') {
    typeFiction = '🧜‍♀️ Fantasy';
  }
  if (type === 'Historical') {
    typeFiction = '🤴 Historical';
  }
  if (type === 'Horror') {
    typeFiction = '😱 Horror';
  }
  if (type === 'Science Fiction') {
    typeFiction = '🤖 Science Fiction';
  }
  return typeFiction;
};

export const TypeSelect: React.FunctionComponent<TypeProps> = (props: TypeProps) => {
  const [hide, setHide] = useState<boolean>(false);

  const [possibleTypes, modifyPossibleTypes] = useState<Array<string>>([
    '🤠 Adventure',
    '💞 Romance',
    '🤣 Comedy',
    '🎭 Drama',
    '🧐 Mystery',
    '🧜‍♀️ Fantasy',
    '🤴 Historical',
    '😱 Horror',
    '🤖 Science Fiction',
  ]);

  const addType = (value: string) => {
    if (props.types === '' || props.types == null) {
      possibleTypes.splice(possibleTypes.indexOf(value), 1);
      modifyPossibleTypes(possibleTypes.slice(0));
      props.onSetType(value);
    } else {
      possibleTypes.push(props.types);
      possibleTypes.splice(possibleTypes.indexOf(value), 1);
      props.onSetType(value);
      modifyPossibleTypes(possibleTypes.slice(0));
    }
    afficher();
  };

  const removeType = (value: string) => {
    possibleTypes.push(value);
    modifyPossibleTypes(possibleTypes.slice(0));
    props.onSetType('');
  };

  const StoryTypesList = () =>
    props.types === '' ? (
      <>
        <p className={styles.rien}>Choose from the list</p>
      </>
    ) : (
      <>
        {
          <div className={styles.type}>
            <button className={styles.button} value={props.types} onClick={() => removeType(props.types)}>
              <span className={styles.space}>{fictionType(props.types)}</span>
              <AiOutlineClose />
            </button>
          </div>
        }
      </>
    );

  const TypesToAddList = () => (
    <>
      {possibleTypes.map((type) => (
        // eslint-disable-next-line react/jsx-key
        <div className={styles.type}>
          <button className={styles.button} value={type} onClick={() => addType(type)}>
            {type}
          </button>
        </div>
      ))}
    </>
  );

  const afficher = () => {
    if (hide) {
      setHide(false);
    } else {
      setHide(true);
    }
  };

  return (
    <div>
      <div className={styles.myTypes}>
        <div className={styles.typeAdded} onClick={() => afficher()}>
          <StoryTypesList />
        </div>
        <div className={styles.typeIcon} onClick={() => afficher()}>
          <BiCaretDown />
        </div>
      </div>
      {hide ? (
        <div className={styles.typesToAdd}>
          <TypesToAddList />
        </div>
      ) : null}
    </div>
  );
};
