import React from 'react';

interface NavigationLinePreviewProps {
  cursorPosition: number;
  children?: any;
}

export const NavigationLinePreview: React.FunctionComponent<NavigationLinePreviewProps> = (
  props: NavigationLinePreviewProps,
) => {
  return (
    <div style={{ height: '1px', background: '#dfdfdf' }}>
      <div style={{ position: 'absolute', width: props.cursorPosition, height: '1px', backgroundColor: '#208775' }}>
        {props.children}
      </div>
    </div>
  );
};
