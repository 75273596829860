import React, { useState } from 'react';
import styles from './ChooseSchool.module.css';
import { useHistory } from 'react-router-dom';
import { updateUserSchool } from '../../api/user.api';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { Button } from 'react-bootstrap';
import { usePlacesWidget } from 'react-google-autocomplete';
import festivalEmoji from '../../assets/img/emoji_party.png';
export function ChooseSchool(): JSX.Element {
  const loggedUser = useSelector((state: RootState) => state.loggedUser);
  const urlNextPage = '/description';

  const [school, setSchool] = useState<string>('');
  const [adress, setAdress] = useState<any>();
  const [error, setError] = useState<string>('');

  const history = useHistory();

  const ref = usePlacesWidget<HTMLInputElement>({
    apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    onPlaceSelected: (place) => {
      setAdress(place.address_components);
      setSchool(place.name);
    },
    options: {
      fields: ['name', 'formatted_address', 'address_components', 'adr_address'],
      types: ['school', 'university', 'primary_school', 'secondary_school'],
    },
  }).ref;

  let city: number;
  let country_number: number;

  const handleSave = () => {
    if (loggedUser) {
      if (!school) {
        setError('You should add your school!');
      } else {
        if (adress) {
          for (let i = 0; i < adress.length; i++) {
            if (adress[i].types.includes('country')) {
              country_number = i;
            }
            if (adress[i].types.includes('locality') || adress[i].types.includes('postal_town')) {
              city = i;
            }
          }
        }
        if (!adress) {
          updateUserSchool(loggedUser.id, school, '', '');
          history.push(urlNextPage);
        } else {
          updateUserSchool(loggedUser.id, school, adress[city].long_name, adress[country_number].long_name);
          history.push(urlNextPage);
        }
      }
    }
  };
  return (
    <>
      <div className={styles.header}>
        <div className="mb-4">
          <h1 className={styles.textTitle}>
            Welcome to eStory!
            <span>
              <img src={festivalEmoji} height="50" />
            </span>
          </h1>
        </div>
        <div className={styles.progressBar}>
          <div className={styles.progression}></div>
        </div>
        {loggedUser?.role === 'teacher' ? (
          <div className="mb-4">
            <h3 className={styles.textBold}>Where do you teach? </h3>
          </div>
        ) : (
          <div className="mb-4">
            <h3 className={styles.textBold}>Where do you study? </h3>
          </div>
        )}
        <form autoComplete="off">
          <input
            ref={ref}
            id="email"
            className={styles.textInput}
            placeholder="Enter your school name"
            onChange={(event) => {
              setSchool(event.target.value), (country_number = 0), (city = 0);
            }}
            type="text"
          />
        </form>
        <div>
          {error ? (
            <div className={styles.error}>
              <p>{error}</p>
            </div>
          ) : null}
          <Button
            className={styles.button}
            onClick={() => handleSave()}
            style={{ borderRadius: '15px', background: '#428576', borderColor: 'transparent', fontSize: '25px' }}
          >
            Continue
          </Button>
        </div>
        <div>
          <a></a>
        </div>
      </div>
    </>
  );
}
