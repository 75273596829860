import React from 'react';
import { NavLink } from 'react-router-dom';

import styles from './NavBar.module.scss';

export type NavBarButtonProps = {
  to: string;
};

export const NavBarButton: React.FunctionComponent<NavBarButtonProps> = (props: NavBarButtonProps) => {
  return (
    <NavLink exact to={`/${props.to}`} className={styles.navBarButton} activeClassName={styles.selected}>
      <div>
        <p>{props.to[0].toUpperCase() + props.to.slice(1)}</p>
      </div>
    </NavLink>
  );
};
