import React from 'react';
import styles from './CancelSubscriptionModal.module.css';
import { Modal } from 'react-bootstrap';

export type ConfirmModalProps = {
  show: boolean;
  handleClose: () => void;
  handleConfirm: () => void;
};

export const CancelSubscriptionModal: React.FunctionComponent<ConfirmModalProps> = (props: ConfirmModalProps) => {
  const { show, handleClose, handleConfirm } = props;

  return (
    <>
      <Modal show={show} onHide={handleClose} className={styles.modal}>
        <div className={styles.message}>
          <br />
          <h2 className={styles.title}>Cancel subscription</h2>
          <h4 className={styles.question}>Are you sure ?</h4>
          <br />
          <h4>You will no longer be able to </h4>
          <h4>create amazing stories</h4>
        </div>
        <div className={styles.buttonsDiv}>
          <button className={styles.noBtn} onClick={handleClose}>
            No
          </button>
          <button className={styles.yesBtn} onClick={handleConfirm}>
            Yes
          </button>
        </div>
      </Modal>
    </>
  );
};
