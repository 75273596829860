import { useEffect, useState } from 'react';
import { getUserTimelines } from '../api/user.api';
import { Timeline_Interface } from '../interfaces/Timeline.interface';

export default function useUserTimelinesSearch(
  id_user: string,
  last: number,
  nbrToFetch: number,
  isUserTimeline: boolean,
) {
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [timelines, setTimelines] = useState<Array<Timeline_Interface>>([]);
  const [hasMore, setHasMore] = useState<boolean>(false);

  useEffect(() => {
    setTimelines([]);
  }, [id_user]);

  useEffect(() => {
    setLoading(true);
    setError(false);
    getUserTimelines(id_user, last, nbrToFetch, isUserTimeline)
      .then((timelineList) => {
        setTimelines((prevTimelines) => {
          return [...prevTimelines, ...timelineList.timelines.sort((t1, t2) => (t1.id > t2.id ? -1 : 1))];
        });
        setHasMore(timelineList.timelines.length > 0);
        setLoading(false);
      })
      .catch(() => {
        setError(true);
      });
  }, [id_user, last]);

  return { loading, error, timelines, hasMore };
}
