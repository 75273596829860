/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import styles from './ToneSelect.module.css';
import { BiCaretDown } from 'react-icons/bi';
import { AiOutlineClose } from 'react-icons/ai';

export type ToneProps = {
  tones: string;
  onSetTone: (type: string) => void;
};

const fictionTone = (tone: string) => {
  let toneFiction = tone;
  if (tone === 'Happy') {
    toneFiction = '😃 Happy';
  }
  if (tone === 'Sad') {
    toneFiction = '😢 Sad';
  }
  if (tone === 'Scary') {
    toneFiction = '😱 Scary';
  }
  if (tone === 'Surprising') {
    toneFiction = '😮 Surprising';
  }
  if (tone === 'Neutral') {
    toneFiction = '😐 Neutral';
  }
  return toneFiction;
};

export const ToneSelect: React.FunctionComponent<ToneProps> = (props: ToneProps) => {
  const [hide, setHide] = useState<boolean>(false);

  const [possibleTones, modifyPossibleTones] = useState<Array<string>>([
    '😃 Happy',
    '😢 Sad',
    '😱 Scary',
    '😮 Surprising',
    '😐 Neutral',
  ]);

  const addTone = (value: string) => {
    if (props.tones === '' || props.tones == null) {
      possibleTones.splice(possibleTones.indexOf(value), 1);
      modifyPossibleTones(possibleTones.slice(0));
      props.onSetTone(value);
    } else {
      possibleTones.push(props.tones);
      possibleTones.splice(possibleTones.indexOf(value), 1);
      props.onSetTone(value);
      modifyPossibleTones(possibleTones.slice(0));
    }
    afficher();
  };

  const removeTone = (value: string) => {
    possibleTones.push(value);
    modifyPossibleTones(possibleTones.slice(0));
    props.onSetTone('');
  };

  const StoryTonesList = () =>
    props.tones === '' ? (
      <>
        <p className={styles.rien}>Choose from the list</p>
      </>
    ) : (
      <>
        {
          <div className={styles.tone}>
            <button className={styles.button} value={props.tones} onClick={() => removeTone(props.tones)}>
              <span className={styles.space}>{fictionTone(props.tones)}</span>
              <AiOutlineClose />
            </button>
          </div>
        }
      </>
    );

  const TonesToAddList = () => (
    <>
      {possibleTones.map((tone) => (
        // eslint-disable-next-line react/jsx-key
        <div className={styles.tone}>
          <button className={styles.button} value={tone} onClick={() => addTone(tone)}>
            {tone}
          </button>
        </div>
      ))}
    </>
  );

  const afficher = () => {
    if (hide) {
      setHide(false);
    } else {
      setHide(true);
    }
  };

  return (
    <div>
      <div className={styles.myTones}>
        <div className={styles.toneAdded} onClick={() => afficher()}>
          <StoryTonesList />
        </div>
        <div className={styles.toneIcon} onClick={() => afficher()}>
          <BiCaretDown />
        </div>
      </div>
      {hide ? (
        <div className={styles.tonesToAdd}>
          <TonesToAddList />
        </div>
      ) : null}
    </div>
  );
};
