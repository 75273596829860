import React from 'react';
import { slide as Menu, State } from 'react-burger-menu';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { RootState } from '../../store/store';
import { SearchBarMenu } from '../NavigationBar/SearchBar/SearchBarMenu';
import './SlideMenu.css';

interface IState {
  menuOpen: boolean;
}

export class SlideMenu extends React.Component<Record<string, number>, IState> {
  constructor(props = {}) {
    super(props);
    this.state = {
      menuOpen: false,
    };
    this.closeMenu = this.closeMenu.bind(this);
  }

  handleStateChange(state: State): void {
    this.setState({ menuOpen: state.isOpen });
  }

  closeMenu(): void {
    this.setState({ menuOpen: false });
  }

  render(): JSX.Element {
    return (
      <Menu right width={'100%'} isOpen={this.state.menuOpen} onStateChange={(state) => this.handleStateChange(state)}>
        <div>✨ {this.props.nb_tokens} tokens</div>
        <NavLink
          exact
          to={'/home'}
          className="menu-item"
          activeClassName={'menu-item-active'}
          onClick={() => this.closeMenu()}
        >
          <div>
            <p>Home</p>
          </div>
        </NavLink>
        <NavLink
          exact
          to={'/explore'}
          className="menu-item"
          activeClassName={'menu-item-active'}
          onClick={() => this.closeMenu()}
        >
          <div>
            <p>Explore</p>
          </div>
        </NavLink>
        <NavLink
          exact
          to={'/pricing'}
          className="menu-item"
          activeClassName={'menu-item-active'}
          onClick={() => this.closeMenu()}
        >
          <div>
            <p>Pricing</p>
          </div>
        </NavLink>
        <NavLink
          exact
          to={'/about'}
          className="menu-item"
          activeClassName={'menu-item-active'}
          onClick={() => this.closeMenu()}
        >
          <div>
            <p>About</p>
          </div>
        </NavLink>
        <NavLink
          exact
          to={'/create'}
          className="menu-item"
          activeClassName={'menu-item-active'}
          onClick={() => this.closeMenu()}
        >
          <div>
            <p className="tallMenu">Create </p>
          </div>
        </NavLink>
        <NavLink
          exact
          to={'/createFiction'}
          className="menu-item"
          activeClassName={'menu-item-active'}
          onClick={() => this.closeMenu()}
        >
          <div>
            <p className="tallMenuFiction">Create</p>
          </div>
        </NavLink>
        {!!localStorage.getItem('estory_user') ? (
          <div className="logged">
            <a
              href="/"
              onClick={() => {
                localStorage.removeItem('estory_user');
              }}
            >
              <p>Logout</p>
            </a>
          </div>
        ) : (
          <div className="logged">
            <NavLink
              exact
              to={'/login'}
              className="menu-item"
              activeClassName={'menu-item-active'}
              onClick={() => this.closeMenu()}
            >
              <div>
                <p>Login</p>
              </div>
            </NavLink>
          </div>
        )}
        <div>
          <div className="menu-search">
            <SearchBarMenu closeMenu={this.closeMenu} />
          </div>
        </div>
      </Menu>
    );
  }
}
