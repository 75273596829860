import React from 'react';
import { EventsDisplayPreviewFiction } from './EventsDisplayPreviewFiction';
import { Event_Interface } from '../../interfaces/Event.interface';
import styles from './FictionPrev.module.css';
import { BsLightning } from 'react-icons/bs';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';

export type FictionPrevProps = {
  title: string;
  imgUrl: string;
  events: Event_Interface[];
  index: number;
  removeEvent: (event: bigint) => void;
  setIndex: (index: number) => void;
  selectedKey: number;
  setSelectedKey: (key: number) => void;
};

export const FictionPrev: React.FunctionComponent<FictionPrevProps> = (props: FictionPrevProps) => {
  const loggedUser = useSelector((state: RootState) => state.loggedUser);

  const style = {
    paddingTop: '60px',
  };

  return props.index === -1 ? (
    <div className={styles.previewContainer}>
      {props.imgUrl !== '' ? (
        <div
          className={styles.background}
          style={{
            ...style,
            backgroundImage: `url(${props.imgUrl})`,
          }}
        />
      ) : null}
      <div className={styles.container}>
        <h2 className={styles.title}>{props.title}</h2>
        <div className={styles.user}>
          {!!loggedUser?.picture ? <img className={styles.profilImage} src={loggedUser?.picture} /> : null}
          <div className={styles.category}>
            <a
              className={styles.profileLink}
              href={`/user/${loggedUser?.id}/${loggedUser?.firstname.replaceAll(
                ' ',
                '-',
              )}-${loggedUser?.lastname.replaceAll(' ', '-')}`}
              target="_blank"
              rel="noreferrer"
            >
              <button className={styles.button} value="N. Bonaparte">
                By {loggedUser?.firstname} {loggedUser?.lastname}
              </button>
            </a>
          </div>
        </div>
        <div className={styles.eventsDisplayContainer}>
          {props.events.length == 0 ? null : (
            <EventsDisplayPreviewFiction
              events={props.events}
              selectedKey={props.selectedKey}
              setSelectedKey={props.setSelectedKey}
              removeEvent={props.removeEvent}
              setIndex={(index: number) => props.setIndex(index)}
            />
          )}
        </div>
        <div className={styles.poweredBy}>
          <div className={styles.textPoweredBy}>
            We are <BsLightning className={styles.lightning} /> by
            <a className={styles.eStory} href="https://estory.io/" target="_blank" rel="noreferrer">
              eStory
            </a>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className={styles.previewContainer2}>
      {props.imgUrl !== '' ? (
        <div
          className={styles.background}
          style={{
            ...style,
            backgroundImage: `url(${props.imgUrl})`,
          }}
        />
      ) : null}
      <div className={styles.container}>
        <h2 className={styles.title}>{props.title}</h2>
        <div className={styles.user}>
          {!!loggedUser?.picture ? <img className={styles.profilImage} src={loggedUser?.picture} /> : null}
          <div className={styles.category}>
            <a
              className={styles.profileLink}
              href={`/user/${loggedUser?.id}/${loggedUser?.firstname.replaceAll(
                ' ',
                '-',
              )}-${loggedUser?.lastname.replaceAll(' ', '-')}`}
              target="_blank"
              rel="noreferrer"
            >
              <button className={styles.button} value="N. Bonaparte">
                By {loggedUser?.firstname} {loggedUser?.lastname}
              </button>
            </a>
          </div>
        </div>
        <div className={styles.eventsDisplayContainer}>
          {props.events.length == 0 ? null : (
            <EventsDisplayPreviewFiction
              events={props.events}
              selectedKey={props.selectedKey}
              setSelectedKey={props.setSelectedKey}
              removeEvent={(event: bigint) => props.removeEvent(event)}
              setIndex={(index: number) => props.setIndex(index)}
            />
          )}
        </div>
        <div className={styles.poweredBy}>
          <div className={styles.textPoweredBy}>
            We are <BsLightning className={styles.lightning} /> by
            <a className={styles.eStory} href="https://estory.io/" target="_blank" rel="noreferrer">
              eStory
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
