import React, { useState } from 'react';
import styles from './LoginMode.module.css';
import { Button } from 'react-bootstrap';
import { login } from '../../../api/auth.api';
import { setLoggedUserActionCreator } from '../../../reducers/LoggedUser';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';

export const Login: React.FunctionComponent = () => {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleLogin = () => {
    setIsLoading(true);
    login(email, password).then(
      (loggedUser) => {
        localStorage.setItem('estory_user', loggedUser.id.toString());
        dispatch(setLoggedUserActionCreator(loggedUser));
      },
      (error) => {
        if (error.response.status === 409) {
          history.push('/email-not-registered');
        } else if (error.response.status === 400) {
          setIsLoading(false);
          setError(true);
        }
      },
    );
  };

  return (
    <div id={styles.login}>
      <div className={styles.fields}>
        <div className={styles.field1}>
          <input
            className={styles.inputs}
            type="text"
            name="email"
            id="email"
            required
            value={email}
            onChange={(event) => setEmail(event.target.value)}
            onKeyPress={(event) => event.charCode === 13 && handleLogin()}
          />
          <label htmlFor="email" className={styles.label}>
            Email Address
          </label>
        </div>
        <div className={styles.field2} id="PasswordDiv">
          <input
            className={styles.inputs}
            type="password"
            name="password"
            required
            id="Password"
            value={password}
            onChange={(event) => setPassword(event.target.value)}
            onKeyPress={(event) => event.charCode === 13 && handleLogin()}
          />
          <label htmlFor="Password" className={styles.label2}>
            Password
          </label>
        </div>
        <div className={styles.field4}>
          <p className={styles.forgot}>
            <a href="/forgot-password">Forgot Password?</a>
          </p>
          {isLoading ? (
            <Spinner animation="grow" className={styles.loader} />
          ) : (
            <Button className={styles.loginButton} variant="success" onClick={handleLogin}>
              Login
            </Button>
          )}
          {error && <div className={styles.errorText}>An error occured, please contact support.</div>}
        </div>
      </div>
    </div>
  );
};
