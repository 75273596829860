/* eslint-disable */
import React, { useEffect, useState } from 'react';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/scss/alice-carousel.scss';
import styles from './MediaCarousel.module.css';
import './MediaCarousel.css';
import { fetchMedia } from '../../api/timeline.api';
import bookIcon from '../../assets/img/book.svg';
import movieIcon from '../../assets/img/movie.svg';
import newspaperIcon from '../../assets/img/newspaper.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useParams } from 'react-router-dom';
import { TimelineModalMedia } from '../../features/TimelineDisplay/TimelineModalMedia/TimelineModalMedia';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';

const handleDragStart = (e: React.DragEvent<HTMLImageElement>) => e.preventDefault();

export type MediaCarouselProps = {
  idTimeline: bigint;
  firstName: string;
  lastName: string;
};

const getIcon = (type: string) => {
  if (type === 'movie') return movieIcon;
  else if (type === 'book') return bookIcon;
  else return newspaperIcon;
};

export const MediaCarousel: React.FC<MediaCarouselProps> = (props: MediaCarouselProps): JSX.Element => {
  const [active, setActive] =
    useState<{ type: string; text: string; link: string; description: string; date: string; img: string }>();
  const [images, setImages] = useState<JSX.Element[]>([]);
  const [desc, setDesc] = useState<
    { type: string; text: string; link: string; description: string; date: string; img: string }[]
  >([]);
  const [loading, setLoading] = useState<boolean>(true);
  const carouselRef = React.createRef<AliceCarousel>();
  const { id } = useParams<{ id: string }>();
  const [modalShow, setModalShow] = useState<boolean>(false);

  useEffect(() => {
    loading &&
      fetchMedia(props.idTimeline).then((medias) => {
        medias.forEach((media, key) => {
          setImages((prevImages) => [
            ...prevImages,
            <img
              src={media.img}
              onDragStart={handleDragStart}
              key={key}
              className={styles.carouselImage}
              onClick={() => setModalShow(true)}
            />,
          ]);
          setDesc((prevDescs) => [
            ...prevDescs,
            {
              text: media.title,
              type: media.type,
              link: media.link,
              description: media.description,
              date: media.date,
              img: media.img,
            },
          ]);
        });
        setLoading(false);
      });
    setActive(desc[0]);
  }, [loading]);

  return (
    <div>
      {desc.length > 0 ? (
        <>
          <p className={`${styles.title} ${styles.recommendedTitle}`}>
            {props.firstName} {props.lastName} media recommendation
          </p>
          <div className={styles.carouselContainer}>
            {images.length > 1 && (
              <FontAwesomeIcon
                icon={faArrowLeft}
                color="white"
                className={styles.carouselButton}
                onClick={(e) => carouselRef.current?.slidePrev(e)}
              />
            )}
            <AliceCarousel
              mouseTracking
              items={images}
              infinite={images.length > 1}
              animationType="fadeout"
              autoPlay={true}
              autoPlayInterval={5000}
              autoPlayStrategy="all"
              swipeDelta={images.length > 1 ? 20 : 1000}
              disableButtonsControls={true}
              disableDotsControls={!(images.length > 1)}
              onSlideChanged={(e) => setActive(desc[e.item])}
              ref={carouselRef}
            />
            {images.length > 1 && (
              <FontAwesomeIcon
                icon={faArrowRight}
                color="white"
                className={styles.carouselButton}
                onClick={(e) => carouselRef.current?.slideNext(e)}
              />
            )}
          </div>
          {active && (
            <div className={styles.carouselTextContainer}>
              <img className={styles.carouselTextType} src={getIcon(active.type)} onClick={() => setModalShow(true)} />
              <div className={styles.carouselText} onClick={() => setModalShow(true)}>
              {active.text.includes('Amazon')? (<div>Amazon product</div>):(active.text)}
              </div>
              <TimelineModalMedia
                id={id}
                show={modalShow}
                title={active.text}
                link={active.link}
                img={active.img}
                description={active.description}
                date={active.date}
                onHide={() => setModalShow(false)}
              />
            </div>
          )}
        </>
      ) : (
        <p className={`${styles.title} ${styles.recommendedTitle}`}>No media recommendation</p>
      )}
    </div>
  );
};
