import React from 'react';
import styles from './InfoModal.module.css';
import { Modal, Button } from 'react-bootstrap';

export type InfoModalProps = {
  text?: string;
  show: boolean;
  buttonText: string;
  title: string;
  handleClose: () => void;
};

export const InfoModal: React.FunctionComponent<InfoModalProps> = (props: InfoModalProps) => {
  const { show, handleClose, title, buttonText, text } = props;

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className={styles.title}>{title}</Modal.Title>
        </Modal.Header>
        {text && <Modal.Body>{text}</Modal.Body>}
        <Modal.Footer>
          <Button onClick={handleClose} className={styles.button}>
            {buttonText}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
