import React, { useCallback, useRef, useState, useEffect } from 'react';
import ReactGA from 'react-ga';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import wiki from '../../assets/img/wikipedia.svg.png';
import styles from './TimelineList.module.css';
import useTimelineSearch from '../../hooks/useTimelineSearch';
import { Col, Container, Row } from 'react-bootstrap';
import { TimelineCard } from '../../components/TimelineCard/TimelineCard/TimelineCard';
import { Loading } from '../../components/Loading/Loading';
import { FictionCard } from '../../components/FictionCard/FictionCard';
import useFictionSearch from '../../hooks/useFictionSearch';
import { generateDateStringFromDateFormat } from '../../utils/date.util';

export type TimelineListProps = {
  defaultCategory?: string;
  defaultCategoryFiction?: string;
  selected?: string;
};

export const TimelineListExplore: React.FunctionComponent<TimelineListProps> = function (props: TimelineListProps) {
  const location = useLocation();
  useEffect(() => {
    if (window.location.hostname === 'estory.io') ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  const [mode, setMode] = useState<boolean>();
  const nbrToFetch = 16;
  const [category, setCategory] = useState(props.defaultCategory ? props.defaultCategory : 'Wikipedia');
  const [categoryFiction, setCategoryFiction] = useState(
    props.defaultCategoryFiction ? props.defaultCategoryFiction : 'Adventure',
  );

  const [last, setLast] = useState<number>(0);
  const getDeviceType = () => {
    const ua = navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
      return 'tablet';
    }
    if (
      /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)
    ) {
      return 'mobile';
    }
    return 'desktop';
  };

  const categories: Array<{ logo: any | null; name: string; category: string; width: string }> = [
    {
      logo: <img src={wiki} width="24" />,
      name: 'Wikipedia',
      category: 'Wikipedia',
      width: '11%',
    },
    { logo: null, name: '💂 Famous people', category: 'People', width: '11%' },
    { logo: null, name: '⚔️ Historical episodes', category: 'Historical', width: '13%' },
    { logo: null, name: '⚽️ Sport & games', category: 'Sport', width: '13%' },
    { logo: null, name: '🔬 Science & technology', category: 'Science', width: '15%' },
    { logo: null, name: '🎨 Art & literature', category: 'Art', width: '11%' },
    { logo: null, name: '🌍 Places', category: 'Places', width: '10%' },
    { logo: null, name: '🚀 Quirky', category: 'Quirky', width: '8%' },
    { logo: null, name: '🔎 Other', category: 'Other', width: '8%' },
  ];

  const categoriesFiction: Array<{ logo: any | null; name: string; categoryFiction: string; width: string }> = [
    { logo: null, name: '🤠 Adventure', categoryFiction: 'Adventure', width: '12%' },
    { logo: null, name: '💞 Romance', categoryFiction: 'Romance', width: '14%' },
    { logo: null, name: '🤣 Comedy', categoryFiction: 'Comedy', width: '13%' },
    { logo: null, name: '🎭 Drama', categoryFiction: 'Drama', width: '10%' },
    { logo: null, name: '🧐 Mystery', categoryFiction: 'Mystery', width: '14%' },
    { logo: null, name: '🧜‍️ Fantasy', categoryFiction: 'Fantasy', width: '14%' },
    { logo: null, name: '🤴️ Historical', categoryFiction: 'Historical', width: '15%' },
    { logo: null, name: '😱 Horror', categoryFiction: 'Horror', width: '10%' },
    { logo: null, name: '🤖 Science Fiction', categoryFiction: 'Science Fiction', width: '14%' },
  ];

  const { timelines, hasMore, loading, error } = useTimelineSearch(last, nbrToFetch, category ? category : '');
  const { fictions } = useFictionSearch(last, nbrToFetch, categoryFiction ? categoryFiction : '');

  const observer: any = useRef();
  const lastTimelineElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setLast((prevLast) => prevLast + nbrToFetch + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore],
  );

  const handleClick = useCallback((newCategory: string) => {
    setCategory(newCategory);
    setLast(0);
  }, []);
  const handleClickFiction = useCallback((newCategory: string) => {
    setCategoryFiction(newCategory);
    setLast(0);
  }, []);

  return (
    <>
      <Helmet>
        <title>eStory | Explore</title>
        <meta property="og:type" content="siteweb" />
        <meta name="twitter:title" content="eStory | Explore" />
        <meta
          name="description"
          content="Discover the best timelines on history, inspiring people, sciences, arts, sports and other topics."
        />
        <meta property="og:title" content="eStory | Explore" />
        <meta
          property="og:description"
          content="Discover the best timelines on history, inspiring people, sciences, arts, sports and other topics."
        />
        <meta
          property="og:image"
          content="https://res.cloudinary.com/dk2ghb1pg/image/upload/v1531942668/Logo_eStory_simple_klalco.png"
        />
        <meta property="og:url" content="https://estory.io/explore" />
      </Helmet>
      <div className={styles.headerCategories}>
        <div className={styles.rowTitleButton}>
          <h1 className={styles.title}>Explore</h1>
          {mode ? (
            <div className={styles.exploreButton}>
              <button className={styles.setTimelineButton} onClick={() => setMode(false)}>
                Timelines
              </button>
              <button className={styles.setFictionsButtonSelected} onClick={() => setMode(true)}>
                Fictions
              </button>
            </div>
          ) : (
            <div className={styles.exploreButton}>
              <button className={styles.setTimelineButtonSelected} onClick={() => setMode(false)}>
                Timelines
              </button>
              <button className={styles.setFictionsButton} onClick={() => setMode(true)}>
                Fictions
              </button>
            </div>
          )}
        </div>
        <div className={styles.exploreCategories}>
          {mode
            ? categoriesFiction.map((c, key) => {
                return getDeviceType() === 'desktop' ? (
                  <p
                    key={key}
                    className={`${styles.category} ${categoryFiction === c.categoryFiction ? styles.selected : null}`}
                    onClick={() => handleClickFiction(c.categoryFiction)}
                    style={{ width: c.width }}
                  >
                    {c.logo}
                    {c.name}
                  </p>
                ) : (
                  <p
                    key={key}
                    className={`${styles.category} ${categoryFiction === c.categoryFiction ? styles.selected : null}`}
                    onClick={() => handleClickFiction(c.categoryFiction)}
                  >
                    {c.logo}
                    {c.name}
                  </p>
                );
              })
            : categories.map((c, key) => {
                return getDeviceType() === 'desktop' ? (
                  <p
                    key={key}
                    className={`${styles.category} ${category === c.category ? styles.selected : null}`}
                    onClick={() => handleClick(c.category)}
                    style={{ width: c.width }}
                  >
                    {c.logo}
                    {c.name}
                  </p>
                ) : (
                  <p
                    key={key}
                    className={`${styles.category} ${category === c.category ? styles.selected : null}`}
                    onClick={() => handleClick(c.category)}
                  >
                    {c.logo}
                    {c.name}
                  </p>
                );
              })}
        </div>
      </div>
      <div className={styles.AppTimelineList}>
        <div className={styles.listTimelines}>
          <Container fluid={true}>
            <Row>
              {mode
                ? fictions.map((fiction, index) => {
                    if (timelines.length === index + 1) {
                      return (
                        <Col xs={12} sm={6} md={4} lg={3} key={index} className={styles.myCol}>
                          <div ref={lastTimelineElementRef} key={Number(fiction.id)}>
                            <FictionCard
                              id={Number(fiction.id)}
                              title={fiction.title}
                              backgroundImageUrl={fiction.img_background}
                              author={
                                fiction.UserModels[0]
                                  ? `${fiction.UserModels[0].firstname} ${fiction.UserModels[0].lastname}`
                                  : `Unknown User`
                              }
                              nbLike={fiction.nbLike ? fiction.nbLike : 0}
                            />
                          </div>
                        </Col>
                      );
                    } else {
                      return (
                        <Col xs={12} sm={6} md={4} lg={3} key={index} className={styles.myCol}>
                          <div key={Number(fiction.id)}>
                            <FictionCard
                              id={Number(fiction.id)}
                              title={fiction.title}
                              backgroundImageUrl={fiction.img_background}
                              author={
                                fiction.UserModels[0]
                                  ? `${fiction.UserModels[0].firstname} ${fiction.UserModels[0].lastname}`
                                  : `Unknown User`
                              }
                              nbLike={fiction.nbLike ? fiction.nbLike : 0}
                            />
                          </div>
                        </Col>
                      );
                    }
                  })
                : timelines.map((timeline, index) => {
                    if (timelines.length === index + 1) {
                      return (
                        <Col xs={12} sm={6} md={4} lg={3} key={index} className={styles.myCol}>
                          <div ref={lastTimelineElementRef} key={Number(timeline.id)}>
                            <TimelineCard
                              id={Number(timeline.id)}
                              title={timeline.title}
                              backgroundImageUrl={timeline.img_background}
                              author={
                                timeline.UserModels[0]
                                  ? `${timeline.UserModels[0].firstname} ${timeline.UserModels[0].lastname}`
                                  : `Unknown User`
                              }
                              lastUpdate={
                                timeline.timeline_updated_at
                                  ? generateDateStringFromDateFormat(new Date(timeline.timeline_updated_at))
                                  : 'Unknown date'
                              }
                              nbLike={timeline.nbLike ? timeline.nbLike : 0}
                            />
                          </div>
                        </Col>
                      );
                    } else {
                      return (
                        <Col xs={12} sm={6} md={4} lg={3} key={index} className={styles.myCol}>
                          <div key={Number(timeline.id)}>
                            <TimelineCard
                              id={Number(timeline.id)}
                              title={timeline.title}
                              backgroundImageUrl={timeline.img_background}
                              author={
                                timeline.UserModels[0]
                                  ? `${timeline.UserModels[0].firstname} ${timeline.UserModels[0].lastname}`
                                  : `Unknown User`
                              }
                              lastUpdate={
                                timeline.timeline_updated_at
                                  ? generateDateStringFromDateFormat(new Date(timeline.timeline_updated_at))
                                  : 'Unknown date'
                              }
                              nbLike={timeline.nbLike ? timeline.nbLike : 0}
                            />
                          </div>
                        </Col>
                      );
                    }
                  })}
            </Row>
          </Container>
          <div>{loading && <Loading />}</div>
          <div>{error && 'Error'}</div>
        </div>
      </div>
    </>
  );
};
