import React from 'react';
import loadingImg from '../../assets/img/Logo_eStory.png';
import styles from './Loading.module.css';

export const Loading: React.FunctionComponent = () => {
  return (
    <div className={styles.container}>
      <div>
        <h1> Welcome to eStory! </h1>
        <div className={styles.pulse}>
          <img src={loadingImg} className={styles.imgLoading} />
        </div>
      </div>
    </div>
  );
};
