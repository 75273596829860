/* eslint-disable react/jsx-key */
import React, { useEffect, useState } from 'react';
import styles from './ImageOpenAiModal.module.css';
import { Modal, Button, InputGroup, FormControl, Col, Row } from 'react-bootstrap';
import { BiPlus } from 'react-icons/bi';
import comics from '../../../assets/img/comics.png';
import oil from '../../../assets/img/oilPainting.png';
import medieval from '../../../assets/img/medieval.png';
import cartoon from '../../../assets/img/cartoon.png';
import manga from '../../../assets/img/manga.png';
import photo from '../../../assets/img/photography.png';
import cyberpunk from '../../../assets/img/punk.png';
import other from '../../../assets/img/other.png';
import wand from '../../../assets/img/wand_white.png';
import { Loading } from '../../Loading/Loading';
import { IoArrowBackCircleOutline } from 'react-icons/all';
import { useTokenAPI } from '../../../api/user.api';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import { self } from '../../../api/user.api';
import { useDispatch } from 'react-redux';
import { setLoggedUserActionCreator } from '../../../reducers/LoggedUser';
import { generateImageOpenAI } from '../../../api/fiction.api';
import { TokensModal } from '../TokensModal/TokensModal';

export type AddItemProps = {
  show?: boolean;
  onHide: () => void;
  onSetImage: (img: string) => void;
};

const getStyle = (number: number) => {
  switch (number) {
    case 1:
      return 'Comics';
    case 2:
      return 'Oil Painting';
    case 3:
      return 'Medieval Painting';
    case 4:
      return 'Cartoon';
    case 5:
      return 'Manga';
    case 6:
      return 'Photography';
    case 7:
      return 'Cyberpunk';
    default:
      return '';
  }
};

export const ImageOpenAiModal: (props: AddItemProps) => JSX.Element = function (props: AddItemProps) {
  const [links, setLinks] = useState<Array<string>>([]);
  const [mode, setMode] = useState<boolean>(false);
  const [description, setDescription] = useState('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [otherType, setOtherType] = useState('');
  const [modalShow, setModalShow] = useState<boolean>(false);
  const [imageSelected, setImageSelected] = useState<number>(0);
  const [specialStyle, setSpecialStyle] = useState<string>('');

  const loggedUser = useSelector((state: RootState) => state.loggedUser);

  const dispatch = useDispatch();

  const getPrompt = () => {
    if (description && imageSelected === 8) return description + ', ' + specialStyle;
    if (description && imageSelected) return description + ', ' + getStyle(imageSelected);
    return '';
  };

  useEffect(() => {
    if (!props.show) {
      setMode(false);
      setDescription('');
      setOtherType('');
      setIsLoading(false);
      setImageSelected(0);
      setLinks([]);
    }
  }, [props.show]);

  async function resultatImageAi() {
    if (Number(loggedUser?.nb_tokens) > 0) {
      setIsLoading(true);
      await generateImageOpenAI(getPrompt())
        .then((result) => {
          setLinks(result);
        })
        .then(() => {
          useTokenAPI(Number(loggedUser?.id)).then(() => {
            self().then((loggedUser) => dispatch(setLoggedUserActionCreator(loggedUser)));
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setModalShow(true);
    }
  }

  return (
    <Modal {...props} className={styles.modal} size="xl" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header className={styles.modalHeader} closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {links.length == 0 ? '✨ Image creator' : '✨ Results'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!isLoading && links.length === 0 ? (
          <div>
            <InputGroup className={styles.imageCreator}>
              <div className={styles.description}>
                <p className={styles.label}>Describe what you wish to see</p>
                <FormControl
                  className={styles.inputDescription}
                  placeholder="Example: “a man on a horse with the style of Napoleon"
                  value={description}
                  aria-label={description}
                  aria-describedby="basic-addon2"
                  onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                    setDescription(ev.target.value);
                  }}
                />
              </div>
            </InputGroup>
            <div>
              <div className={styles.styleImage}>
                <p className={styles.label}>Select a style</p>
                <Row>
                  <Col className={styles.colonne} lg={3} md={2} onClick={() => setImageSelected(1)}>
                    <div className={styles.styleChoice}>
                      <div className={imageSelected === 1 ? styles.selected : styles.noSelected}></div>
                      <img className={imageSelected === 1 ? styles.imageSelected : styles.image} src={comics} />
                      <p className={styles.labelCenter}>Comics</p>
                    </div>
                  </Col>{' '}
                  <Col className={styles.colonne} lg={3} md={2} onClick={() => setImageSelected(2)}>
                    <div className={styles.styleChoice}>
                      <div className={imageSelected === 2 ? styles.selected : styles.noSelected}></div>
                      <img className={imageSelected === 2 ? styles.imageSelected : styles.image} src={oil} />
                      <p className={styles.labelCenter}>Oil Painting</p>
                    </div>
                  </Col>
                  <Col className={styles.colonne} lg={3} md={2} onClick={() => setImageSelected(3)}>
                    {' '}
                    <div className={styles.styleChoice}>
                      <div className={imageSelected === 3 ? styles.selected : styles.noSelected}></div>
                      <img className={imageSelected === 3 ? styles.imageSelected : styles.image} src={medieval} />
                      <p className={styles.labelCenter}>Medieval Painting</p>
                    </div>
                  </Col>
                  <Col className={styles.colonne} lg={3} md={2} onClick={() => setImageSelected(4)}>
                    {' '}
                    <div className={styles.styleChoice}>
                      <div className={imageSelected === 4 ? styles.selected : styles.noSelected}></div>
                      <img className={imageSelected === 4 ? styles.imageSelected : styles.image} src={cartoon} />
                      <p className={styles.labelCenter}>Cartoon</p>
                    </div>
                  </Col>
                  <Col className={styles.colonne} lg={3} md={2} onClick={() => setImageSelected(5)}>
                    <div className={styles.styleChoice}>
                      <div className={imageSelected === 5 ? styles.selected : styles.noSelected}></div>
                      <img className={imageSelected === 5 ? styles.imageSelected : styles.image} src={manga} />
                      <p className={styles.labelCenter}>Manga</p>
                    </div>
                  </Col>
                  <Col className={styles.colonne} lg={3} md={2} onClick={() => setImageSelected(6)}>
                    {' '}
                    <div className={styles.styleChoice}>
                      <div className={imageSelected === 6 ? styles.selected : styles.noSelected}></div>
                      <img className={imageSelected === 6 ? styles.imageSelected : styles.image} src={photo} />
                      <p className={styles.labelCenter}>Photography</p>
                    </div>
                  </Col>
                  <Col className={styles.colonne} lg={3} md={2} onClick={() => setImageSelected(7)}>
                    {' '}
                    <div className={styles.styleChoice}>
                      <div className={imageSelected === 7 ? styles.selected : styles.noSelected}></div>
                      <img className={imageSelected === 7 ? styles.imageSelected : styles.image} src={cyberpunk} />
                      <p className={styles.labelCenter}>Cyberpunk</p>
                    </div>
                  </Col>
                  <Col className={styles.colonne} lg={3} md={2} onClick={() => setImageSelected(8)}>
                    {' '}
                    <div onClick={() => setMode(true)} className={styles.styleChoice}>
                      <div className={imageSelected === 8 ? styles.selected : styles.noSelected}></div>
                      <img className={imageSelected === 8 ? styles.imageSelected : styles.image} src={other} />
                      <p className={styles.labelCenter}>Other</p>
                    </div>
                  </Col>
                </Row>
              </div>
              {mode && (
                <InputGroup className={styles.inputOtherForm}>
                  <p className={styles.label}>Type a generic style, influence or artist</p>
                  <div className={styles.bottomOther}>
                    <FormControl
                      placeholder="Other style example: “Pencil & Watercolor”, “3D render”, “Drawing”..."
                      value={otherType}
                      aria-lable={otherType}
                      aria-describedby="basic-addon2"
                      onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                        setOtherType(ev.target.value);
                      }}
                      style={{ width: '80%' }}
                    />
                    <InputGroup.Append>
                      <Button
                        className={styles.searchButton}
                        variant="success"
                        onClick={() => setSpecialStyle(otherType)}
                      >
                        <BiPlus /> Add
                      </Button>
                    </InputGroup.Append>
                  </div>
                </InputGroup>
              )}
              <div className={styles.colonneCenter}>
                <div className={styles.buttonDivCenter}>
                  <button
                    className={styles.buttonGenerateCenter}
                    onClick={() => resultatImageAi()}
                    disabled={!getPrompt() || !description || imageSelected < 1}
                  >
                    <img className={styles.wandlogo} src={wand} />
                    <span>Generate</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : isLoading && links.length === 0 ? (
          <div className={styles.loaderContainer}>
            <div className={styles.loaderContent}>
              <Loading />
            </div>
          </div>
        ) : (
          <div>
            <div className={styles.back}>
              <a onClick={() => setLinks([])}>
                <IoArrowBackCircleOutline className={styles.arrow} />
                <span>Back</span>
              </a>
            </div>
            <div className={styles.descriptionResult}>
              <InputGroup className={styles.imageCreatorResult}>
                <div className={styles.description}>
                  <p className={styles.label}>Describe what you wish to see</p>
                  <div className={styles.groupButtonInput}>
                    <FormControl
                      className={styles.inputDescriptionResult}
                      placeholder="Example: “a man on a horse with the style of Napoleon”"
                      value={getPrompt()}
                      aria-lable={getPrompt()}
                      aria-describedby="basic-addon2"
                      disabled
                    />
                    <div className={styles.buttonDiv}>
                      <button className={styles.buttonGenerate} onClick={() => resultatImageAi()}>
                        <img className={styles.wandlogo} src={wand} />
                        <span>Generate</span>
                      </button>
                    </div>
                  </div>
                </div>
              </InputGroup>
            </div>
            <div className={styles.styleImage}>
              <p className={styles.label}>Select an Image</p>
              <Row>
                {links.map((url) => (
                  <Col className={styles.colonne} lg={3}>
                    {' '}
                    <img className={styles.imageSearched} src={url} />
                    <button
                      className={styles.buttonSearched}
                      onClick={() => {
                        props.onSetImage(url);
                        props.onHide();
                        setLinks([]);
                      }}
                    >
                      Select
                    </button>
                  </Col>
                ))}
              </Row>
            </div>
          </div>
        )}
        <TokensModal
          show={modalShow}
          handleClose={() => setModalShow(false)}
          handleConfirm={() => setModalShow(false)}
        />
      </Modal.Body>
    </Modal>
  );
};
