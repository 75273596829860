import React, { useState } from 'react';
import styles from './SignUpMode.module.css';
import { Button, Spinner } from 'react-bootstrap';
import { signup } from '../../../api/auth.api';
import { useHistory } from 'react-router-dom';

export const SignUp: React.FunctionComponent = () => {
  const [firstname, setFirstName] = useState<string>('');
  const [lastname, setLastName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [apiError, setApiError] = useState<boolean>(false);

  const history = useHistory();

  const signUp = () => {
    const decimal = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/;
    if (!firstname) {
      setError('You should fill firstname before sign up!');
      return false;
    } else if (!lastname) {
      setError('You should fill lastname before sign up!');
      return false;
    } else if (!email) {
      setError('You should fill email before sign up!');
      return false;
    } else if (!password) {
      setError('You should fill password before sign up!');
      return false;
    } else if (confirmPassword !== password) {
      setError('Both passwords should be the same!');
      return false;
    } else if (password.length < 8) {
      setError('Password length must be greater than 8!');
      return false;
    } else if (!password.match(decimal)) {
      setError(
        'Your password should be between 8 and 15 characters and it must contain one special character, one numeric digit, one lowercase and one uppercase letter',
      );
      return false;
    } else {
      setError('');
    }

    setIsLoading(true);
    signup(email, password, firstname, lastname).then(
      () => {
        const url = `/email-validation`;
        history.push(url);
      },
      (error) => {
        if (error.response.status === 409) {
          history.push('/already-registered');
        } else if (error.response.status === 400) {
          setIsLoading(false);
          setApiError(true);
        }
      },
    );
  };

  return (
    <div className={styles.signup}>
      <div className={styles.names}>
        <div className={styles.field1}>
          <input
            className={styles.inputs2}
            type="text"
            name="firstname"
            id="firstname"
            required
            value={firstname}
            onChange={(event) => setFirstName(event.target.value)}
            onKeyPress={(event) => event.charCode === 13 && signUp()}
          />
          <label htmlFor="firstname" className={styles.label}>
            First Name
          </label>
        </div>
        <div className={styles.field2}>
          <input
            className={styles.inputs2}
            type="text"
            id="lastname"
            name="lastname"
            required
            value={lastname}
            onChange={(event) => setLastName(event.target.value)}
            onKeyPress={(event) => event.charCode === 13 && signUp()}
          />
          <label htmlFor="lastname" className={styles.label}>
            Last Name
          </label>
        </div>
      </div>
      <div className={styles.field3}>
        <input
          className={styles.inputs}
          type="email"
          name="email"
          required
          id="Email"
          value={email}
          onChange={(event) => setEmail(event.target.value)}
          onKeyPress={(event) => event.charCode === 13 && signUp()}
        />
        <label htmlFor="Email" className={styles.label2}>
          Email Address
        </label>
      </div>
      <div className={styles.field3} id="PasswordDiv">
        <input
          className={styles.inputs}
          type="password"
          name="Password"
          required
          id="Password"
          value={password}
          onChange={(event) => setPassword(event.target.value)}
          onKeyPress={(event) => event.charCode === 13 && signUp()}
        />
        <label htmlFor="Password" className={styles.label2}>
          Set A Password
        </label>
      </div>
      <div className={styles.field3} id="ConfirmPasswordDiv">
        <input
          className={styles.inputs}
          type="password"
          name="ConfirmPassword"
          required
          id="ConfirmPassword"
          value={confirmPassword}
          onChange={(event) => setConfirmPassword(event.target.value)}
          onKeyPress={(event) => event.charCode === 13 && signUp()}
        />
        <label htmlFor="ConfirmPassword" className={styles.label2}>
          Confirm your Password
        </label>
      </div>
      {error !== '' ? (
        <div className={styles.error}>
          <p>{error}</p>
        </div>
      ) : (
        <div className={styles.error}></div>
      )}
      <div className={styles.field4}>
        {isLoading ? (
          <Spinner animation="grow" className={styles.loader} />
        ) : (
          <Button className={styles.startedButton} variant="success" onClick={signUp}>
            Get Started !
          </Button>
        )}
        {apiError && <div className={styles.apiErrorText}>An error occured, please contact support.</div>}
      </div>
    </div>
  );
};
