import React, { useState } from 'react';
import { Event_Interface } from '../../../interfaces/Event.interface';
import styles from './EventFictionPreviewStep.module.css';
import { Event } from '../../../models/Event.model';
import { CardEventFictionCreation } from '../EventFictionCard/CardEventFictionCreation';
import { FictionPrev } from '../FictionPrev';

export type Step3Props = {
  timelineId: number;
  title: string;
  type: string;
  tone: string;
  imgUrl: string;
  events: Event_Interface[];
  selectedKey: number;
  setSelectedKey: (key: number) => void;
  addEvent: (
    id_timeline: number,
    description: string,
    display_roman_numbers: boolean,
    x_position: number,
    y_position: number,
    img: string,
    day: number,
    month: number,
    year: number,
  ) => void;
  modifyEvent: (event: Event_Interface, indice: number) => void;
  removeEvent: (event: bigint) => void;
};

export const EventFictionPreviewStep: React.FunctionComponent<Step3Props> = (props: Step3Props) => {
  const [index, setIndex] = useState<number>(-1);

  return (
    <div className={styles.blockForm}>
      <div className={styles.eventCard}>
        <h3 className={styles.subtitles}>Event Details</h3>
        {index !== -1 ? (
          <CardEventFictionCreation
            title={props.title}
            type={props.type}
            tone={props.tone}
            timelineId={props.timelineId}
            events={props.events}
            event={props.events[index]}
            addEvent={props.addEvent}
            modifyEvent={(
              id: number,
              description: string,
              display_roman_numbers: boolean,
              x_position: number,
              y_position: number,
              img: string,
            ) =>
              props.modifyEvent(
                new Event(
                  BigInt(id),
                  '',
                  description,
                  0,
                  0,
                  BigInt(0),
                  display_roman_numbers,
                  x_position,
                  y_position,
                  img,
                  '',
                  new Date(),
                  new Date(),
                ),
                index,
              )
            }
            setIndex={setIndex}
          />
        ) : (
          <CardEventFictionCreation
            title={props.title}
            type={props.type}
            tone={props.tone}
            timelineId={props.timelineId}
            events={props.events}
            addEvent={props.addEvent}
            modifyEvent={(
              id: number,
              description: string,
              display_roman_numbers: boolean,
              x_position: number,
              y_position: number,
              img: string,
            ) =>
              props.modifyEvent(
                new Event(
                  BigInt(id),
                  '',
                  description,
                  0,
                  0,
                  BigInt(0),
                  display_roman_numbers,
                  x_position,
                  y_position,
                  img,
                  '',
                  new Date(),
                  new Date(),
                ),
                index,
              )
            }
            setIndex={(index: number) => setIndex(index)}
          />
        )}
      </div>
      <div className={styles.preview}>
        <h3 className={styles.subtitles}>Preview</h3>
        <FictionPrev
          selectedKey={props.selectedKey}
          setSelectedKey={props.setSelectedKey}
          title={props.title}
          imgUrl={props.imgUrl}
          events={props.events}
          index={index}
          setIndex={(index: number) => setIndex(index)}
          removeEvent={props.removeEvent}
        />
      </div>
    </div>
  );
};
