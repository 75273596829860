import React from 'react';
import styles from './StepBar.module.css';

export type StepBarProps = {
  created: boolean;
  stepNumber: number;
  goToStep: (step: number) => void;
};

export const StepBar: React.FunctionComponent<StepBarProps> = (props: StepBarProps) => {
  const renderCircleStep = (step: number) => {
    const circleStepClass = props.stepNumber >= step ? styles.circleStep2 : styles.circleStep;
    const styleCursor = props.created ? { cursor: 'pointer' } : { cursor: 'not-allowed' };
    return (
      <div id={step.toString()} className={circleStepClass} style={styleCursor} onClick={() => props.goToStep(step)}>
        <p>{step}</p>
      </div>
    );
  };

  return (
    <>
      <div className={styles.stepBar}>
        <div className={styles.ligne}>
          <div className={styles.horizontalLine}></div>
        </div>
        <div
          className={styles.ligne}
          style={{
            width: `${props.stepNumber * 25}%`,
            left: 0,
          }}
        >
          <div className={styles.horizontalLineGreen}></div>
        </div>
        <div className={styles.bar}>
          {renderCircleStep(1)}
          {renderCircleStep(2)}
          {renderCircleStep(3)}
        </div>
        <div className={styles.titles}>
          <div className={styles.titleDiv} style={{ cursor: 'pointer' }} onClick={() => props.goToStep(1)}>
            <p>Timeline overview</p>
          </div>
          <div
            className={styles.titleDiv}
            style={props.created ? { cursor: 'pointer' } : { cursor: 'not-allowed' }}
            onClick={() => props.goToStep(2)}
          >
            <p>Related sources</p>
          </div>
          <div
            className={styles.titleDiv}
            style={props.created ? { cursor: 'pointer' } : { cursor: 'not-allowed' }}
            onClick={() => props.goToStep(3)}
          >
            <p>Add events</p>
          </div>
        </div>
      </div>
    </>
  );
};
