import React, { FunctionComponent, useState } from 'react';
import { RequestTimeline } from './RequestTimeline';
import { RequestEvent } from './RequestEvent';
import { RequestTimelineProps } from './RequestTimeline';
import { RequestEventProps } from './RequestEvent';
import { ERROR_GENERIC, ERROR__EVENTS_NOT_FOUND, ERROR__TIMELINE_NOT_FOUND } from '../../../utils/errorMessages';

export type RequestAIProps = {
  step: number;
  requestTimeline?: RequestTimelineProps;
  requestEvent?: RequestEventProps;
};

export const RequestAI: FunctionComponent<RequestAIProps> = (props: RequestAIProps) => {
  switch (props.step) {
    case 1:
      if (props.requestTimeline === undefined) {
        return ERROR__TIMELINE_NOT_FOUND;
      }
      return <RequestTimeline {...props.requestTimeline} />;
    case 2:
      if (props.requestEvent === undefined) {
        return ERROR__EVENTS_NOT_FOUND;
      }
      return <RequestEvent {...props.requestEvent} />; //<RequestResources {...props} />;  à remplacer plus tard
    case 3:
      if (props.requestEvent === undefined) {
        return ERROR__EVENTS_NOT_FOUND;
      }
      return <RequestEvent {...props.requestEvent} />;
    default:
      return ERROR_GENERIC;
  }
};
