import React, { useState } from 'react';
import { Event_Interface } from '../../../interfaces/Event.interface';
import styles from './EventFictionDisplayVertical.module.scss';
import { initiateFictionEvents } from '../../TimelineModification/util';

interface EventsDisplayVProps {
  events: Event_Interface[];
}

export const EventFictionDisplayVertical: React.FunctionComponent<EventsDisplayVProps> = (
  props: EventsDisplayVProps,
) => {
  const [events] = useState<{ event: Event_Interface; date: Date }[]>(initiateFictionEvents(props.events));
  const getDeviceType = () => {
    const ua = navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
      return 'tablet';
    }
    if (
      /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)
    ) {
      return 'mobile';
    }
    return 'desktop';
  };

  return getDeviceType() === 'mobile' ? (
    <div>
      {events.map((event, key) => {
        const splitedImg = event.event.img && event.event.img.split('/upload/');
        const img =
          event.event.img &&
          splitedImg &&
          splitedImg[0] + '/upload/c_fill,ar_6:4,g_auto/q_auto,f_auto/' + splitedImg[1];
        return (
          <div key={key} className={styles.boxMobile}>
            <div className={styles.contentMobile}>
              <div className={`${event.event.img != null ? styles.pImg : styles.pImg3}`}>
                <img className={styles.pImg2} src={img}></img>
              </div>
              <div className={styles.boxtimeline}>
                <h4 className={styles.boxtimelineTitle}>{event.event.title}</h4>
                <p className={styles.boxtimelineDescription}>{event.event.description}</p>
              </div>
              <span className={styles.circleMobile} />
            </div>
          </div>
        );
      })}
    </div>
  ) : (
    <div>
      {events.map((event, key) => {
        const splitedImg = event.event.img && event.event.img.split('/upload/');
        const img =
          event.event.img &&
          splitedImg &&
          splitedImg[0] + '/upload/c_fill,ar_6:4,g_auto/q_auto,f_auto/' + splitedImg[1];
        return (
          <div key={key} className={styles.box}>
            <div className={styles.content}>
              <div className={`${event.event.img != null ? styles.pImg : styles.pImg3}`}>
                <img className={styles.pImg2} src={img}></img>
              </div>
              <div className={styles.boxtimeline}>
                <h4 className={styles.boxtimelineTitle}>{event.event.title}</h4>
                <p className={styles.boxtimelineDescription}>{event.event.description}</p>
              </div>
              <span className={styles.circle} />
            </div>
          </div>
        );
      })}
    </div>
  );
};
