import axios, { AxiosRequestConfig } from 'axios';
import { Event_Interface } from '../interfaces/Event.interface';
import { Media_Interface } from '../interfaces/Media.interface';
import { Timeline_Interface } from '../interfaces/Timeline.interface';
import { User_Interface } from '../interfaces/User.interface';
import { Timeline } from '../models/Timeline.model';
import { categoriesArrayIntoString } from '../utils/categories';
import { request } from 'http';
import { EventByAI_Interface } from '../interfaces/EventByAi.interface';

export async function fetchTimelines(
  index: number,
  num: number,
  order: string | null,
  category: string | null,
  search: string | string[] | null,
): Promise<{ timelines: Timeline_Interface[]; count: number; max: number }> {
  return new Promise((resolve, reject) => {
    try {
      const url = `${process.env.REACT_APP_SERV_HOST}/timelines`;
      const config: AxiosRequestConfig = {
        method: 'get',
        headers: { 'Content-Type': 'application/json' },
        params: {
          index: index,
          number: num,
          order: order ? order : 'like',
          category: category ? category : null,
          search: search ? search : null,
        },
      };
      axios.get(url, config).then((timelines) => {
        const timelineList: Timeline_Interface[] = new Array<Timeline>();
        timelines.data.data.forEach((timeline: Timeline_Interface) => {
          timelineList.push(timeline);
        });
        resolve({ timelines: timelineList, count: timelines.data.length, max: timelines.data.max });
      });
    } catch (err) {
      reject(err);
    }
  });
}

export async function fetchTimelinesByCategory(
  index: number,
  num: number,
  category: string | null,
): Promise<{ timelines: Timeline_Interface[]; count: number; max: number }> {
  return new Promise((resolve, reject) => {
    try {
      const url = `${process.env.REACT_APP_SERV_HOST}/timelines/category`;
      const config: AxiosRequestConfig = {
        method: 'get',
        headers: { 'Content-Type': 'application/json' },
        params: {
          index: index,
          number: num,
          category: category ? category : null,
        },
      };
      axios.get(url, config).then((timelines) => {
        const timelineList: Timeline_Interface[] = new Array<Timeline>();
        timelines.data.data.forEach((timeline: Timeline_Interface) => {
          timelineList.push(timeline);
        });
        resolve({ timelines: timelineList, count: timelines.data.length, max: timelines.data.max });
      });
    } catch (err) {
      reject(err);
    }
  });
}

export async function fetchUsers(search: string): Promise<User_Interface[]> {
  return new Promise((resolve, reject) => {
    try {
      const url = `${process.env.REACT_APP_SERV_HOST}/users/search`;
      const config: AxiosRequestConfig = {
        method: 'get',
        headers: { 'Content-Type': 'application/json' },
        params: {
          search: search,
        },
      };
      axios.get(url, config).then((users) => {
        const userList: User_Interface[] = new Array<User_Interface>();
        users.data.data.forEach((user: User_Interface) => {
          userList.push(user);
        });
        resolve(userList);
      });
    } catch (err) {
      reject(err);
    }
  });
}

export async function fetchMedia(idTimeline: bigint): Promise<Media_Interface[]> {
  return new Promise((resolve, reject) => {
    try {
      const url = `${process.env.REACT_APP_SERV_HOST}/mediaitems/timeline/${idTimeline}`;
      const config: AxiosRequestConfig = {
        method: 'get',
        headers: { 'Content-Type': 'application/json' },
      };
      axios.get(url, config).then((mediaItems) => {
        const mediaList: Media_Interface[] = new Array<Media_Interface>();
        mediaItems.data.data.forEach((media: Media_Interface) => {
          mediaList.push(media);
        });
        resolve(mediaList);
      });
    } catch (err) {
      reject(err);
    }
  });
}

export async function getRelatedTimelinesId(id: number): Promise<{ id: number }[]> {
  return new Promise((resolve, reject) => {
    try {
      const url = `${process.env.REACT_APP_SERV_HOST}/timelines/${id}/relateds`;
      const config: AxiosRequestConfig = {
        method: 'get',
        headers: { 'Content-Type': 'application/json' },
      };
      axios.get(url, config).then((result) => {
        const timelinesId: { id: number }[] = result.data.map((timeline: { id: number }) => ({
          id: timeline.id,
        }));
        resolve(timelinesId);
      });
    } catch (err) {
      reject(err);
    }
  });
}

export async function getTimeline(id: number): Promise<Timeline_Interface> {
  return new Promise((resolve, reject) => {
    try {
      const url = `${process.env.REACT_APP_SERV_HOST}/timelines/${id}`;
      const config: AxiosRequestConfig = {
        method: 'get',
        headers: { 'Content-Type': 'application/json' },
      };
      axios.get(url, config).then(
        (result) => {
          if (result.data.data.UserModels[0]) {
            result.data.data.UserModels[0].id = result.data.data.UserModels[0].id_user;
          }
          const timeline: Timeline_Interface = result.data.data;
          timeline.category = result.data.data.category.split(', ');
          resolve(timeline);
        },
        (err) => reject(err),
      );
    } catch (err) {
      reject(err);
    }
  });
}

export async function getMax(category?: string | null, search?: string | null): Promise<number> {
  return new Promise((resolve, reject) => {
    try {
      const url = `${process.env.REACT_APP_SERV_HOST}/timelines/max`;
      const config: AxiosRequestConfig = {
        method: 'get',
        headers: { 'Content-Type': 'application/json' },
      };
      if (category) config.params.category = category;
      if (search) config.params.search = search;
      axios.get(url, config).then((result) => {
        resolve(result.data.data);
      });
    } catch (err) {
      reject(err);
    }
  });
}

export async function getEvents(idTimeline: number): Promise<Event_Interface[]> {
  return new Promise((resolve, reject) => {
    try {
      const url = `${process.env.REACT_APP_SERV_HOST}/events/timeline/${idTimeline}`;
      const config: AxiosRequestConfig = {
        method: 'get',
        headers: { 'Content-Type': 'application/json' },
      };
      axios.get(url, config).then((result) => {
        const events: Event_Interface[] = [];
        if (result.data.data) {
          result.data.data.forEach((event: Event_Interface) => {
            event.month = Number(event.month);
            events.push(event);
          });
        }
        resolve(events);
      });
    } catch (err) {
      reject(err);
    }
  });
}

export async function getTimelinesByEvent(search: string | string[] | null): Promise<Timeline_Interface[]> {
  return new Promise((resolve, reject) => {
    try {
      const url = `${process.env.REACT_APP_SERV_HOST}/events/`;
      const config: AxiosRequestConfig = {
        method: 'get',
        headers: { 'Content-Type': 'application/json' },
        params: {
          search: search,
        },
      };
      axios.get(url, config).then((result) => {
        let timelines: Timeline_Interface[] = [];
        let uniqueTimeline: (Timeline_Interface | undefined)[] = [];
        if (result.data.data) {
          result.data.data.forEach((eventWithTimeline: any) => {
            timelines.push(eventWithTimeline.TimelineModels[0]);
            uniqueTimeline = Array.from(new Set(timelines.map((timeline) => timeline.id))).map((id) => {
              return timelines.find((timelines) => timelines.id === id);
            });
          });
        }
        timelines = [];
        uniqueTimeline.forEach((timeline) => {
          timeline && timelines.push(timeline);
        });
        resolve(timelines);
      });
    } catch (err) {
      reject(err);
    }
  });
}

export async function getAuthorIdByTimelineId(id: number): Promise<number> {
  return new Promise((resolve, reject) => {
    try {
      const url = `${process.env.REACT_APP_SERV_HOST}/timelines/${id}/author`;
      const config: AxiosRequestConfig = {
        method: 'get',
        headers: { 'Content-Type': 'application/json' },
        params: {
          search: id,
        },
      };
      axios.get(url, config).then((result) => {
        resolve(result.data.data);
      });
    } catch (err) {
      reject(err);
    }
  });
}

export async function getPeriod(id: number): Promise<string> {
  return new Promise((resolve, reject) => {
    try {
      const url = `${process.env.REACT_APP_SERV_HOST}/timelines/${id}/period`;
      const config: AxiosRequestConfig = {
        method: 'get',
        headers: { 'Content-Type': 'application/json' },
      };
      axios.get(url, config).then((result) => {
        resolve(result.data.data);
      });
    } catch (err) {
      reject(err);
    }
  });
}

export async function getCardInfos(id: number): Promise<Timeline_Interface> {
  return new Promise((resolve, reject) => {
    try {
      const url = `${process.env.REACT_APP_SERV_HOST}/timelines/${id}`;
      const config: AxiosRequestConfig = {
        method: 'get',
        headers: { 'Content-Type': 'application/json' },
      };
      axios.get(url, config).then((result) => {
        resolve(result.data.data);
      });
    } catch (err) {
      reject(err);
    }
  });
}

export async function postTimeline(
  title: string,
  categories: string[],
  img_background: string,
  visible: boolean,
  step: number,
  status: string,
  type_story: string,
  tone: string,
  used_ai: boolean,
): Promise<Timeline_Interface> {
  return new Promise((resolve, reject) => {
    try {
      const category = categoriesArrayIntoString(categories);
      const config: AxiosRequestConfig = {
        method: 'post',
        url: `${process.env.REACT_APP_SERV_HOST}/timelines`,
        withCredentials: true,
        data: { title, category, img_background, visible, step, status, type_story, tone, used_ai },
      };
      axios(config).then((result) => {
        const timeline: Timeline_Interface = result.data.data[0];
        resolve(timeline);
      });
    } catch (err) {
      reject(err);
    }
  });
}

export async function postEvent(
  id_timeline: number,
  title: string,
  description: string,
  day: number,
  month: number,
  year: number,
  display_roman_numbers: boolean,
  x_position: number,
  y_position: number,
  img: string,
  img_offset: string,
): Promise<Event_Interface> {
  return new Promise((resolve, reject) => {
    try {
      const config: AxiosRequestConfig = {
        method: 'post',
        url: `${process.env.REACT_APP_SERV_HOST}/events`,
        withCredentials: true,
        data: {
          id_timeline,
          title,
          description,
          day,
          month,
          year,
          display_roman_numbers,
          x_position,
          y_position,
          img,
          img_offset,
        },
      };
      axios(config).then((result) => {
        const event: Event_Interface = result.data.data[0];
        resolve(event);
      });
    } catch (err) {
      reject(err);
    }
  });
}

export async function deleteEvent(id: number): Promise<Event_Interface> {
  return new Promise((resolve, reject) => {
    try {
      const url = `${process.env.REACT_APP_SERV_HOST}/events/${id}`;
      const config: AxiosRequestConfig = {
        method: 'delete',
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true,
      };
      axios.delete(url, config).then((result) => {
        const event: Event_Interface = result.data.data[0];
        resolve(event);
      });
    } catch (err) {
      reject(err);
    }
  });
}

export async function deleteTimeline(id: number): Promise<Event_Interface> {
  return new Promise((resolve, reject) => {
    try {
      const url = `${process.env.REACT_APP_SERV_HOST}/timelines/${id}`;
      const config: AxiosRequestConfig = {
        method: 'delete',
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true,
      };
      axios.delete(url, config).then((result) => {
        resolve(result.data.data[0]);
      });
    } catch (err) {
      reject(err);
    }
  });
}

export async function putTimeline(
  id_timeline: number,
  title: string,
  categories: string[],
  img_background: string,
  visible: boolean,
  step: number,
  status: string,
): Promise<Timeline_Interface> {
  return new Promise((resolve, reject) => {
    try {
      const category = categoriesArrayIntoString(categories);
      const config: AxiosRequestConfig = {
        method: 'put',
        url: `${process.env.REACT_APP_SERV_HOST}/timelines/${id_timeline}`,
        withCredentials: true,
        data: { title, category, img_background, visible, step, status },
      };
      axios(config).then((result) => {
        const timeline: Timeline_Interface = result.data.data;
        resolve(timeline);
      });
    } catch (err) {
      reject(err);
    }
  });
}

export async function putEvent(
  id_event: number,
  title: string,
  description: string,
  day: number,
  month: number,
  year: number,
  display_roman_numbers: boolean,
  x_position: number,
  y_position: number,
  img: string,
  img_offset: string,
): Promise<Event_Interface> {
  return new Promise((resolve, reject) => {
    try {
      const config: AxiosRequestConfig = {
        method: 'put',
        url: `${process.env.REACT_APP_SERV_HOST}/events/${id_event}`,
        withCredentials: true,
        data: { title, description, day, month, year, display_roman_numbers, x_position, y_position, img, img_offset },
      };
      axios(config).then((result) => {
        const event: Event_Interface = result.data.data;
        resolve(event);
      });
    } catch (err) {
      reject(err);
    }
  });
}

export async function getEventGeneratedWithOpenAI(
  timelineId: number,
  requestFromUser: string,
): Promise<EventByAI_Interface> {
  return new Promise((resolve, reject) => {
    try {
      const config: AxiosRequestConfig = {
        method: 'post',
        url: `${process.env.REACT_APP_SERV_HOST}/events/generateEvent`,
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true,
        data: { timelineId, requestFromUser },
      };
      axios(config).then((result) => {
        resolve(result.data.data);
      });
    } catch (err) {
      reject(err);
    }
  });
}

export async function getTimelineGeneratedWithOpenAI(
  requestFromUser: string,
): Promise<{ timeline: Timeline_Interface; events: Event_Interface[] }> {
  return new Promise((resolve, reject) => {
    try {
      const config: AxiosRequestConfig = {
        method: 'post',
        url: `${process.env.REACT_APP_SERV_HOST}/timelines/generateTimeline`,
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true,
        data: { requestFromUser },
      };
      axios(config).then((result) => {
        console.log(result);
        const timeline: Timeline_Interface = result.data.data.timeline;
        timeline.category = result.data.data.timeline.category.split(', ');
        const events: Event_Interface[] = result.data.data.events;
        resolve({ timeline, events });
      });
    } catch (err) {
      reject(err);
    }
  });
}
