import axios from 'axios';
import { LoggedUser_Interface } from '../interfaces/LoggedUser.interface';

export async function login(email: string, password: string): Promise<LoggedUser_Interface> {
  return new Promise((resolve, reject) => {
    try {
      const url = `${process.env.REACT_APP_SERV_HOST}/login`;
      axios
        .post(
          url,
          {
            email,
            password,
          },
          {
            //AxiosRequestConfig parameter
            withCredentials: true, //correct
          },
        )
        .then(
          (user) => {
            const loggedUser: LoggedUser_Interface = user.data.data;
            resolve(loggedUser);
          },
          (err) => reject(err),
        );
    } catch (err) {
      reject(err);
    }
  });
}

export async function signup(email: string, password: string, firstname: string, lastname: string): Promise<void> {
  return new Promise((resolve, reject) => {
    try {
      const url = `${process.env.REACT_APP_SERV_HOST}/signup`;
      axios
        .post(url, {
          email,
          password,
          firstname,
          lastname,
          gender: 'male',
        })
        .then(
          () => {
            resolve();
          },
          (err) => reject(err),
        );
    } catch (err) {
      reject(err);
    }
  });
}

export async function externalSignup(
  email: string,
  firstname: string,
  lastname: string,
  oauth_provider: string,
  oauth_uid: string,
  picture: string,
): Promise<LoggedUser_Interface> {
  return new Promise((resolve, reject) => {
    try {
      const url = `${process.env.REACT_APP_SERV_HOST}/externalsignup`;
      axios
        .post(
          url,
          {
            email,
            firstname,
            lastname,
            oauth_provider,
            oauth_uid,
            picture,
          },
          {
            //AxiosRequestConfig parameter
            withCredentials: true, //correct
          },
        )
        .then(
          (user) => {
            const loggedUser: LoggedUser_Interface = user.data.data;
            resolve(loggedUser);
          },
          (err) => reject(err),
        );
    } catch (err) {
      reject(err);
    }
  });
}

export async function validate(rand: string, id: string): Promise<LoggedUser_Interface> {
  return new Promise((resolve, reject) => {
    try {
      const url = `${process.env.REACT_APP_SERV_HOST}/validate`;
      axios
        .post(
          url,
          {
            id,
            rand,
          },
          {
            //AxiosRequestConfig parameter
            withCredentials: true, //correct
          },
        )
        .then(
          (result) => {
            const user: LoggedUser_Interface = result.data.data;
            resolve(user);
          },
          (err) => reject(err),
        );
    } catch (err) {
      reject(err);
    }
  });
}

export async function forgotPassword(email: string): Promise<void> {
  return new Promise((resolve, reject) => {
    try {
      const url = `${process.env.REACT_APP_SERV_HOST}/forgot/${email}`;
      axios.post(url, {}).then(
        () => {
          resolve();
        },
        (err) => reject(err),
      );
    } catch (err) {
      reject(err);
    }
  });
}
