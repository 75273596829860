import React, { useState } from 'react';
import styles from './TokensModal.module.css';
import { Modal, Button } from 'react-bootstrap';
import logo from '../../../../src/assets/img/Logo_eStory.png';

export type ConfirmModalProps = {
  show: boolean;
  handleClose: () => void;
  handleConfirm: () => void;
};

export const TokensModal: React.FunctionComponent<ConfirmModalProps> = (props: ConfirmModalProps) => {
  const { show, handleClose, handleConfirm } = props;
  const [count, setCount] = useState(5);
  const [price, setPrice] = useState(1);
  const url = `${process.env.REACT_APP_LINK_TOKENS}`;
  function incrementCount() {
    setCount(count + 5);
    setPrice(price + 1);
  }
  function decrementCount() {
    if (count > 5) {
      setCount(count - 5);
      setPrice(price - 1);
    }
  }

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header className={styles.header} style={{ border: 'none' }}>
          <Modal.Title className={styles.title}>You are out of tokens ✨</Modal.Title>
        </Modal.Header>
        <Modal.Body className={styles.body}>
          <img src={logo} className={styles.logo} />
          <div>Buy {count} tokens</div>
          <div>{price}$ USD</div>
        </Modal.Body>
        <Modal.Footer className={styles.footer}>
          <Button onClick={handleClose} className={styles.cancelButton}>
            Cancel
          </Button>
          <Button onClick={handleConfirm} href={url} className={styles.confirmButton}>
            Continue
          </Button>
          <a className={styles.subLink} href="/pricing">
            Or purchase a subscription
          </a>
        </Modal.Footer>
      </Modal>
    </>
  );
};
