import React from 'react';
import styles from './StepBarFiction.module.css';

export type StepBarFictionProps = {
  stepNumber: number;
  goToStep: (step: number) => void;
};

export const StepBarFiction: React.FunctionComponent<StepBarFictionProps> = (props: StepBarFictionProps) => {
  return (
    <>
      <div className={styles.stepBar}>
        <div className={styles.ligne}>
          <div className={styles.horizontalLine}></div>
        </div>
        {props.stepNumber == 1 ? (
          <div className={styles.bar}>
            <div id="1" className={styles.circleStep2} onClick={() => props.goToStep(1)}>
              <p>1</p>
            </div>
            <div id="2" className={styles.circleStep} onClick={() => props.goToStep(2)}>
              <p>2</p>
            </div>
          </div>
        ) : (
          <div className={styles.bar}>
            <div id="1" className={styles.circleStep} onClick={() => props.goToStep(1)}>
              <p>1</p>
            </div>
            <div id="2" className={styles.circleStep2} onClick={() => props.goToStep(2)}>
              <p>2</p>
            </div>
          </div>
        )}
        <div className={styles.titles}>
          <div className={styles.titleDiv}>
            <p>Story overview</p>
          </div>
          <div className={styles.titleDiv}>
            <p>Add events</p>
          </div>
        </div>
      </div>
    </>
  );
};
