export const listCategoryBD = ['People', 'Historical', 'Sport', 'Science', 'Art', 'Places', 'Quirky', 'Other'];
export const listCategoryDisplayed = [
  'Famous people',
  'Historical episodes',
  'Sports and entertainment',
  'Science and technologies',
  'Art and literature',
  'Places',
  'Quirky',
  'Other',
];

export const categoriesArrayIntoString = (categoriesArray: string[]) => {
  let categoriesString = '';
  for (let i = 0; i < categoriesArray.length; i++) {
    categoriesString = categoriesString + categoriesArray[i];
    if (i !== categoriesArray.length - 1) {
      categoriesString = categoriesString + ', ';
    }
  }
  return categoriesString;
};
