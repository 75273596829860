import axios, { AxiosRequestConfig } from 'axios';
import { Media_Interface } from '../interfaces/Media.interface';

export async function postMedia(
  id_timeline: number,
  title: string,
  link: string,
  img: string,
  description: string,
  date: string,
  type: string,
  service: string,
): Promise<Media_Interface> {
  return new Promise((resolve, reject) => {
    try {
      const config: AxiosRequestConfig = {
        method: 'post',
        url: `${process.env.REACT_APP_SERV_HOST}/mediaitems`,
        withCredentials: true,
        data: { id_timeline, title, link, img, description, date, type, service },
      };
      axios(config).then((result) => {
        const media: Media_Interface = result.data.data[0];
        resolve(media);
      });
    } catch (err) {
      reject(err);
    }
  });
}

export async function deleteMedia(id: number): Promise<Media_Interface> {
  return new Promise((resolve, reject) => {
    try {
      const url = `${process.env.REACT_APP_SERV_HOST}/mediaitems/${id}`;
      const config: AxiosRequestConfig = {
        method: 'delete',
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true,
      };
      axios.delete(url, config).then((result) => {
        const event: Media_Interface = result.data.data[0];
        resolve(event);
      });
    } catch (err) {
      reject(err);
    }
  });
}
