import React, { useState } from 'react';
import { Event_Interface } from '../../../interfaces/Event.interface';
import { generateDateString } from '../../../utils/date.util';
import styles from './EventDisplayVertical.module.scss';
import { initiateEvents } from '../util';

interface EventsDisplayVProps {
  events: Event_Interface[];
}

export const EventDisplayVertical: React.FunctionComponent<EventsDisplayVProps> = (props: EventsDisplayVProps) => {
  const [events] = useState<{ event: Event_Interface; date: Date }[]>(initiateEvents(props.events));
  const getDeviceType = () => {
    const ua = navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
      return 'tablet';
    }
    if (
      /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)
    ) {
      return 'mobile';
    }
    return 'desktop';
  };

  return getDeviceType() === 'mobile' ? (
    <div className={styles.containerMobile}>
      {events.map((event, key) => {
        const generatedDate: string = generateDateString(
          event.event.year,
          event.event.month,
          event.event.day,
          event.event.display_roman_numbers,
        );
        const splitedImg = event.event.img && event.event.img.split('/upload/');
        const img =
          event.event.img &&
          splitedImg &&
          splitedImg[0] + '/upload/c_fill,ar_6:4,g_auto/q_auto,f_auto/' + splitedImg[1];
        return (
          <div key={key} className={styles.boxMobile}>
            <div className={styles.contentMobile}>
              <h4 className={styles.generatedDate}>{generatedDate}</h4>
              {event.event.img != null ? (
                <div className={`${styles.pImg}`}>
                  <img className={styles.pImg2} src={img}></img>
                </div>
              ) : (
                <div className={styles.pImg3}></div>
              )}
              <div className={styles.boxtimeline}>
                <h4 className={styles.boxtimelineTitle}>{event.event.title}</h4>
                <p className={styles.boxtimelineDescription}>{event.event.description}</p>
              </div>
              <span className={styles.circleMobile} />
            </div>
          </div>
        );
      })}
    </div>
  ) : (
    <div className={styles.container}>
      {events.map((event, key) => {
        const generatedDate: string = generateDateString(
          event.event.year,
          event.event.month,
          event.event.day,
          event.event.display_roman_numbers,
        );
        const splitedImg = event.event.img && event.event.img.split('/upload/');
        const img =
          event.event.img &&
          splitedImg &&
          splitedImg[0] + '/upload/c_fill,ar_6:4,g_auto/q_auto,f_auto/' + splitedImg[1];
        return (
          <div key={key} className={styles.box}>
            <div className={styles.content}>
              <h4 className={styles.generatedDate}>{generatedDate}</h4>
              {event.event.img != null ? (
                <div className={`${styles.pImg}`}>
                  <img className={styles.pImg2} src={img}></img>
                </div>
              ) : (
                <div className={styles.pImg3}></div>
              )}
              <div className={styles.boxtimeline}>
                <h4 className={styles.boxtimelineTitle}>{event.event.title}</h4>
                <p className={styles.boxtimelineDescription}>{event.event.description}</p>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
