import React, { useState, useEffect, useRef } from 'react';
import ReactGA from 'react-ga';
import { useLocation } from 'react-router-dom';
import { StepBar } from './StepBar/StepBar';
import { TimelineStep } from './TimelineStep/TimelineStep';
import { EventPreviewStep } from './EventPreviewStep/EventPreviewStep';
import { Helmet } from 'react-helmet';
import styles from './TimelineCreation.module.css';
import {
  postEvent,
  postTimeline,
  deleteEvent,
  putTimeline,
  putEvent,
  fetchMedia,
  deleteTimeline,
} from '../../api/timeline.api';
import { Event_Interface } from '../../interfaces/Event.interface';
import { BrowserRouter as Router, Redirect, useHistory } from 'react-router-dom';
import { NoPublishModal } from './Modals/NoPublishModal';
import { MediaStep } from './MediaStep/MediaStep';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { Media_Interface } from '../../interfaces/Media.interface';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { ConfirmModal } from '../../components/Modal/ConfirmModal/ConfirmModal';
import { Spinner } from 'react-bootstrap';
import { RequestTimeline } from './RequestAi/RequestTimeline';
import { RequestAI } from './RequestAi/RequestAI';
import { LoadingAI } from '../Loading/LoadingAI';
import wand from '../../assets/img/wand_white.png';
import { TokensModal } from '../../components/Modal/TokensModal/TokensModal';

export const TimelineCreation: React.FunctionComponent = () => {
  const location = useLocation();
  useEffect(() => {
    if (window.location.hostname === 'estory.io') ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  const [title, setTitle] = useState<string>('');
  const [category, setCategory] = useState<string[]>([]);
  const [imgUrl, setImgUrl] = useState<string>('');
  const [selectedKey, setSelectedKey] = useState(0);
  const [visible, setVisible] = useState<boolean>(false);
  const [newTimelineId, setNewTimelineId] = useState<number | undefined>();
  const loggedUser = useSelector((state: RootState) => state.loggedUser);
  const [medias, setMedias] = useState<Array<Media_Interface>>([]);
  const [modalConfirmShow, setModalConfirmShow] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingAI, setLoadingAI] = useState<boolean>(false);
  const history = useHistory();
  const [showAIBar, setShowAIBar] = useState<boolean>(false);
  const node = useRef<HTMLDivElement>(null);
  const [notificationToUser, setNotificationToUser] = useState<string>('');
  const [newNotification, setNewNotification] = useState<boolean>(false);

  const goToTimelineModified = () => {
    const url = '/timeline/' + newTimelineId + '/' + title;
    history.push(url);
  };

  const onSetTitle = (title: string) => {
    setTitle(title);
  };

  const onSetCategory = (category: string[]) => {
    setCategory(category);
  };
  const onSetVisible = (visible: boolean) => {
    setVisible(visible);
  };
  const onSetImage = (image: string) => {
    setImgUrl(image);
  };

  const handleButtonAi = () => {
    setShowAIBar(true);
  };

  const handleClickOutsideAIBar = (event: MouseEvent) => {
    if (node.current && !node.current.contains(event.target as Node)) {
      setShowAIBar(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutsideAIBar);
    return () => {
      document.removeEventListener('mousedown', handleClickOutsideAIBar);
    };
  }, []);

  const timelinePost = (title: string, categories: string[], imgUrl: string, visible: boolean, step: number) => {
    setLoading(true);
    postTimeline(title, categories, imgUrl, visible, step, 'in progress', '', '', false).then((resultat) => {
      setNewTimelineId(Number(resultat.id));
      changeStep(numStep);
      setLoading(false);
    });
  };

  const modifyTimeline = (
    id: number,
    title: string,
    categories: string[],
    imgUrl: string,
    visible: boolean,
    step: number,
  ) => {
    setLoading(true);
    putTimeline(id, title, categories, imgUrl, visible, step, 'in progress').then(() => {
      changeStep(numStep);
      setLoading(false);
    });
  };

  const publishTimeline = (
    id: number,
    title: string,
    categories: string[],
    imgUrl: string,
    visible: boolean,
    step: number,
  ) => {
    setLoading(true);
    putTimeline(id, title, categories, imgUrl, visible, step, 'published')
      .then(() => {
        <Router>
          <Redirect
            to={{
              pathname: '/home',
            }}
          />
        </Router>;
      })
      .then(() => goToTimelineModified());
  };

  const [modalShow, setModalShow] = useState<boolean>(false);

  /**
   * Change Step
   */
  const [numStep, setNumStep] = useState<number>(1);

  const changeStep = (step: number) => {
    if (step == 1 || step == 2) {
      setNumStep(step + 1);
    }
  };

  const goToStep = (step: number) => {
    {
      newTimelineId != null || newTimelineId != undefined ? setNumStep(step) : null;
    }
  };

  /**
   * Step 3 : Events
   */
  const [events, setEvents] = useState<Event_Interface[]>([]);

  const eventModification = (event: Event_Interface, index: number) => {
    putEvent(
      Number(event.id),
      event.title,
      event.description,
      event.day,
      event.month,
      Number(event.year),
      event.display_roman_numbers,
      event.x_position,
      event.y_position,
      event.img,
      '',
    ).then((result: Event_Interface) => {
      events[index] = result;
      setEvents(events.slice(0));
    });
  };

  const addEvent = (
    id_timeline: number,
    title: string,
    description: string,
    day: number,
    month: number,
    year: number,
    display_roman_numbers: boolean,
    x_position: number,
    y_position: number,
    img: string,
  ) => {
    postEvent(
      id_timeline,
      title,
      description,
      day,
      month,
      year,
      display_roman_numbers,
      x_position,
      y_position,
      img,
      '0px',
    ).then((event) => {
      events.push(event);
      const sortedEvents = events.sort((a, b) => (a.year > b.year ? 1 : -1));
      setEvents(sortedEvents.slice(0));
      setSelectedKey(sortedEvents.findIndex((e) => e.id === event.id));
    });
  };

  const removeEvent = (id: bigint) => {
    deleteEvent(Number(id)).then(() => {
      const ids = events.map((event) => event.id);
      const index = ids.indexOf(id);
      events.splice(index, 1);
      setEvents(events.slice(0));
      if (selectedKey >= events.length && selectedKey) setSelectedKey(events.length - 1);
    });
  };

  useEffect(() => {
    if (newTimelineId) {
      (async () => {
        await fetchMedia(BigInt(newTimelineId)).then((result) => {
          result.forEach((media) => {
            medias.push(media);
            setMedias(medias.slice(0));
          });
        });
      })();
    }
  }, [newTimelineId]);

  useEffect(() => {
    if (selectedKey >= events.length && selectedKey) setSelectedKey(events.length - 1);
  }, [events, selectedKey]);

  const addMedia = (media: Media_Interface) => {
    medias.push(media);
    setMedias(medias);
  };

  const removeMedia = (id: bigint) => {
    medias.map((media) => {
      if (media.id == id) {
        medias.splice(medias.indexOf(media), 1);
        setMedias(medias.slice(0));
      }
    });
  };

  useEffect(() => {
    if (loadingAI) setShowAIBar(false);
  }, [loadingAI]);

  return (
    <>
      <div className={`${loadingAI || showAIBar ? styles.blur : ''}`}>
        <Helmet>
          <title>eStory | Create Timeline</title>
        </Helmet>
        <div className={styles.backgroundImage}>
          <div>
            <div className={styles.formContainer}>
              <div className={styles.stepBarContainer}>
                <StepBar
                  created={newTimelineId !== null && newTimelineId !== undefined}
                  stepNumber={numStep}
                  goToStep={(numStep: number) => goToStep(numStep)}
                />
              </div>
              {numStep == 1 && newTimelineId == null ? (
                <>
                  <div className={styles.barAI} ref={node}>
                    <RequestAI
                      step={numStep}
                      requestTimeline={{
                        setTimelineId: setNewTimelineId,
                        setTitle: setTitle,
                        setCategories: setCategory,
                        setVisibility: setVisible,
                        setStep: setNumStep,
                        setImgUrl: setImgUrl,
                        events: events,
                        setEvents: setEvents,
                        setLoading: setLoadingAI,
                      }}
                      requestEvent={{
                        timelineId: newTimelineId ?? -1,
                        title: title,
                        imgUrl: imgUrl,
                        events: events,
                        selectedKey: selectedKey,
                        setSelectedKey: setSelectedKey,
                        setEvents: setEvents,
                        modifyEvent: eventModification,
                        removeEvent: removeEvent,
                        setLoading: setLoadingAI,
                        setNotificationToUser: setNotificationToUser,
                        setNewNotification: setNewNotification,
                      }}
                    />
                  </div>
                </>
              ) : (
                <></>
              )}
              {numStep == 3 && newTimelineId != null ? (
                <div className={styles.stepContainer2}>
                  <EventPreviewStep
                    timelineId={newTimelineId}
                    title={title}
                    imgUrl={imgUrl}
                    events={events}
                    selectedKey={selectedKey}
                    setSelectedKey={setSelectedKey}
                    addEvent={addEvent}
                    modifyEvent={eventModification}
                    removeEvent={removeEvent}
                  />
                </div>
              ) : numStep == 2 && newTimelineId != null ? (
                <div className={styles.stepContainer}>
                  <MediaStep
                    id_timeline={newTimelineId}
                    title={title}
                    medias={medias}
                    addMedia={addMedia}
                    removeMedia={removeMedia}
                  />
                </div>
              ) : (
                <div className={styles.stepContainer}>
                  <TimelineStep
                    title={title}
                    onSetTitle={(title) => onSetTitle(title)}
                    onSetCategory={(category) => onSetCategory(category)}
                    onSetImage={(image) => onSetImage(image)}
                    categories={category}
                    imgUrl={imgUrl}
                    visible={visible}
                    onSetVisible={(visible) => onSetVisible(visible)}
                  />
                </div>
              )}
              {loading ? (
                <div className={styles.bottomContainer}>
                  <Spinner animation="grow" className={styles.loader} />
                </div>
              ) : numStep == 1 ? (
                <div className={styles.bottomContainer}>
                  {newTimelineId && (
                    <button className={styles.deleteButton} onClick={() => setModalConfirmShow(true)} disabled={!title}>
                      <FontAwesomeIcon icon={faTrashAlt} className={styles.trashIcon} />
                      Delete
                    </button>
                  )}
                  <button
                    className={styles.button}
                    disabled={title === ''}
                    onClick={() => {
                      if (newTimelineId != null) {
                        modifyTimeline(newTimelineId, title, category, imgUrl, visible, numStep);
                      } else {
                        timelinePost(title, category, imgUrl, visible, numStep);
                      }
                    }}
                  >
                    Save and continue
                  </button>
                </div>
              ) : numStep == 3 ? (
                <div className={styles.bottomContainer}>
                  {newTimelineId && (
                    <button className={styles.deleteButton} onClick={() => setModalConfirmShow(true)} disabled={!title}>
                      <FontAwesomeIcon icon={faTrashAlt} className={styles.trashIcon} />
                      Delete
                    </button>
                  )}
                  <button
                    className={styles.button2}
                    onClick={() => {
                      if (newTimelineId != null && events.length > 3) {
                        publishTimeline(newTimelineId, title, category, imgUrl, visible, numStep);
                      } else {
                        setModalShow(true);
                      }
                    }}
                  >
                    Publish
                  </button>
                  <button className={styles.buttonEditAi} onClick={handleButtonAi}>
                    <img className={styles.wandlogo} src={wand}></img>
                    Edit with AI
                  </button>
                </div>
              ) : (
                <div className={styles.bottomContainer}>
                  {newTimelineId && (
                    <button className={styles.deleteButton} onClick={() => setModalConfirmShow(true)} disabled={!title}>
                      <FontAwesomeIcon icon={faTrashAlt} className={styles.trashIcon} />
                      Delete
                    </button>
                  )}
                  <button
                    className={styles.button}
                    onClick={() => {
                      changeStep(numStep);
                    }}
                  >
                    Save and continue
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
        <ConfirmModal
          show={modalConfirmShow}
          handleClose={() => setModalConfirmShow(false)}
          text={'Are you sure to delete this timeline ?'}
          closeButtonText={'Cancel'}
          confirmButtonText={'Delete'}
          handleConfirm={() =>
            deleteTimeline(Number(newTimelineId)).then(() =>
              history.push(
                `/user/${loggedUser?.id}/${loggedUser?.firstname.replaceAll(
                  ' ',
                  '-',
                )}-${loggedUser?.lastname.replaceAll(' ', '-')}`,
              ),
            )
          }
        />
        <NoPublishModal show={modalShow} onHide={() => setModalShow(false)} />
      </div>
      <LoadingAI show={loadingAI} />
      {numStep == 3 && showAIBar ? (
        <div className={styles.barAI} ref={node}>
          <RequestAI
            step={numStep}
            requestTimeline={{
              setTimelineId: setNewTimelineId,
              setTitle: setTitle,
              setCategories: setCategory,
              setVisibility: setVisible,
              setStep: setNumStep,
              setImgUrl: setImgUrl,
              events: events,
              setEvents: setEvents,
              setLoading: setLoadingAI,
            }}
            requestEvent={{
              timelineId: newTimelineId ?? -1,
              title: title,
              imgUrl: imgUrl,
              events: events,
              selectedKey: selectedKey,
              setSelectedKey: setSelectedKey,
              setEvents: setEvents,
              modifyEvent: eventModification,
              removeEvent: removeEvent,
              setLoading: setLoadingAI,
              setNotificationToUser: setNotificationToUser,
              setNewNotification: setNewNotification,
            }}
          />
        </div>
      ) : (
        <></>
      )}
    </>
  );
};
