import React from 'react';
import styles from '../TimelineModalShare/TimelineModalShare.module.css';
import { Modal } from 'react-bootstrap';

export type TimelineModalEmbedProps = {
  id: string;
  show?: boolean;
  onHide: () => void;
};

export const TimelineModalEmbed: React.FC<TimelineModalEmbedProps> = (props: TimelineModalEmbedProps) => {
  return (
    <Modal {...props} className={styles.modal} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header className={styles.upBar} closeButton>
        <Modal.Title>Embed this timeline to your website</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className={styles.shareContainer}>
          <input
            type="text"
            name="Timeline_link"
            className={styles.modalInput}
            value={`<iframe src='https://${window.location.hostname}/api/timeline/${props.id}' />`}
          ></input>
        </div>
      </Modal.Body>
    </Modal>
  );
};
