import React, { ReactElement, useState } from 'react';
import styles from './SearchBar.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { setSearchActionCreator, removeSearchActionCreator, selectSearch } from '../../../reducers/SearchBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { Link, useHistory } from 'react-router-dom';
import { SearchDropDown } from './SearchDropDown';

export const SearchBar: React.FC = (): ReactElement => {
  const dispatch = useDispatch();
  const search = useSelector(selectSearch);
  const history = useHistory();
  const [displayDropDown, setDisplayDropDown] = useState<boolean>(true);

  const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDisplayDropDown(true);
    dispatch(setSearchActionCreator(event.target.value));
  };

  function onKeyUp(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key === 'Enter' && search) {
      setDisplayDropDown(false);
      history.push(`/search?query=${search}`);
      dispatch(removeSearchActionCreator());
    }
  }

  return (
    <div className={styles.searchBar}>
      <div className={styles.searchForm}>
        <input
          placeholder="Search..."
          className={`js-search`}
          type="text"
          onChange={handleInput}
          onKeyPress={onKeyUp}
          value={search ? search : ''}
        ></input>
        {search ? (
          <Link to={`/search/${search}`}>
            <FontAwesomeIcon icon={faSearch} className={styles.searchIcon} />
          </Link>
        ) : (
          <FontAwesomeIcon icon={faSearch} className={styles.searchIcon} />
        )}
      </div>
      {displayDropDown && search && <SearchDropDown search={search} />}
    </div>
  );
};
