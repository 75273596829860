import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import styles from './Login.module.css';
import { Login } from './LoginMode/LoginMode';
import { SignUp } from './SignUpMode/SignUpMode';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import GoogleLogin from 'react-google-login';
import { setLoggedUserActionCreator } from '../../reducers/LoggedUser';
import { externalSignup } from '../../api/auth.api';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faGoogle } from '@fortawesome/free-brands-svg-icons';
import { InfoModal } from '../../components/Modal/InfoModal/InfoModal';

export function LoginPage(): JSX.Element {
  const location = useLocation();
  useEffect(() => {
    if (window.location.hostname === 'estory.io') ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  const [mode, setMode] = useState<boolean>(false);
  const [showInfoModal, setShowInfoModal] = useState<boolean>(false);
  const dispatch = useDispatch();

  return (
    <>
      <Helmet>
        <title>eStory | Login</title>
        <meta property="og:type" content="siteweb" />
        <meta name="twitter:title" content="eStory | Login" />
        <meta
          name="description"
          content="Create and discover timelines on history, inspiring people, sciences, arts, sports and other topics."
        />
        <meta property="og:title" content="eStory | Login" />
        <meta
          property="og:description"
          content="Create and discover timelines on history, inspiring people, sciences, arts, sports and other topics."
        />
        <meta
          property="og:image"
          content="https://res.cloudinary.com/dk2ghb1pg/image/upload/v1531942668/Logo_eStory_simple_klalco.png"
        />
        <meta property="og:url" content="https://estory.io/login" />
      </Helmet>
      <div className={styles.backgroundImage}>
        <div className={styles.log}>
          <a href="/home">
            <img
              src="https://res.cloudinary.com/dk2ghb1pg/image/upload/w_220,h_73,c_scale/v1531942668/Logo_eStory_simple_klalco.png"
              alt="logo"
              width="160"
            ></img>
          </a>
          <div className={styles.externalLogin}>
            <GoogleLogin
              clientId={process.env.REACT_APP_GOOGLE_APP_ID ? process.env.REACT_APP_GOOGLE_APP_ID : ''}
              render={(renderProps) => (
                <button className={styles.loginGoogle} onClick={renderProps.onClick}>
                  <FontAwesomeIcon icon={faGoogle} className={styles.brandIcon} />
                  Continue with Google
                </button>
              )}
              onSuccess={(response: any) =>
                externalSignup(
                  response.profileObj.email,
                  response.profileObj.givenName,
                  response.profileObj.familyName,
                  'google',
                  response.profileObj.googleId,
                  response.profileObj.imageUrl,
                ).then(
                  (loggedUser) => {
                    localStorage.setItem('estory_user', loggedUser.id.toString());
                    dispatch(setLoggedUserActionCreator(loggedUser));
                  },
                  (error) => (error.response.status === 409 ? setShowInfoModal(true) : null),
                )
              }
              cookiePolicy={'single_host_origin'}
            />
            <FacebookLogin
              appId={process.env.REACT_APP_FACEBOOK_APP_ID}
              fields="name,email,picture,first_name,last_name"
              textButton="Continue with Facebook"
              render={(renderProps: any) => (
                <button className={styles.loginFacebook} onClick={renderProps.onClick}>
                  <FontAwesomeIcon icon={faFacebookF} className={styles.brandIcon} />
                  Continue with Facebook
                </button>
              )}
              callback={(response: any) => {
                if (response && response.status !== 'unknown') {
                  externalSignup(
                    response.email,
                    response.first_name,
                    response.last_name,
                    response.graphDomain,
                    response.userID,
                    response.picture.data.url,
                  ).then(
                    (loggedUser) => {
                      localStorage.setItem('estory_user', loggedUser.id.toString());
                      dispatch(setLoggedUserActionCreator(loggedUser));
                    },
                    (error) => (error.response.status === 409 ? setShowInfoModal(true) : null),
                  );
                }
              }}
            />
          </div>
          <div className={styles.or}>
            <p>OR</p>
          </div>
          {mode ? (
            <div className={styles.buttonDiv}>
              <button className={styles.setRegisterButton} onClick={() => setMode(false)}>
                Register
              </button>
              <button className={styles.setLoginButtonSelected} onClick={() => setMode(true)}>
                Login
              </button>
            </div>
          ) : (
            <div className={styles.buttonDiv}>
              <button className={styles.setRegisterButtonSelected} onClick={() => setMode(false)}>
                Register
              </button>
              <button className={styles.setLoginButton} onClick={() => setMode(true)}>
                Login
              </button>
            </div>
          )}

          <div className={styles.signUpsignIn}>
            {mode ? (
              <div className={styles.login}>
                <Login />
              </div>
            ) : (
              <div className={styles.signUpsignIn}>
                <SignUp />
              </div>
            )}
          </div>
          <div className={styles.verticalLine}></div>
          <div>
            <p className={styles.footer}>
              By signing up you indicate that you have read and agree to the&nbsp;
              <a className={styles.link} href="/terms-of-service" target="_blank">
                Terms of Service&nbsp;
              </a>
              and&nbsp;
              <a className={styles.link} href="/privacy-policy" target="_blank">
                Privacy Policy
              </a>
              .
            </p>
          </div>
        </div>
      </div>
      <InfoModal
        handleClose={() => setShowInfoModal(false)}
        show={showInfoModal}
        buttonText={'Okay'}
        title={`Wait a minute!
          It seems you already connected with this email address in the past.
          Try another authentication method to log in to your account.`}
      />
    </>
  );
}
