import React, { useEffect, useState } from 'react';
import styles from './TimelineCard.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import { NavLink } from 'react-router-dom';
import { getPeriod, getCardInfos } from '../../../api/timeline.api';
import editIcon from '../../../assets/img/edit.png';
import loupe from '../../../assets/img/loupe.png';
import { generateDateStringFromDateFormat } from '../../../utils/date.util';

export type TimelineCardProps = {
  id: number;
  title?: string;
  period?: string;
  author?: string;
  backgroundImageUrl?: string;
  nbLike?: number;
  reduced?: boolean;
  marginBottom?: string;
  status?: string;
  isCurrentUserTimeline?: boolean;
  lastUpdate?: string;
};

const cardImgStyle = (urlImg: string | undefined) => {
  const styles: React.CSSProperties = {
    backgroundImage: urlImg
      ? `url(${urlImg.replace(
          'https://res.cloudinary.com/dk2ghb1pg/image/upload/',
          'https://res.cloudinary.com/dk2ghb1pg/image/upload/w_440,c_scale/',
        )})`
      : `none`,
    position: 'relative',
    width: '100%',
    height: '100%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
  };
  return styles;
};

export const TimelineCard: React.FunctionComponent<TimelineCardProps> = (props: TimelineCardProps) => {
  const [period, setPeriod] = useState(props.period ? props.period : null);
  const [author, setAuthor] = useState(props.author ? props.author : null);
  const [nbLike, setNbLike] = useState(props.nbLike ? props.nbLike : 0);
  const [title, setTitle] = useState(props.title ? props.title : null);
  const [status, setStatus] = useState(props.status ? props.status : null);
  const [lastUpdate, setLastUpdate] = useState(props.lastUpdate ? props.lastUpdate : null);
  const [backgroundImageUrl, setBackgroundImageUrl] = useState(
    props.backgroundImageUrl ? props.backgroundImageUrl : undefined,
  );
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getPeriod(props.id).then((period) => {
      setPeriod(period);
    });
    if (!props.author || !props.title || !props.nbLike || !props.backgroundImageUrl) {
      getCardInfos(props.id).then((result) => {
        setAuthor(
          result.UserModels[0] ? `${result.UserModels[0].firstname} ${result.UserModels[0].lastname}` : `Unknown User`,
        );
        setBackgroundImageUrl(result.img_background);
        setTitle(result.title);
        setNbLike(result.nbLike ? result.nbLike : 0);
        setStatus(result.status ? result.status : 'in progress');
        setLastUpdate(
          result.timeline_updated_at
            ? generateDateStringFromDateFormat(new Date(result.timeline_updated_at))
            : 'Unknown date',
        );
      });
    }
    setLoading(false);
  }, [loading]);
  return (
    <div
      className={`${styles.eventCard} ${props.reduced ? styles.reduced : ''}`}
      style={{ marginBottom: props.marginBottom }}
    >
      <NavLink
        exact
        to={
          status == 'in progress'
            ? `/timeline/edit/${props.id}/${title?.replaceAll(' ', '-')}`
            : `/timeline/${props.id}/${title?.replaceAll(' ', '-')}`
        }
      >
        <div className={styles.cardImg} style={cardImgStyle(backgroundImageUrl)}>
          {status == 'in progress' && props.isCurrentUserTimeline && (
            <div className={styles.inProgress}>
              <span>In progress</span>
            </div>
          )}
        </div>
        <div className={`${styles.cardContent} hvr-grow`}>
          <div className={styles.cardText}>
            <h3 className={`${styles.eventTitle}`}>{title}</h3>
            <div className={period ? styles.cardType : ''}>
              <div className={styles.timeline}>Timeline</div>
              {!props.reduced && <h3 className={styles.eventDate}> {period}</h3>}
            </div>
            <h3 className={styles.eventAuthor}>By {author}</h3>
            {!props.reduced && (
              <h3 className={styles.eventLike}>
                <FontAwesomeIcon icon={faThumbsUp} className={styles.LikeIcon} />
                {nbLike}
              </h3>
            )}
            {props.isCurrentUserTimeline && (
              <h3 className={styles.edit}>
                <NavLink exact to={'/timeline/edit/' + props.id + '/' + title?.replaceAll(' ', '-')}>
                  <img src={editIcon} className={styles.editIcon} />
                </NavLink>
                {status == 'Published' && <img src={loupe} className={styles.loupe} />}
              </h3>
            )}
          </div>
        </div>
      </NavLink>
    </div>
  );
};
