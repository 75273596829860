import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { Col, Container, Row } from 'react-bootstrap';
import { fetchTimelines, getTimelinesByEvent } from '../../api/timeline.api';
import { Loading } from '../../components/Loading/Loading';
import { CreateTimelineCard } from '../../components/TimelineCard/CreateTimelineCard/CreateTimelineCard';
import { TimelineCard } from '../../components/TimelineCard/TimelineCard/TimelineCard';
import { Timeline_Interface } from '../../interfaces/Timeline.interface';
import styles from './TimelineList.module.css';
import BackgroundVideo from '../../assets/gif/IntroVideo.gif';
import BackgroundImg from '../../assets/img/IntroVideo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FictionCard } from '../../components/FictionCard/FictionCard';
import { generateDateStringFromDateFormat } from '../../utils/date.util';

export const TimelineListSearch: React.FunctionComponent = function () {
  const location = useLocation();
  useEffect(() => {
    if (window.location.hostname === 'estory.io') ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  const [timelines, setTimelines] = useState<Array<Timeline_Interface>>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const { query } = queryString.parse(location.search);

  useEffect(() => {
    setLoading(true);
    fetchTimelines(0, 32, 'like', null, String(query)).then((timelineList) => {
      getTimelinesByEvent(String(query)).then((timelinesByEvent: Timeline_Interface[]) => {
        const filteredTimelines = timelineList.timelines
          .concat(timelinesByEvent)
          .filter((timeline, index, self) => index === self.findIndex((t) => t.id === timeline.id));
        setTimelines(filteredTimelines);
      });
    });
    setLoading(false);
  }, [query]);

  return (
    <div className={styles.AppTimelineList}>
      {query ? (
        loading || timelines.length > 0 ? (
          <>
            <h1 className={styles.title}>Stories result for &quot;{query}&quot;</h1>
            <p className={styles.searchText}>
              You will find several eStories that have already been shared by our eStorian community.
            </p>
            {loading ? (
              <div className={styles.loaderContainer}>
                <Loading />
              </div>
            ) : (
              <Container fluid={true}>
                <Row>
                  {timelines?.map((timeline) => (
                    <Col xs={12} sm={6} md={6} lg={3} key={Number(timeline.id)} className={styles.myCol}>
                      {timeline.type_story === 'fiction' ? (
                        <FictionCard
                          id={Number(timeline.id)}
                          title={timeline.title}
                          backgroundImageUrl={timeline.img_background}
                          author={
                            timeline.UserModels[0]
                              ? `${timeline.UserModels[0].firstname} ${timeline.UserModels[0].lastname}`
                              : `Unknown User`
                          }
                          nbLike={timeline.nbLike ? timeline.nbLike : 0}
                        />
                      ) : (
                        <TimelineCard
                          id={Number(timeline.id)}
                          title={timeline.title}
                          backgroundImageUrl={timeline.img_background}
                          author={
                            timeline.UserModels[0]
                              ? `${timeline.UserModels[0].firstname} ${timeline.UserModels[0].lastname}`
                              : `Unknown User`
                          }
                          lastUpdate={
                            timeline.timeline_updated_at
                              ? generateDateStringFromDateFormat(new Date(timeline.timeline_updated_at))
                              : 'Unknown date'
                          }
                          nbLike={timeline.nbLike ? timeline.nbLike : 0}
                        />
                      )}
                    </Col>
                  ))}
                  <Col xs={12} sm={6} md={6} lg={3} className={styles.myCol}>
                    <CreateTimelineCard
                      borderColor={'#208775'}
                      color={'#208775'}
                      text={'Create Timeline'}
                      url={'/create'}
                      backgroundColor={'transparent'}
                      margin={'1px'}
                      height={'70%'}
                    />
                  </Col>
                  <Col xs={12} sm={6} md={6} lg={3} className={styles.myCol}>
                    <CreateTimelineCard
                      borderColor={'#5823E2'}
                      color={'#5823E2'}
                      text={'Create Fiction'}
                      url={'/createFiction'}
                      backgroundColor={'transparent'}
                      margin={'1px'}
                      height={'100%'}
                    />
                  </Col>
                </Row>
              </Container>
            )}
          </>
        ) : (
          <>
            <h1 className={styles.title}>No stories on &quot;{query}&quot; has yet been created.</h1>
            <div className={styles.blocCard}>
              <div className={styles.createTimelineContainer}>
                <CreateTimelineCard
                  borderColor={'#208775'}
                  color={'#208775'}
                  text={'Create Timeline'}
                  url={'/create'}
                  backgroundColor={'transparent'}
                  margin={'20px'}
                  height={'75%'}
                />
                <CreateTimelineCard
                  borderColor={'#5823E2'}
                  color={'#5823E2'}
                  text={'Create Fiction'}
                  url={'/createFiction'}
                  backgroundColor={'transparent'}
                  margin={'20px'}
                  height={'75%'}
                />
              </div>
            </div>
            <div>
              <h2 className={styles.quote}>{'"History will be kind to me for I intend to write it."'}</h2>
              <h4 className={styles.quoteAuthor}>- Winston Churchill</h4>
            </div>
          </>
        )
      ) : (
        <>
          <h1 className={styles.title}>No search words has been typed.</h1>
          <div className={styles.createTimelineContainer}>
            <div className={styles.blocCard}>
              <div className={styles.createTimelineContainer}>
                <CreateTimelineCard
                  borderColor={'#208775'}
                  color={'#208775'}
                  text={'Create Timeline'}
                  url={'/create'}
                  backgroundColor={'transparent'}
                  margin={'20px'}
                  height={'75%'}
                />
                <CreateTimelineCard
                  borderColor={'#5823E2'}
                  color={'#5823E2'}
                  text={'Create Fiction'}
                  url={'/createFiction'}
                  backgroundColor={'transparent'}
                  margin={'20px'}
                  height={'75%'}
                />
              </div>
            </div>
          </div>
          <div>
            <h2 className={styles.quote}>{'"History will be kind to me for I intend to write it."'}</h2>
            <h4 className={styles.quoteAuthor}>- Winston Churchill</h4>
          </div>
        </>
      )}
    </div>
  );
};
