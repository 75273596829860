import React from 'react';
import { generateDateString } from '../../../utils/date.util';
import styles from './EventCard.module.scss';
import { Position } from '../EventsDisplay/EventsDisplay';
import Cropper from 'react-easy-crop';

export interface EventCardProps {
  id: bigint;
  title?: string;
  description?: string;
  day?: number;
  month?: number;
  year?: bigint;
  display_roman_numbers?: boolean;
  x_position: number;
  y_position: number;
  img?: string;
  img_offset?: string;
  event_created_at?: Date;
  event_updated_at?: Date;
  position?: Position;
  selected: boolean;
}

export const EventCard: React.FunctionComponent<EventCardProps> = (props: EventCardProps) => {
  const generatedDate: string = generateDateString(props.year, props.month, props.day, props.display_roman_numbers);
  const crop = { x: props.x_position, y: props.y_position };
  const zoom = 1;

  const positionClassMap = {
    [Position.RIGHT]: styles.right,
    [Position.LEFT]: styles.left,
  };

  // Apply auto crop
  const splitedImg = props.img && props.img.split('/upload/');
  const img = props.img && splitedImg && splitedImg[0] + '/upload/c_fill,ar_6:4,g_auto/q_auto,f_auto/' + splitedImg[1];

  return (
    <div
      className={`
        ${props.selected ? styles.selected : styles.hidden} ${
        props.position !== undefined ? positionClassMap[props.position] : ''
      }
      `}
    >
      <div className={styles.imgWhiteBackground} />
      {props.img == null ? (
        <div className={styles.pImg3}>
          <div className={styles.dateEstory}>
            <p className={styles.dateEstoryGenerated}>{generatedDate}</p>
          </div>
        </div>
      ) : (
        <div className={`${styles.pImg}`}>
          <div className={styles.dateEstory}>
            <p className={styles.dateEstoryGenerated}>{generatedDate}</p>
          </div>

          {props.x_position !== 0 || props.y_position !== 0 ? (
            <div className={styles.cropper2}>
              <Cropper
                image={props.img}
                crop={crop}
                zoomWithScroll={false}
                showGrid={false}
                restrictPosition={true}
                style={{
                  mediaStyle: {
                    maxHeight: 'none',
                  },
                  cropAreaStyle: {
                    minWidth: '100%',
                    minHeight: '100%',
                  },
                }}
                zoom={zoom}
                aspect={10 / 1}
                onCropChange={() => null}
                onZoomChange={() => null}
              />
            </div>
          ) : (
            <div className={styles.pImg2} style={{ backgroundColor: img && 'transparent' }}>
              <img src={img} alt={props.title} width={500} />
            </div>
          )}
        </div>
      )}
      <div className={styles.boxtimeline}>
        <h2 className={styles.boxtimelineTitle}>{props.title}</h2>
        <p className={styles.boxtimelineDescription}>{props.description}</p>
      </div>
    </div>
  );
};
