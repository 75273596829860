import React from 'react';
import styles from './LikersModal.module.css';
import { Modal } from 'react-bootstrap';
import { User_Interface } from '../../../interfaces/User.interface';

export type LikersModalProps = {
  show?: boolean;
  onHide: () => void;
  users: User_Interface[];
};

export const LikersModal: React.FunctionComponent<LikersModalProps> = function (props: LikersModalProps) {
  return (
    <Modal
      show={props.show}
      className={styles.modal}
      onHide={props.onHide}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className={styles.modalHeader} closeButton>
        <div className={styles.modalTitle} id="contained-modal-title-vcenter">
          Users who liked this timeline
        </div>
      </Modal.Header>
      <Modal.Body className={styles.modalBody}>
        {props.users.map((user: User_Interface) => (
          // eslint-disable-next-line react/jsx-key
          <div className={styles.modalContent}>
            <a
              className={`${styles.profileLink}`}
              href={`/user/${user.id}/${user.firstname.replaceAll(' ', '-')}-${user.lastname.replaceAll(' ', '-')}`}
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={
                  user.picture
                    ? user.picture
                    : 'https://www.pngkit.com/png/full/281-2812821_user-account-management-logo-user-icon-png.png'
                }
                className={styles.authorImage}
              />
              <div className={styles.authorText}>{`${user.firstname} ${user.lastname}`}</div>
            </a>
          </div>
        ))}
      </Modal.Body>
    </Modal>
  );
};
