/* eslint-disable react/jsx-key */
import React, { useState } from 'react';
import styles from './ImageSearchModal.module.css';
import { Modal, Button, InputGroup, FormControl } from 'react-bootstrap';
import { BiSearch } from 'react-icons/bi';
import axios, { AxiosRequestConfig } from 'axios';

export type AddItemProps = {
  show?: boolean;
  onHide: () => void;
  title: string;
  onSetImage: (img: string) => void;
};

interface GoogleItem {
  link: string;
}

export const ImageSearch: React.FunctionComponent<AddItemProps> = function (props: AddItemProps) {
  const [search, setSearch] = useState<string>(props.title);
  const [links, setLinks] = useState<Array<string>>([]);
  const [modifie, setModifie] = useState<boolean>(false);

  const convertTitle = (title: string): string => {
    return title.split(' ').join('+');
  };

  const fetchImages = async (searchTerms: string) => {
    const url = `https://www.googleapis.com/customsearch/v1?q=${searchTerms}&cx=017775766472701992242:2kfan5wrwqs&searchType=image&key=${process.env.REACT_APP_GOOGLE_API_KEY}`;
    let res;
    try {
      const config: AxiosRequestConfig = {
        method: 'get',
        headers: { 'Content-Type': 'application/js' },
      };
      res = (await axios.get(url, config)).data;
    } catch (e) {
      console.log(e);
    }
    if (res && res.items) {
      return res.items.map((item: GoogleItem) => item.link);
    }

    return [];
  };

  return (
    <Modal {...props} className={styles.modal} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header className={styles.modalHeader} closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Find a picture</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {modifie ? (
          <InputGroup className="mb-3">
            <FormControl
              placeholder="Find an image.."
              value={search}
              aria-label={props.title}
              aria-describedby="basic-addon2"
              onChange={(ev: React.ChangeEvent<HTMLInputElement>) => setSearch(ev.target.value)}
            />
            <InputGroup.Append>
              <Button
                className={styles.searchButton}
                variant="success"
                onClick={() => {
                  fetchImages(convertTitle(search)).then((data) => setLinks(data));
                }}
              >
                <BiSearch /> Search
              </Button>
            </InputGroup.Append>
          </InputGroup>
        ) : (
          <InputGroup className="mb-3">
            <FormControl
              placeholder="Find an image.."
              value={props.title}
              aria-label={props.title}
              aria-describedby="basic-addon2"
              onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                setSearch(ev.target.value);
                setModifie(true);
              }}
            />
            <InputGroup.Append>
              <Button
                className={styles.searchButton}
                variant="success"
                onClick={() => fetchImages(convertTitle(props.title)).then((data) => setLinks(data))}
              >
                <BiSearch /> Search
              </Button>
            </InputGroup.Append>
          </InputGroup>
        )}
        <div className={styles.viewImages}>
          {links.map((element) => (
            <div className={styles.searched}>
              <input type="image" className={styles.imageSearched} src={element} />
              <button
                className={styles.buttonSearched}
                onClick={() => {
                  props.onSetImage(element);
                  props.onHide();
                  setLinks([]);
                }}
              >
                Import
              </button>
            </div>
          ))}
        </div>
      </Modal.Body>
    </Modal>
  );
};
