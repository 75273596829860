import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import { useLocation } from 'react-router-dom';
import duplicate from '../../assets/img/duplicate-photo.svg';
import styles from './AlreadyRegistered.module.css';
import { Button } from 'react-bootstrap';

export function AlreadyRegistered(): JSX.Element {
  const location = useLocation();
  useEffect(() => {
    if (window.location.hostname === 'estory.io') ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  return (
    <div className={styles.container}>
      <div className="heading animated fadeInUp">
        <div>
          <div>
            <h2>Your email address is already registered with eStory!</h2>
          </div>
        </div>

        <div className="text-center our-services">
          <div className="row">
            <div className="col-sm-12 animated fadeInDown">
              <div>
                <img className={styles.image} src={duplicate} width="120" />
              </div>
              <div className={styles.serviceInfo}>
                <h3>If you have forgotten your password, please click the button below.</h3>
                <div className={styles.buttons}>
                  <Button variant="success" data-scroll className="btn btn-start" href="/login">
                    Back to Login
                  </Button>
                  <Button variant="success" data-scroll className="btn btn-start2" href="/forgot-password">
                    Forgot password
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
