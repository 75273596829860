import React, { useState } from 'react';
import { generateDateString } from '../../../utils/date.util';
import styles from './EventDisplay.module.scss';
import { Position } from './EventsDisplayPreview';
import { IoTrash } from 'react-icons/io5';
import Cropper from 'react-easy-crop';
import { putEvent } from '../../../api/timeline.api';
import allDirections from '../../../assets/img/all-directions.png';

export interface EventCardPreviewProps {
  id: bigint;
  title?: string;
  description?: string;
  day?: number;
  month?: number;
  year?: bigint;
  display_roman_numbers?: boolean;
  x_position: number;
  y_position: number;
  img?: string;
  img_offset?: string;
  event_created_at?: Date;
  event_updated_at?: Date;
  position?: Position;
  selected: boolean;
  index: number;
  removeEvent: (event: bigint) => void;
  setIndex: (index: number) => void;
}
export const EventCardPreview: React.FunctionComponent<EventCardPreviewProps> = (props: EventCardPreviewProps) => {
  const generatedDate: string = generateDateString(props.year, props.month, props.day, props.display_roman_numbers);
  const [crop, setCrop] = useState({ x: props.x_position, y: props.y_position });
  const [zoom, setZoom] = useState(1);
  const [positionOn, setPositionOn] = useState<boolean>(false);

  function setPosition(bool: boolean) {
    putEvent(
      Number(props.id),
      props.title!,
      props.description!,
      props.day!,
      props.month!,
      Number(props.year),
      props.display_roman_numbers!,
      crop.x,
      crop.y,
      props.img!,
      props.img_offset!,
    );
    setPositionOn(bool);
  }
  const positionClassMap = {
    [Position.RIGHT]: styles.right,
    [Position.LEFT]: styles.left,
  };
  return (
    <div
      className={`
        ${props.selected ? styles.selected : styles.hidden} ${props.position && positionClassMap[props.position]}
      `}
    >
      <div className={styles.options}>
        {positionOn ? (
          <div>
            <div className={styles.modify3} onClick={() => setPosition(false)}>
              Save position
            </div>
            <div className={styles.dragImage}>Drag image to reposition</div>
          </div>
        ) : (
          <div className={styles.options2}>
            <div className={styles.delete} onClick={() => props.removeEvent(props.id)}>
              <IoTrash className={styles.trash} />
            </div>
            {props.img !== undefined ? (
              <div className={styles.modify2} onClick={() => setPositionOn(true)}>
                <img src={allDirections} width="15px" />
              </div>
            ) : null}
            <div className={styles.modify} onClick={() => props.setIndex(props.index)}>
              Modify
            </div>
          </div>
        )}
      </div>
      <div className={styles.pImg}>
        {positionOn ? (
          <div className={styles.cropper}>
            <Cropper
              image={props.img}
              crop={crop}
              zoomWithScroll={false}
              restrictPosition={true}
              style={{
                mediaStyle: {
                  maxHeight: 'none',
                },
                cropAreaStyle: {
                  minWidth: '100%',
                  minHeight: '100%',
                },
              }}
              zoom={zoom}
              aspect={10 / 1}
              onCropChange={setCrop}
              onZoomChange={setZoom}
            />
          </div>
        ) : (
          <div className={styles.cropper2}>
            <Cropper
              image={props.img}
              crop={crop}
              zoomWithScroll={false}
              showGrid={false}
              restrictPosition={true}
              style={{
                mediaStyle: {
                  maxHeight: 'none',
                },
                cropAreaStyle: {
                  minWidth: '100%',
                  minHeight: '100%',
                },
              }}
              zoom={zoom}
              aspect={10 / 1}
              onCropChange={() => null}
              onZoomChange={() => null}
            />
          </div>
        )}
        <div className={styles.dateEstory}>
          <h4 className={styles.dateEstoryGenerated}>{generatedDate}</h4>
        </div>
      </div>
      <div className={styles.boxtimeline}>
        <h4 className={styles.boxtimelineTitle}>{props.title}</h4>
        <p className={styles.boxtimelineDescription}>{props.description}</p>
      </div>
    </div>
  );
};
