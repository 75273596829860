import { useEffect, useState } from 'react';
import { fetchFictions } from '../api/fiction.api';
import { Timeline_Interface } from '../interfaces/Timeline.interface';

export default function useFictionSearch(
  last: number,
  nbrToFetch: number,
  category?: string,
  order?: string,
  search?: string,
) {
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [fictions, setFictions] = useState<Array<Timeline_Interface>>([]);
  const [hasMore, setHasMore] = useState<boolean>(false);

  useEffect(() => {
    setFictions([]);
  }, [category]);

  useEffect(() => {
    setLoading(true);
    setError(false);
    fetchFictions(last, nbrToFetch, order ? order : null, category ? category : null, search ? search : null)
      .then((fictionList) => {
        setFictions((prevTimelines) => {
          return [...prevTimelines, ...fictionList.fictions];
        });
        setHasMore(fictionList.fictions.length > 0);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setError(true);
      });
  }, [category, last]);

  return { loading, error, fictions, hasMore };
}
