import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { NavBar } from './layout/NavigationBar/NavBar';
import usePageTracking from './usePageTracking';
import './App.css';
import { About } from './features/About/about';
import { TimelineDisplayLayout } from './features/TimelineDisplay/TimelineDisplay';
import { FictionDisplayLayout } from './features/FictionDisplay/FictionDisplay';
import { Footer } from './layout/Footer/Footer';
import { TimelineListContainer } from './features/TimelineList/TimelineListContainer';
import { TimelineListExplore } from './features/TimelineList/TimelineListExplore';
import { TimelineListSearch } from './features/TimelineList/TimelineListSearch';
import { Animated } from 'react-animated-css';
import { LoginPage } from './features/Login/Login';
import { TermsOfService } from './features/TermsOfService/TermsOfService';
import { PrivacyPolicy } from './features/PrivacyPolicy/PrivacyPolicy';
import { ForgotPassword } from './features/ForgotPassword/ForgotPassword';
import { AskNewPassword } from './features/AskNewPassword/AskNewPassword';
import { AccountValidation } from './features/AccountValidation/AccountValidation';
import { AlreadyRegistered } from './features/AlreadyRegistered/AlreadyRegistered';
import { EmailNotRegistered } from './features/EmailNotRegistered/EmailNotRegistered';
import { EmailValidation } from './features/EmailValidation/EmailValidation';
import { useSelector } from 'react-redux';
import { RootState } from './store/store';
import { UserProfile } from './features/Profile/UserProfile';
import { ChooseNewPassword } from './features/ChooseNewPassword/ChooseNewPassword';
import { TimelineCreation } from './features/TimelineModification/TimelineCreation';
import { TimelineModification } from './features/TimelineModification/TimelineModification';
import { NotFound } from './features/NotFound/NotFound';
import { ChooseRole } from './features/ChooseRole/ChooseRole';
import { ChooseSchool } from './features/ChooseSchool/ChooseSchool';
import { ChooseDescription } from './features/ChooseDescription/ChooseDescription';
import { ChooseRoleOther } from './features/ChooseRole/ChooseRoleOther';
import PayementPage from './features/Payement/PayementPage';
import { Helmet } from 'react-helmet';
import { FictionCreation } from './features/fictionCreation/FictionCreation';
import { FictionModification } from './features/FictionModification/FictionModification';

function App(): JSX.Element {
  usePageTracking();
  const [style, setStyle] = useState({
    paddingTop: '60px',
  });

  const loggedUser = useSelector((state: RootState) => state.loggedUser);

  // Can be extracted in hook
  // https://reactjs.org/docs/hooks-custom.html
  useEffect(() => {
    if (location.pathname.split('/')[1] === 'api') {
      setStyle({
        paddingTop: '0px',
      });
    } else {
      setStyle({
        paddingTop: '60px',
      });
    }
  }, []);

  return (
    <div className="App" style={style}>
      <Router>
        <Route
          exact
          path={[
            '/',
            '/about',
            '/explore',
            '/create',
            '/createFiction',
            '/search',
            '/pricing',
            '/timeline/:id/:title',
            '/fiction/:id/:title',
            '/terms-of-service',
            '/privacy-policy',
            '/password-email-sent',
            '/account-validation',
            '/already-registered',
            '/email-not-registered',
            '/email-validation',
            '/user/:id/:name',
            '/timeline/edit/:id/:title',
            '/fiction/editFiction/:id/:title',
            '/description',
            '/role',
            '/role-other',
            '/school',
          ]}
          component={NavBar}
        />
        <Route exact path={'*'}>
          <div className="App-content">
            <Switch>
              <Route exact path="/">
                <Helmet>
                  <link rel="canonical" href="https://estory.io/" />
                </Helmet>
                <div className="Intro-Video">
                  <video autoPlay muted loop id="myVid">
                    <source src={`video/Intro_Video/IntroVideo.mp4`} type="video/mp4" />
                    Your browser does not support HTML5 video.
                  </video>
                </div>
                <Animated animationIn="slideInLeft" animationOut="fadeOut" animationInDuration={1000} isVisible={true}>
                  <div className="Intro-Text-Container">
                    {!!loggedUser ? (
                      <h1 className="Intro-Text-Title">{'Hello ' + loggedUser.firstname}</h1>
                    ) : (
                      <>
                        <h1 className="Intro-Text-Title">Welcome to eStory</h1>
                        <h2 className="Intro-Text-Desc">Visualize the key events of stories on a timeline</h2>
                      </>
                    )}
                    <a
                      href="https://www.producthunt.com/posts/estory-io?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-estory-io"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=332487&theme=light"
                        alt="eStory.io - Create or discover +1,000 historical timelines 🕰 | Product Hunt"
                        style={{ marginTop: '25px', width: '250px', height: '54px' }}
                        width="250"
                        height="54"
                      />
                    </a>
                  </div>
                </Animated>
                <TimelineListContainer />
              </Route>
              <Route exact path="/explore" component={TimelineListExplore} />
              <Route exact path="/search" component={TimelineListSearch} />
              <Route exact path="/pricing" component={PayementPage} />
              <Route exact path="/about" component={About} />
              <Route exact path="/terms-of-service" component={TermsOfService} />
              <Route exact path="/privacy-policy" component={PrivacyPolicy} />
              <Route exact path="/forgot-password" component={ForgotPassword} />
              <Route exact path="/password-email-sent" component={AskNewPassword} />
              <Route exact path="/account-validation" component={AccountValidation} />
              <Route exact path="/choose-new-password" component={ChooseNewPassword} />
              <Route exact path="/already-registered" component={AlreadyRegistered} />
              <Route exact path="/email-not-registered" component={EmailNotRegistered} />
              <Route exact path="/email-validation" component={EmailValidation} />
              <Route exact path="/user/:id/:name" component={UserProfile} />
              <Route
                exact
                path="/role"
                render={() =>
                  !!loggedUser ? (
                    <ChooseRole />
                  ) : (
                    <Redirect
                      to={{
                        pathname: '/login',
                      }}
                    />
                  )
                }
              />
              <Route exact path="/school" component={ChooseSchool} />
              <Route exact path="/description" component={ChooseDescription} />
              <Route exact path="/role-other" component={ChooseRoleOther} />
              <Route
                exact
                path="/login"
                render={() =>
                  !!loggedUser ? (
                    loggedUser.role === null || loggedUser.role === '' ? (
                      <Redirect
                        to={{
                          pathname: '/role',
                        }}
                      />
                    ) : (
                      <Redirect
                        to={{
                          pathname: '/',
                        }}
                      />
                    )
                  ) : (
                    <LoginPage />
                  )
                }
              />
              <Route
                exact
                path="/paySubscription"
                render={() =>
                  !!loggedUser ? (
                    <Redirect
                      to={{
                        pathname: '/explore',
                      }}
                    />
                  ) : (
                    <LoginPage />
                  )
                }
              />
              <Route
                exact
                path="/create"
                render={() =>
                  !!loggedUser ? (
                    <TimelineCreation />
                  ) : (
                    <Redirect
                      to={{
                        pathname: '/login',
                      }}
                    />
                  )
                }
              />
              <Route
                exact
                path="/createFiction"
                render={() =>
                  !!loggedUser ? (
                    <FictionCreation />
                  ) : (
                    <Redirect
                      to={{
                        pathname: '/login',
                      }}
                    />
                  )
                }
              />
              <Route exact path="/timeline/:id/:title" component={TimelineDisplayLayout} />
              <Route exact path="/api/timeline/:id" component={TimelineDisplayLayout} />
              <Route exact path="/fiction/:id/:title" component={FictionDisplayLayout} />
              <Route exact path="/api/fiction/:id" component={FictionDisplayLayout} />
              <Route exact path="/timeline/edit/:id/:title" component={TimelineModification} />
              <Route exact path="/fiction/editFiction/:id/:title" component={FictionModification} />
              <Route exact path="/not-found" component={NotFound} />
              <Route
                exact
                path="/timeline/editUFTimeline/:id/:title"
                render={(props) => (
                  // eslint-disable-next-line react/prop-types
                  <Redirect to={`/timeline/edit/${props.match.params.id}/${props.match.params.title}`} />
                )}
              />
              <Route
                exact
                path="/timeline/view/:code/:id/:title"
                // eslint-disable-next-line react/prop-types
                render={(props) => <Redirect to={`/timeline/${props.match.params.id}/${props.match.params.title}`} />}
              />
              <Route
                exact
                path="/timeline/:id"
                // eslint-disable-next-line react/prop-types
                render={(props) => <Redirect to={`/timeline/${props.match.params.id}/`} />}
              />
              <Route
                exact
                path="/fiction/view/:code/:id/:title"
                // eslint-disable-next-line react/prop-types
                render={(props) => <Redirect to={`/fiction/${props.match.params.id}/${props.match.params.title}`} />}
              />
              <Route
                exact
                path="/fiction/:id"
                // eslint-disable-next-line react/prop-types
                render={(props) => <Redirect to={`/fiction/${props.match.params.id}/`} />}
              />
              <Route
                exact
                path="/user/view/:id/:name"
                // eslint-disable-next-line react/prop-types
                render={(props) => <Redirect to={`/user/${props.match.params.id}/${props.match.params.name}`} />}
              />
              <Route
                exact
                path="/search/:search"
                // eslint-disable-next-line react/prop-types
                render={(props) => <Redirect to={`/search?query=${props.match.params.search}`} />}
              />
              <Redirect exact from="/home" to="/" />
              <Redirect from="*" to="/not-found" />
            </Switch>
          </div>
          <Route exact path={['/', '/about', '/explore', '/search', '/demo/related-source']}>
            <div className="App-footer">
              <Footer />
            </div>
          </Route>
        </Route>
      </Router>
    </div>
  );
}

export default App;
