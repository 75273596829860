import React from 'react';
import {
  FacebookShareButton,
  FacebookIcon,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
  EmailIcon,
  EmailShareButton,
} from 'react-share';
import styles from './TimelineModalShare.module.css';
import { Modal } from 'react-bootstrap';

export type TimelineModalShareProps = {
  id: string;
  show?: boolean;
  onHide: () => void;
  copied?: boolean;
  title?: string;
  setCopied: () => void;
};

export const TimelineModalShare: React.FC<TimelineModalShareProps> = (props: TimelineModalShareProps) => {
  const urlToShare = `https://${window.location.hostname}/timeline/${props.id}/${props.title?.replaceAll(' ', '-')}`;
  function copyToClipboard() {
    navigator.clipboard.writeText(urlToShare);
    props.setCopied();
  }
  return (
    <Modal {...props} className={styles.modal} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header className={styles.upBar} closeButton>
        <Modal.Title>Share via social medias</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <FacebookShareButton url={urlToShare} className={styles.modalLogo}>
            <FacebookIcon size={55} round={true} />
          </FacebookShareButton>
          <LinkedinShareButton url={urlToShare} className={styles.modalLogo}>
            <LinkedinIcon size={55} round={true} />
          </LinkedinShareButton>
          <EmailShareButton url={urlToShare} className={styles.modalLogo}>
            <EmailIcon size={55} round={true} />
          </EmailShareButton>
          <TwitterShareButton url={urlToShare} className={styles.modalLogo}>
            <TwitterIcon size={55} round={true} />
          </TwitterShareButton>
        </div>
        <h4 style={{ marginTop: '20px' }}>Share this link:</h4>
        <div className="linkContainer">
          <input type="text" name="Timeline_link" className={styles.modalInput2} value={urlToShare} />

          {props.copied ? (
            <button className={styles.modalButtonCopied}>Copied !</button>
          ) : (
            <button
              className={styles.modalButton}
              onClick={() => {
                copyToClipboard();
              }}
            >
              Copy url
            </button>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};
