import React, { useState, useEffect } from 'react';
import { Event_Interface } from '../../../interfaces/Event.interface';
import style from './RequestTimeline.module.css';
import { getEventGeneratedWithOpenAI } from '../../../api/timeline.api';
import { time } from 'console';
import { self } from '../../../api/user.api';
import { setLoggedUserActionCreator } from '../../../reducers/LoggedUser';
import { useDispatch } from 'react-redux';
import wand from '../../../assets/img/wand_white.png';
import { TokensModal } from '../../../components/Modal/TokensModal/TokensModal';
import { RootState } from '../../../store/store';
import { useSelector } from 'react-redux';
import { getUser } from '../../../api/user.api';

export type RequestEventProps = {
  timelineId: number;
  title: string;
  imgUrl: string;
  events: Event_Interface[];
  selectedKey: number;
  setSelectedKey: (key: number) => void;
  setEvents: (events: Event_Interface[]) => void;
  modifyEvent: (event: Event_Interface, indice: number) => void;
  removeEvent: (event: bigint) => void;
  setLoading: (loading: boolean) => void;
  setNotificationToUser: (message: string) => void;
  setNewNotification: (notification: boolean) => void;
};

export const RequestEvent: React.FunctionComponent<RequestEventProps> = (props: RequestEventProps) => {
  const [requestText, setRequestText] = useState('');
  const dispatch = useDispatch();
  const loggedUser = useSelector((state: RootState) => state.loggedUser);
  const [modalShow, setModalShow] = useState(false);

  const handleGenerateRequest = async () => {
    await getUser(Number(loggedUser?.id)).then(async (result) => {
      if (Number(result.nb_tokens) > 0) {
        props.setLoading(true);
        getEventGeneratedWithOpenAI(props.timelineId, requestText).then((response) => {
          props.setEvents([...response.events]);
          self().then((loggedUser) => dispatch(setLoggedUserActionCreator(loggedUser)));
          props.setLoading(false);
          props.setNotificationToUser(
            response.eventsAdded.length +
              ' Events added, ' +
              response.eventsDeleted.length +
              ' Events deleted, ' +
              response.eventsUpdated.length +
              ' Events updated.',
          );
          props.setNewNotification(true);
        });
        setRequestText('');
      } else {
        setModalShow(true);
      }
    });
  };

  return (
    <>
      {modalShow ? (
        <TokensModal
          show={modalShow}
          handleClose={() => setModalShow(false)}
          handleConfirm={() => setModalShow(false)}
        />
      ) : (
        <div className={style.input_container}>
          <label className={style.input_label}>What would you like to change ? ✨ </label>
          <div className={style.input_generate}>
            <input
              placeholder="ex : add 2 more events about this specific time period, and add 3 more books as reference."
              type="text"
              value={requestText}
              onChange={(e) => setRequestText(e.target.value)}
              className={style.input}
              name="text"
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleGenerateRequest();
                }
              }}
            />
            <button className={style.button_generate} onClick={handleGenerateRequest}>
              ✨ Generate
            </button>
          </div>
        </div>
      )}
    </>
  );
};
