import React, { useState } from 'react';
import { Event_Interface } from '../../../interfaces/Event.interface';
import { CardEventCreation } from '../EventCard/CardEventCreation';
import { TimelinePreview } from '../Preview/TimelinePreview';
import { RequestEvent } from '../RequestAi/RequestEvent';
import styles from './EventPreviewStep.module.css';
import { Event } from '../../../models/Event.model';

export type Step3Props = {
  timelineId: number;
  title: string;
  imgUrl: string;
  events: Event_Interface[];
  selectedKey: number;
  setSelectedKey: (key: number) => void;
  addEvent: (
    id_timeline: number,
    title: string,
    description: string,
    day: number,
    month: number,
    year: number,
    display_roman_numbers: boolean,
    x_position: number,
    y_position: number,
    img: string,
  ) => void;
  modifyEvent: (event: Event_Interface, indice: number) => void;
  removeEvent: (event: bigint) => void;
};

export const EventPreviewStep: React.FunctionComponent<Step3Props> = (props: Step3Props) => {
  const [index, setIndex] = useState<number>(-1);
  const cardEventCreationProps = {
    timelineId: props.timelineId,
    title: props.title,
    events: props.events,
    addEvent: props.addEvent,
    modifyEvent: (
      id: number,
      title: string,
      description: string,
      day: number,
      month: number,
      year: number,
      display_roman_numbers: boolean,
      x_position: number,
      y_position: number,
      img: string,
    ) =>
      props.modifyEvent(
        new Event(
          BigInt(id),
          title,
          description,
          day,
          month,
          BigInt(year),
          display_roman_numbers,
          x_position,
          y_position,
          img,
          '',
          new Date(),
          new Date(),
        ),
        index,
      ),
    setIndex: setIndex,
  };

  return (
    <div className={styles.blockForm}>
      <div className={styles.eventPreview}>
        <div className={styles.eventCard}>
          <h3 className={styles.subtitles}>Event Details</h3>
          {index !== -1 ? (
            <CardEventCreation {...cardEventCreationProps} event={props.events[index]} />
          ) : (
            <CardEventCreation {...cardEventCreationProps} setIndex={(index: number) => setIndex(index)} />
          )}
        </div>
        <div className={styles.preview}>
          <h3 className={styles.subtitles}>Preview</h3>
          <TimelinePreview
            selectedKey={props.selectedKey}
            setSelectedKey={props.setSelectedKey}
            title={props.title}
            imgUrl={props.imgUrl}
            events={props.events}
            index={index}
            setIndex={(index: number) => setIndex(index)}
            removeEvent={props.removeEvent}
          />
        </div>
      </div>
    </div>
  );
};
