import axios, { AxiosRequestConfig } from 'axios';
import { Event_Interface } from '../interfaces/Event.interface';
import { Timeline_Interface } from '../interfaces/Timeline.interface';
import { Timeline } from '../models/Timeline.model';

export async function fetchFictions(
  index: number,
  num: number,
  order: string | null,
  category: string | null,
  search: string | string[] | null,
): Promise<{ fictions: Timeline_Interface[]; count: number; max: number }> {
  return new Promise((resolve, reject) => {
    try {
      const url = `${process.env.REACT_APP_SERV_HOST}/timelines`;
      const config: AxiosRequestConfig = {
        method: 'get',
        headers: { 'Content-Type': 'application/json' },
        params: {
          index: index,
          number: num,
          order: order ? order : 'like',
          category: category ? category : null,
          search: search ? search : null,
        },
      };
      axios.get(url, config).then((fictions) => {
        const fictionList: Timeline_Interface[] = new Array<Timeline>();
        fictions.data.data.forEach((fiction: Timeline_Interface) => {
          if (fiction.type_story === 'fiction') {
            fictionList.push(fiction);
          }
        });
        resolve({ fictions: fictionList, count: fictions.data.length, max: fictions.data.max });
      });
    } catch (err) {
      reject(err);
    }
  });
}

export async function postFiction(
  title: string,
  category: string,
  img_background: string,
  visible: boolean,
  step: number,
  status: string,
  type_story: string,
  tone: string,
): Promise<Timeline_Interface> {
  return new Promise((resolve, reject) => {
    try {
      const config: AxiosRequestConfig = {
        method: 'post',
        url: `${process.env.REACT_APP_SERV_HOST}/timelines`,
        withCredentials: true,
        data: { title, category, img_background, visible, step, status, type_story, tone },
      };
      axios(config).then((result) => {
        const timeline: Timeline_Interface = result.data.data[0];
        resolve(timeline);
      });
    } catch (err) {
      reject(err);
    }
  });
}

export async function deleteFiction(id: number): Promise<Event_Interface> {
  return new Promise((resolve, reject) => {
    try {
      const url = `${process.env.REACT_APP_SERV_HOST}/timelines/${id}`;
      const config: AxiosRequestConfig = {
        method: 'delete',
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true,
      };
      axios.delete(url, config).then((result) => {
        resolve(result.data.data[0]);
      });
    } catch (err) {
      reject(err);
    }
  });
}

export async function putFiction(
  id_timeline: number,
  title: string,
  category: string,
  img_background: string,
  visible: boolean,
  step: number,
  status: string,
  type_story: string,
  tone: string,
): Promise<Timeline_Interface> {
  return new Promise((resolve, reject) => {
    try {
      const config: AxiosRequestConfig = {
        method: 'put',
        url: `${process.env.REACT_APP_SERV_HOST}/timelines/${id_timeline}`,
        withCredentials: true,
        data: { title, category, img_background, visible, step, status, type_story, tone },
      };
      axios(config).then((result) => {
        const timeline: Timeline_Interface = result.data.data;
        resolve(timeline);
      });
    } catch (err) {
      reject(err);
    }
  });
}

export async function generateResultOpenAI(prompt: string, length: number): Promise<string> {
  return new Promise((resolve, reject) => {
    try {
      const config: AxiosRequestConfig = {
        method: 'post',
        url: `${process.env.REACT_APP_SERV_HOST}/timelines/fiction`,
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true,
        data: { prompt, length },
      };
      axios(config).then((result) => {
        const response = result.data.data;
        resolve(response);
      });
    } catch (error) {
      reject(error);
    }
  });
}

export async function generateImageOpenAI(prompt: string): Promise<string[]> {
  return new Promise((resolve, reject) => {
    try {
      const config: AxiosRequestConfig = {
        method: 'post',
        url: `${process.env.REACT_APP_SERV_HOST}/timelines/fictionImages`,
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true,
        data: { prompt },
      };
      axios(config).then((result) => {
        const response = result.data.data;
        resolve(response);
      });
    } catch (error) {
      reject(error);
    }
  });
}
