import React from 'react';

interface NavigationLineProps {
  cursorPosition: number;
  children?: any;
}

export const NavigationLine: React.FunctionComponent<NavigationLineProps> = (props: NavigationLineProps) => {
  return (
    <div style={{ height: '2px', background: '#dfdfdf' }}>
      <div
        style={{
          position: 'absolute',
          width: props.cursorPosition,
          height: '2px',
          backgroundColor: '#208775',
        }}
      >
        {props.children}
      </div>
    </div>
  );
};
