import React, { useState } from 'react';
import styles from './FictionStep.module.css';
import Form from 'react-bootstrap/Form';
import { TypeSelect } from '../../../components/Select/TypeSelect/TypeSelect';
import { ToneSelect } from '../../../components/Select/ToneSelect/ToneSelect';
import wand from '../../../assets/img/wand_white.png';
import { ImageChoiceFiction } from '../../../components/ImagePicker/ImagePickerFiction';
import { RootState } from '../../../store/store';
import { useDispatch, useSelector } from 'react-redux';
import { getUser, useTokenAPI } from '../../../api/user.api';
import { TokensModal } from '../../../components/Modal/TokensModal/TokensModal';
import { generateResultOpenAI } from '../../../api/fiction.api';
import { setLoggedUserActionCreator } from '../../../reducers/LoggedUser';
import { self } from '../../../api/user.api';
import { Spinner } from 'react-bootstrap';

const fictionType = (type: string) => {
  let typeFiction = type;
  if (type === '🤠 Adventure') {
    typeFiction = 'adventure';
  }
  if (type === '💞 Romance') {
    typeFiction = 'romance';
  }
  if (type === '🤣 Comedy') {
    typeFiction = 'comedy';
  }
  if (type === '🎭 Drama') {
    typeFiction = 'drama';
  }
  if (type === '🧐 Mystery') {
    typeFiction = 'mystery';
  }
  if (type === '🧜‍♀️ Fantasy') {
    typeFiction = 'fantasy';
  }
  if (type === '🤴 Historical') {
    typeFiction = 'historical';
  }
  if (type === '😱 Horror') {
    typeFiction = 'horror';
  }
  if (type === '🤖 Science Fiction') {
    typeFiction = 'fiction';
  }
  return typeFiction;
};
const fictionTone = (tone: string) => {
  let toneFiction = tone;
  if (tone === '😃 Happy') {
    toneFiction = 'happy';
  }
  if (tone === '😢 Sad') {
    toneFiction = 'sad';
  }
  if (tone === '😱 Scary') {
    toneFiction = 'scary';
  }
  if (tone === '😮 Surprising') {
    toneFiction = 'surprising';
  }
  if (tone === '😐 Neutral') {
    toneFiction = 'neutral';
  }
  return toneFiction;
};

export type Step1Props = {
  title: string;
  onSetTitle: (title: string) => void;
  onSetImage: (image: string) => void;
  imgUrl: string;
  visible: boolean;
  onSetVisible: (visible: boolean) => void;
  types: string;
  onSetType: (type: string) => void;
  tones: string;
  onSetTone: (tone: string) => void;
};

export const FictionStep: React.FunctionComponent<Step1Props> = (props: Step1Props) => {
  const [modalShow, setModalShow] = useState<boolean>(false);

  const loggedUser = useSelector((state: RootState) => state.loggedUser);

  const [loading, setLoading] = useState<boolean>(false);

  const dispatch = useDispatch();

  /** Prompt for OpenAi
   * Example :  “Title of a fantasy story with happy tone”
   */
  const promptOpenAi = `Write a short title for an ${fictionType(props.types)} and ${fictionTone(
    props.tones,
  )} story without using quotes in 60 character max`;

  /** Generate the title of the fiction with OpenAI
   * and use one token
   * */
  async function generateTitleAndUseToken() {
    props.onSetTitle('');
    await getUser(Number(loggedUser?.id)).then(async (result) => {
      if (Number(result.nb_tokens) > 0) {
        setLoading(true);
        await generateResultOpenAI(promptOpenAi, 15)
          .then((result) => {
            if (result !== null) {
              let title = result;

              if (title[0] === '"') {
                title = title.slice(1, title.length);
              }

              if (title[title.length - 1] === '"') {
                title = title.slice(0, title.length - 1);
              }

              props.onSetTitle(title);
            }
          })
          .finally(() => {
            useTokenAPI(Number(loggedUser?.id.toString())).then(() => {
              self().then((loggedUser) => dispatch(setLoggedUserActionCreator(loggedUser)));
            });
            setLoading(false);
          });
      } else {
        setModalShow(true);
      }
    });
  }

  /**
   * Go to the help link in blank
   * @returns link in blank
   */
  const openHelpLink = () => {
    window.open(
      'https://www.notion.so/estoryio/How-to-create-a-fiction-with-eStory-c241fa73c2e648c7a7bfac095d1b6a36',
      '_blank',
    );
  };

  return (
    <>
      <div className={styles.blockForm}>
        <div className={styles.help}>
          <p>
            Need help? Discover our guide
            <a className={styles.link} onClick={() => openHelpLink()}>
              How to create an amazing fictions
            </a>
          </p>
        </div>
        <div className={styles.category}>
          <label>Type of story</label>
          <br />
          <div className={styles.categorySelector}>
            <TypeSelect types={props.types} onSetType={(type: string) => props.onSetType(type)} />
          </div>
        </div>
        <div className={styles.category}>
          <label>Select tone of the story</label>
          <br />
          <div className={styles.categorySelector}>
            <ToneSelect tones={props.tones} onSetTone={(tone: string) => props.onSetTone(tone)} />
          </div>
        </div>
        <div className={styles.title}>
          <label className={styles.obligatoire}>Story title</label>
          <br />
          <div className={styles.table}>
            <div className={props.title ? styles.colonneIn : styles.colonne}>
              <input
                className={props.title ? styles.titleInputIn : styles.titleInput}
                type="text"
                placeholder="Type title here"
                pattern="[A-Za-z0-9'*/+-:.!,;]+"
                value={props.title}
                onChange={(ev: React.ChangeEvent<HTMLInputElement>) => props.onSetTitle(ev.target.value)}
                maxLength={60}
                required
              />
            </div>
            <TokensModal
              show={modalShow}
              handleClose={() => setModalShow(false)}
              handleConfirm={() => setModalShow(false)}
            />
            {loading ? (
              <div className={styles.bottomContainer}>
                <Spinner animation="grow" className={styles.loader} />
              </div>
            ) : (
              <div className={styles.colonne2}>
                <button
                  disabled={props.types === ''}
                  className={styles.buttonGenerate}
                  onClick={() => generateTitleAndUseToken()}
                >
                  <img className={styles.wandlogo} src={wand} />
                  <span>Generate</span>
                </button>
              </div>
            )}
          </div>
        </div>

        <div className={styles.privacy}>
          <label className={styles.privacyTitle}>Privacy</label>
          <br />
          <div className={styles.switchDiv}>
            Fiction is visible to anyone
            <Form className={styles.switchButton}>
              <Form.Check
                checked={props.visible}
                type="switch"
                id="custom-switch"
                onChange={() => props.onSetVisible(!props.visible)}
              />
            </Form>
          </div>
        </div>
        <div className={styles.image}>
          <ImageChoiceFiction
            imgUrl={props.imgUrl}
            setImage={(image: string) => props.onSetImage(image)}
            title={props.title}
          />
        </div>
      </div>
    </>
  );
};
