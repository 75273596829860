import React, { useState } from 'react';
import styles from './MediaStep.module.css';
import { Modal, Button, InputGroup, FormControl } from 'react-bootstrap';
import { getUrlInfo } from '../../../api/openGraphScraper.api';
import { MediaOpenGraph_Interface } from '../../../interfaces/MediaOpenGraph.interface';
import { Spinner } from 'react-bootstrap';

export type AddBookProps = {
  show?: boolean;
  onHide: () => void;
  title: string;
  item?: string;
  onAddItem: (item: BookItem) => void;
};

export class BookItem {
  title: string;
  url: string;
  urlToImage: string;
  description: string;
  date: string;
  source: string;

  constructor(title: string, url: string, urlToImage: string, description: string, date: string, source: string) {
    this.title = title;
    this.url = url;
    this.urlToImage = urlToImage;
    this.description = description;
    this.date = date;
    this.source = source;
  }
}

export const AddBook: React.FunctionComponent<AddBookProps> = function (props: AddBookProps) {
  const [search, setSearch] = useState<string>('');
  const [modifie, setModifie] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  let item_message: string;
  if (props.item === 'Article') {
    item_message = 'Add an ' + props.item;
  } else {
    item_message = 'Add a ' + props.item;
  }

  async function getUrlInfoWithOpenGraphScraper(url: string): Promise<MediaOpenGraph_Interface> {
    const ogMedia = await getUrlInfo(url);
    return ogMedia;
  }

  const submitUrl = async (url: string) => {
    if (url !== '') {
      setLoading(true);
      const ogMedia = await getUrlInfoWithOpenGraphScraper(search);
      const articleItem: BookItem = ogMedia
        ? new BookItem(ogMedia.title, ogMedia.link, ogMedia.image, ogMedia.description, ogMedia.date, '')
        : new BookItem('', url, '', '', '', '');
      props.onAddItem(articleItem);
      setSearch('');
      setLoading(false);
      props.onHide();
    }
  };

  return (
    <Modal {...props} className={styles.modal} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header className={styles.modalHeader} closeButton>
        <Modal.Title id="contained-modal-title-vcenter">{item_message}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <InputGroup className="mb-3">
          {modifie ? (
            <FormControl
              placeholder="Paste book URL here"
              value={search}
              aria-label={'Paste book URL here'}
              aria-describedby="basic-addon2"
              onChange={(ev: React.ChangeEvent<HTMLInputElement>) => setSearch(ev.target.value)}
              disabled={loading}
            />
          ) : (
            <FormControl
              placeholder="Paste book URL here"
              aria-label={'Paste book URL here'}
              aria-describedby="basic-addon2"
              onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                setSearch(ev.target.value);
                setModifie(true);
              }}
            />
          )}
          <InputGroup.Append>
            {loading ? (
              <div className={styles.loaderContainer}>
                <Spinner animation="grow" className={styles.loader} />
              </div>
            ) : (
              <Button
                className={styles.searchButton}
                variant="success"
                onClick={() => submitUrl(search)}
                disabled={loading}
              >
                Submit url
              </Button>
            )}
          </InputGroup.Append>
        </InputGroup>
      </Modal.Body>
    </Modal>
  );
};
