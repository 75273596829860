import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LoggedUser_Interface } from '../interfaces/LoggedUser.interface';
import { RootState } from '../store/store';

export const UserLogged = createSlice({
  name: 'search',
  initialState: null as null | LoggedUser_Interface,
  reducers: {
    setLoggedUser: (state, { payload }: PayloadAction<LoggedUser_Interface>) => payload,
    removeLoggedUser: () => null,
  },
});

// eslint-disable-next-line
export const { setLoggedUser: setLoggedUserActionCreator, removeLoggedUser: removeLoggedUserActionCreator } = UserLogged.actions;
export const selectSearch = (state: RootState): string | null => state.search;
export default UserLogged.reducer;
