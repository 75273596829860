import React, { useState, useEffect } from 'react';
import ReactGA from 'react-ga';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import styles from './ChooseNewPassword.module.css';
import { Button } from 'react-bootstrap';
import { resetPassword } from '../../api/user.api';
import { getUrlParam } from '../../utils/url.util';
import { useHistory } from 'react-router-dom';
import { setLoggedUserActionCreator } from '../../reducers/LoggedUser';
import { useDispatch } from 'react-redux';

export function ChooseNewPassword(): JSX.Element {
  const location = useLocation();
  useEffect(() => {
    if (window.location.hostname === 'estory.io') ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [error, setError] = useState<string>('');
  const dispatch = useDispatch();

  const history = useHistory();

  const confirm = () => {
    const rand = getUrlParam('key');
    const id = getUrlParam('id');

    if (!password) {
      setError('You should fill in both fields before submitting!');
      return false;
    } else if (confirmPassword !== password) {
      setError('Both passwords should be the same!');
      return false;
    }

    resetPassword(id, rand, password, confirmPassword).then((loggedUser) => {
      localStorage.setItem('estory_user', loggedUser.id.toString());
      dispatch(setLoggedUserActionCreator(loggedUser));
      history.push('/home');
    });
  };

  return (
    <div className={styles.backgroundImage}>
      <Helmet>
        <title>eStory | Choose a new password</title>
      </Helmet>
      <div className={styles.log}>
        <h2>Set your new password:</h2>
        <div className={styles.field} id="PasswordDiv">
          <input
            className={styles.input}
            type="password"
            name="password"
            required
            id="Password"
            value={password}
            onChange={(event) => setPassword(event.target.value)}
          />
          <label htmlFor="Password" className={styles.label}>
            Password
          </label>
        </div>
        <div className={styles.field} id="CPasswordDiv">
          <input
            className={styles.input}
            type="password"
            name="Cpassword"
            required
            id="CPassword"
            value={confirmPassword}
            onChange={(event) => setConfirmPassword(event.target.value)}
          />
          <label htmlFor="CPassword" className={styles.label}>
            Confirm password
          </label>
        </div>
        {error ? (
          <div className={styles.error}>
            <p>{error}</p>
          </div>
        ) : null}
        <div className={styles.buttonHolder}>
          <Button className={styles.button} variant="success" onClick={confirm}>
            Submit
          </Button>
        </div>
      </div>
    </div>
  );
}
