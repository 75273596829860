import React from 'react';
import styles from './TimelineStep.module.css';
import { CategorySelect } from '../../../components/Select/CategorySelect/CategorySelect';
import { ImageChoice } from '../../../components/ImagePicker/ImagePicker';
import Form from 'react-bootstrap/Form';

export type Step1Props = {
  title: string;
  onSetTitle: (title: string) => void;
  onSetCategory: (category: string[]) => void;
  onSetImage: (image: string) => void;
  categories: string[];
  imgUrl: string;
  visible: boolean;
  onSetVisible: (visible: boolean) => void;
};

export const TimelineStep: React.FunctionComponent<Step1Props> = (props: Step1Props) => {
  /**
   * Go to the help link in blank
   * @returns link in blank
   */
  const openHelpLink = () => {
    window.open(
      'https://www.notion.so/How-to-create-a-timeline-with-eStory-f1be24960c0243ef9c81195d30eb45af',
      '_blank',
    );
    return false;
  };

  return (
    <>
      <div className={styles.blockForm}>
        <div className={styles.help}>
          <p>
            Need help? Discover our guide
            <a className={styles.link} onClick={() => openHelpLink()}>
              How to create an amazing timeline
            </a>
          </p>
        </div>
        <div className={styles.title}>
          <label className={styles.obligatoire}>Timeline title</label>
          <br />
          <input
            className={styles.titleInput}
            type="text"
            placeholder="Type title here"
            pattern="[A-Za-z0-9'*/+-:.!,;]+"
            value={props.title}
            onChange={(ev: React.ChangeEvent<HTMLInputElement>) => props.onSetTitle(ev.target.value)}
            maxLength={60}
            required
          />
        </div>
        <div className={styles.category}>
          <label>Category</label>
          <br />
          <div className={styles.categorySelector}>
            <CategorySelect
              categories={props.categories}
              onSetCategory={(categories: string[]) => props.onSetCategory(categories)}
            />
          </div>
        </div>
        <div className={styles.privacy}>
          <label className={styles.privacyTitle}>Privacy</label>
          <br />
          <div className={styles.switchDiv}>
            Timeline is visible to anyone
            <Form className={styles.switchButton}>
              <Form.Check
                checked={props.visible}
                type="switch"
                id="custom-switch"
                onChange={() => props.onSetVisible(!props.visible)}
              />
            </Form>
          </div>
        </div>
        <div className={styles.image}>
          <ImageChoice
            imgUrl={props.imgUrl}
            setImage={(image: string) => props.onSetImage(image)}
            title={props.title}
          />
        </div>
      </div>
    </>
  );
};
