import React from 'react';
import styles from './CreateTimelineCard.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

interface CreateTimelineCardProps {
  borderColor: string;
  text: string;
  color: string;
  url: string;
  backgroundColor: string;
  margin: string;
  height: string;
}
export const CreateTimelineCard: React.FunctionComponent<CreateTimelineCardProps> = (
  props: CreateTimelineCardProps,
) => {
  return (
    <Link to={props.url}>
      <div
        className={styles.card}
        style={{ margin: props.margin, borderColor: props.borderColor, backgroundColor: props.backgroundColor }}
      >
        <video className={styles.cardImg} style={{ height: props.height }}></video>
        <div className={`${styles.cardContent} hvr-grow`}>
          <div className={styles.cardText} style={{ color: props.color }}>
            <h3 className={styles.cardTitle}>{props.text}</h3>
            <h3 className={styles.cardPlus}>
              <FontAwesomeIcon icon={faPlus} className={styles.plusIcon} />
            </h3>
          </div>
        </div>
      </div>{' '}
    </Link>
  );
};
