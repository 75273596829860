import React, { useState, useEffect } from 'react';
import { Event_Interface } from '../../../interfaces/Event.interface';
import styles from './CardEventFictionCreation.module.css';
import { ImageChoiceFiction } from '../../../components/ImagePicker/ImagePickerFiction';
import wand from '../../../assets/img/wand_white.png';
import { getUser, self, useTokenAPI } from '../../../api/user.api';
import { generateResultOpenAI } from '../../../api/fiction.api';
import { setLoggedUserActionCreator } from '../../../reducers/LoggedUser';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import { TokensModal } from '../../../components/Modal/TokensModal/TokensModal';
import { Spinner } from 'react-bootstrap';

export type CardEventFictionCreationProps = {
  title: string;
  tone: string;
  type: string;
  timelineId: number;
  events: Event_Interface[];
  event?: Event_Interface;
  addEvent: (
    id_timeline: number,
    description: string,
    display_roman_numbers: boolean,
    x_position: number,
    y_position: number,
    img: string,
    day: number,
    month: number,
    year: number,
  ) => void;
  modifyEvent: (
    id_event: number,
    description: string,
    display_roman_numbers: boolean,
    x_position: number,
    y_position: number,
    img: string,
    day: number,
    month: number,
    year: number,
  ) => void;
  setIndex: (index: number) => void;
};

export const CardEventFictionCreation: React.FunctionComponent<CardEventFictionCreationProps> = (
  props: CardEventFictionCreationProps,
) => {
  const [description, setDescription] = useState<string>('');

  const [imgUrl, setImgUrl] = useState<string>('');

  const [display_roman_numbers, setdisplay_roman_numbers] = useState<boolean>(false);

  const [x_position, setXPosition] = useState<number>(0);

  const [y_position, setYPosition] = useState<number>(0);

  const loggedUser = useSelector((state: RootState) => state.loggedUser);

  const [modalShow, setModalShow] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);

  const dispatch = useDispatch();

  const setImage = (image: string) => {
    setImgUrl(image);
  };

  function addToPreview() {
    props.addEvent(props.timelineId, description, display_roman_numbers, x_position, y_position, imgUrl, 0, 0, 0);
  }

  function saveChanges() {
    props.setIndex(-1);
  }

  function story() {
    const entireStory = [];
    for (let i = 0; i < props.events.length; i++) {
      entireStory.push(props.events[i].description);
    }
    return entireStory;
  }

  const promptOpenAi =
    props.events.length == 0
      ? `Write the first paragraph of a ${props.type} ${props.tone} where the title is '${props.title}' with a maximum length of 55. This first paragraph needs to end with a '.'`
      : `Write the next story paragraph with a maximum length of 55 for the following the ${props.type} ${
          props.tone
        } story called "${
          props.title
        }". This paragraph needs to end with a '.' and this is the first paragraph: ${story()}`;

  /** Generate the description of the fiction event with OpenAI
   * and use one token
   * */
  async function generateDescriptioneAndUseToken() {
    setDescription('');
    await getUser(Number(loggedUser?.id)).then(async (result) => {
      if (Number(result.nb_tokens) > 0) {
        setLoading(true);
        await generateResultOpenAI(promptOpenAi, 60)
          .then((result) => {
            if (result !== '') {
              setDescription(result);
              useTokenAPI(Number(loggedUser?.id.toString())).then(() => {
                self().then((loggedUser) => dispatch(setLoggedUserActionCreator(loggedUser)));
              });
            }
          })
          .finally(() => setLoading(false));
      } else {
        setModalShow(true);
      }
    });
  }

  useEffect(() => {
    if (props.events) {
      setDescription('');
      setImgUrl('');
      setdisplay_roman_numbers(false);
      setXPosition(0);
      setYPosition(0);
    }
    if (props.event) {
      setDescription(!!props.event ? props.event.description : '');
      setImgUrl(!!props.event ? props.event.img : '');
      setdisplay_roman_numbers(!!props.event ? props.event.display_roman_numbers : false);
      !!props.event ? setXPosition(props.event.x_position) : setXPosition(0);
      !!props.event ? setYPosition(props.event.y_position) : setYPosition(0);
    }
  }, [props.events, props.event]);

  return (
    <div className={styles.eventAddContainer}>
      <div className={styles.cardContainer}>
        <div className={styles.card}>
          <div className={description ? styles.descriptionEventAreaIn : styles.descriptionEventArea}>
            <textarea
              className={description ? styles.descriptionNotEmpty : styles.descriptionEmpty}
              value={description}
              placeholder="Event description (max 300 char.)"
              maxLength={300}
              onChange={(ev: React.ChangeEvent<HTMLTextAreaElement>) => setDescription(ev.target.value)}
              required
            />
          </div>
          {loading ? (
            <div className={styles.bottomContainer}>
              <Spinner animation="grow" className={styles.loader} />
            </div>
          ) : (
            <div className={styles.buttonDiv}>
              <button className={styles.buttonGenerate} onClick={() => generateDescriptioneAndUseToken()}>
                <img className={styles.wandlogo} src={wand} />
                <span>Generate description</span>
              </button>
            </div>
          )}
          <TokensModal
            show={modalShow}
            handleClose={() => setModalShow(false)}
            handleConfirm={() => setModalShow(false)}
          />
          <div className={styles.imageEventChoice}>
            <ImageChoiceFiction
              imgUrl={imgUrl}
              setImage={(image) => setImage(image)}
              title={description}
              titleChoiceImage={'ok'}
            />
          </div>
        </div>
      </div>
      <div className={styles.buttonAddEvent}>
        {!!props.event ? (
          description !== '' ? (
            <div className={styles.modifyEventInPreview}>
              <button className={styles.buttonCancel} onClick={() => saveChanges()}>
                Cancel
              </button>
              <button
                className={styles.buttonModifyEvent}
                onClick={() => {
                  if (props.event) {
                    props.modifyEvent(
                      Number(props.event?.id),
                      description,
                      display_roman_numbers,
                      x_position,
                      y_position,
                      imgUrl,
                      0,
                      0,
                      0,
                    );
                    saveChanges();
                  }
                }}
              >
                Save changes
              </button>
            </div>
          ) : (
            <div className={styles.modifyEventInPreview}>
              <button className={styles.buttonCancel} onClick={() => props.setIndex(-1)}>
                Cancel
              </button>
              <button className={styles.buttonModifyEvent} disabled>
                Save changes
              </button>
            </div>
          )
        ) : description !== '' ? (
          <button className={styles.buttonAddToPreview} onClick={() => addToPreview()}>
            Add to preview
          </button>
        ) : (
          <button className={styles.buttonAddToPreview} disabled>
            Add to preview
          </button>
        )}
      </div>
    </div>
  );
};
