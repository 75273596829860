import { Media_Interface } from '../interfaces/Media.interface';

export class Media implements Media_Interface {
  id: bigint;
  title: string;
  link: string;
  img: string;
  description: string;
  date: string;
  type: string;
  service: string;

  constructor(
    id: bigint,
    title: string,
    link: string,
    img: string,
    description: string,
    date: string,
    type: string,
    service: string,
  ) {
    this.id = id;
    this.title = title;
    this.link = link;
    this.img = img;
    this.description = description;
    this.date = date;
    this.type = type;
    this.service = service;
  }
}
