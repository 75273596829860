import React from 'react';
import styles from './NavigationIndicator.module.scss';
export interface NavigationIndicatorProps {
  keyNumber: number;
  isSelected: boolean;
  isBeforeSelected: boolean;
  left: number;
  onClick: ((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void) | undefined;
  onHover: ((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void) | undefined;
}

export const NavigationIndicator: React.FunctionComponent<NavigationIndicatorProps> = (
  props: NavigationIndicatorProps,
) => {
  return (
    <div
      key={props.keyNumber}
      style={{
        borderRadius: '50%',
        position: 'absolute',
        left: `${props.left}px`,
        backgroundColor: props.isSelected || props.isBeforeSelected ? '#208775' : undefined,
        width: '20px',
        height: '20px',
        transform: 'translateY(-50%)',
        cursor: 'pointer',
        zIndex: 1,
      }}
      className={styles.indicator}
      onClick={props.onClick}
      onMouseEnter={props.onHover}
      onMouseLeave={props.onHover}
    ></div>
  );
};
