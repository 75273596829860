import React from 'react';
import styles from './NoPublishModal.module.css';
import { Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';

export type NoPublishModalProps = {
  show?: boolean;
  onHide: () => void;
};

export const NoPublishModal: React.FunctionComponent<NoPublishModalProps> = function (props: NoPublishModalProps) {
  const loggedUser = useSelector((state: RootState) => state.loggedUser);

  return (
    <Modal {...props} className={styles.modal} size="lg" centered>
      <div className={styles.modalHeader}>
        <h3 className={styles.modalTitle}>Not so fast!</h3>
      </div>
      <div>
        <p className={styles.modalContent}>
          Please continue editing your timeline and add more events or save for later :(
        </p>
        <div className={styles.modalDiv}>
          <button className={styles.modalButton} onClick={() => props.onHide()}>
            Back to editing
          </button>
          <a
            className={styles.modalButton2}
            href={`/user/${loggedUser?.id}/${loggedUser?.firstname.replaceAll(
              ' ',
              '-',
            )}-${loggedUser?.lastname.replaceAll(' ', '-')}`}
          >
            <button className={styles.modalButton2Button}>Save for later</button>
          </a>
        </div>
      </div>
    </Modal>
  );
};
