import React from 'react';
import styles from './ChooseRole.module.css';
import { UserRole } from '../../models/UserRole.model';
import { updateUserRole } from '../../api/user.api';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { useHistory } from 'react-router-dom';
import festivalEmoji from '../../assets/img/emoji_party.png';
import teacherEmoji from '../../assets/img/teacherEmoji.png';
import studentEmoji from '../../assets/img/studentEmoji.png';
import otherEmoji from '../../assets/img/otherEmoji.png';

export function ChooseRole(): JSX.Element {
  const loggedUser = useSelector((state: RootState) => state.loggedUser);
  const history = useHistory();

  function handleSaveTeacher() {
    if (loggedUser) {
      updateUserRole(loggedUser.id, UserRole.Teacher.toString());
      history.push('/school');
    }
  }

  function handleSaveStudent() {
    if (loggedUser) {
      updateUserRole(loggedUser.id, UserRole.Student.toString());
      history.push('/school');
    }
  }

  function handleSaveOther() {
    if (loggedUser) {
      history.push('/role-other');
    }
  }

  return (
    <>
      {!!loggedUser ? (
        <div className={styles.header}>
          <div className="mb-4">
            <h1 className={styles.textTitle}>
              Welcome to eStory!
              <span>
                <img src={festivalEmoji} height="50" />
              </span>
            </h1>
          </div>
          <div className={styles.progressBar}>
            <div className={styles.progression}></div>
          </div>
          <div className="mb-4">
            <h3 className={styles.textBold}>Who are you ? </h3>
          </div>
          {!!loggedUser ? (
            <div className={styles.rowRole}>
              <div className="col">
                <a onClick={() => handleSaveTeacher()}>
                  <div className={styles.border}>
                    <h3> A Teacher</h3>
                    <img src={teacherEmoji} />
                  </div>
                </a>
              </div>
              <div className="col">
                <a onClick={() => handleSaveStudent()}>
                  <div className={styles.border}>
                    <h3> A Student</h3>
                    <img src={studentEmoji} />
                  </div>
                </a>
              </div>
              <div className="col">
                <a onClick={() => handleSaveOther()}>
                  <div className={styles.border}>
                    <h3> Other</h3>
                    <img src={otherEmoji} />
                  </div>
                </a>
              </div>
            </div>
          ) : null}
        </div>
      ) : null}
    </>
  );
}
