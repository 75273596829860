import axios, { AxiosRequestConfig } from 'axios';
import { LoggedUser_Interface } from '../interfaces/LoggedUser.interface';
import { Timeline_Interface } from '../interfaces/Timeline.interface';
import { User_Interface } from '../interfaces/User.interface';

export async function self(): Promise<LoggedUser_Interface> {
  return new Promise((resolve, reject) => {
    try {
      const url = `${process.env.REACT_APP_SERV_HOST}/self`;
      const config: AxiosRequestConfig = {
        method: 'get',
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true,
      };
      axios.get(url, config).then(
        (result) => {
          const user: LoggedUser_Interface = result.data.data;
          resolve(user);
        },
        (err) => reject(err),
      );
    } catch (err) {
      reject(err);
    }
  });
}

export async function getUser(id: number): Promise<User_Interface> {
  return new Promise((resolve, reject) => {
    try {
      const url = `${process.env.REACT_APP_SERV_HOST}/users/${id}`;
      const config: AxiosRequestConfig = {
        method: 'get',
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true,
      };
      axios.get(url, config).then(
        (result) => {
          const user: User_Interface = result.data.data;
          resolve(user);
        },
        (err) => reject(err),
      );
    } catch (err) {
      reject(err);
    }
  });
}

export async function cancelSub(id: number): Promise<User_Interface> {
  return new Promise((resolve, reject) => {
    try {
      const url = `${process.env.REACT_APP_SERV_HOST}/users/cancelSub/${id}`;
      const config: AxiosRequestConfig = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true,
      };
      axios.post(url, config).then(
        (result) => {
          const user: User_Interface = result.data.data;
          resolve(user);
        },
        (err) => reject(err),
      );
    } catch (err) {
      reject(err);
    }
  });
}

export async function useTokenAPI(id: number): Promise<User_Interface> {
  return new Promise((resolve, reject) => {
    try {
      const url = `${process.env.REACT_APP_SERV_HOST}/users/tokenUsed/${id}`;
      const config: AxiosRequestConfig = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true,
      };
      axios.post(url, config).then(
        (result) => {
          const user: User_Interface = result.data.data;
          resolve(user);
        },
        (err) => reject(err),
      );
    } catch (err) {
      reject(err);
    }
  });
}

export async function updateUser(id_user: number, description: string, picture: string): Promise<LoggedUser_Interface> {
  return new Promise((resolve, reject) => {
    try {
      const config: AxiosRequestConfig = {
        method: 'put',
        url: `${process.env.REACT_APP_SERV_HOST}/users/${id_user}`,
        withCredentials: true,
        data: { description, picture },
      };
      axios(config).then((result) => {
        const user: LoggedUser_Interface = result.data.data;
        resolve(user);
      });
    } catch (err) {
      reject(err);
    }
  });
}

export async function updateUserRole(id_user: number, role: string): Promise<LoggedUser_Interface> {
  return new Promise((resolve, reject) => {
    try {
      const config: AxiosRequestConfig = {
        method: 'put',
        url: `${process.env.REACT_APP_SERV_HOST}/users/${id_user}`,
        withCredentials: true,
        data: { role },
      };
      axios(config).then((updatedUser) => {
        const user: LoggedUser_Interface = updatedUser.data.data;
        resolve(user);
      });
    } catch (err) {
      reject(err);
    }
  });
}
export async function updateUserSchool(
  id_user: number,
  school: string | undefined,
  city: string,
  country: string,
): Promise<LoggedUser_Interface> {
  return new Promise((resolve, reject) => {
    try {
      const config: AxiosRequestConfig = {
        method: 'put',
        url: `${process.env.REACT_APP_SERV_HOST}/users/${id_user}`,
        withCredentials: true,
        data: { school, city, country },
      };
      axios(config).then((updatedUser) => {
        const user: LoggedUser_Interface = updatedUser.data.data;
        resolve(user);
      });
    } catch (err) {
      reject(err);
    }
  });
}

export async function getUserTimelines(
  id_user: string,
  index: number,
  num: number,
  isUserTimeline: boolean,
): Promise<{ timelines: Timeline_Interface[]; count: number; max: number }> {
  return new Promise((resolve, reject) => {
    try {
      const url = `${process.env.REACT_APP_SERV_HOST}/users/${id_user}/timelines`;
      const config: AxiosRequestConfig = {
        method: 'get',
        headers: { 'Content-Type': 'application/json' },
        params: {
          index: index,
          number: num,
          isUserTimeline: isUserTimeline,
        },
      };
      axios.get(url, config).then(
        (result) => {
          let timelines: Timeline_Interface[] = [];
          result.data.data.forEach((timeline: Timeline_Interface) => {
            timelines.push(timeline);
          });
          if (result.data.data) {
            timelines = result.data.data;
          }
          resolve({ timelines: timelines, count: result.data.length, max: result.data.max });
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            resolve({ timelines: [], count: 0, max: 0 });
          }
        },
      );
    } catch (err) {
      reject(err);
    }
  });
}

export async function resetPassword(
  id_user: number,
  rand: string,
  password: string,
  confirmPassword: string,
): Promise<LoggedUser_Interface> {
  return new Promise((resolve, reject) => {
    try {
      const config: AxiosRequestConfig = {
        method: 'put',
        url: `${process.env.REACT_APP_SERV_HOST}/users/${id_user}/password`,
        data: { rand, password, confirmPassword },
        withCredentials: true,
      };
      axios(config).then((result) => {
        const user: LoggedUser_Interface = result.data.data;
        resolve(user);
      });
    } catch (err) {
      reject(err);
    }
  });
}
