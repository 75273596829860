import React, { useEffect, useRef, useState } from 'react';
import { useContainerDimensions } from '../../../hooks/useContainerDimensions';
import useKeyPress from '../../../hooks/useKeyPress';
import { Event_Interface } from '../../../interfaces/Event.interface';
import { generateDateString } from '../../../utils/date.util';
import { EventCardPreview } from './EventCardPreview';
import styles from './EventDisplay.module.scss';
import { NavigationIndicatorPreview } from './NavigationIndicatorPreview';
import { NavigationLinePreview } from './NavigationLinePreview';
import { generateDateTranslate, initiateEvents, left } from '../util';

interface EventsDisplayPreviewProps {
  events: Event_Interface[];
  removeEvent: (event: bigint) => void;
  setIndex: (index: number) => void;
  selectedKey: number;
  setSelectedKey: (key: number) => void;
}

export enum Position {
  RIGHT,
  LEFT,
}

export const EventsDisplayPreview: React.FunctionComponent<EventsDisplayPreviewProps> = (
  props: EventsDisplayPreviewProps,
) => {
  const [hoverKey, setHoverKey] = useState<null | number>(null);
  const componentRef = useRef<HTMLDivElement>(null);
  const { width } = useContainerDimensions(componentRef);
  const [events, setEvents] = useState<{ event: Event_Interface; date: Date; position: number }[]>(
    initiateEvents(props.events),
  );
  const LEFT = useKeyPress('ArrowLeft');
  const RIGHT = useKeyPress('ArrowRight');

  const leftWithCardIncluded = function (position: number): number {
    return left(width - 100, 15, 280, true, position);
  };
  const leftWithoutCardIncluded = function (position: number): number {
    return left(width - 30, 15, 280, false, position);
  };

  const position = function (eventKey: number): Position | undefined {
    let position: Position | undefined = undefined;
    if (eventKey > props.selectedKey) position = Position.RIGHT;
    else if (eventKey < props.selectedKey) position = Position.LEFT;
    return position;
  };

  useEffect(() => {
    if (LEFT) {
      previousEvent();
    } else if (RIGHT) {
      nextEvent();
    } else if (props.events) {
      setEvents(initiateEvents(props.events));
    }
  }, [LEFT, RIGHT, props.events]);

  function previousEvent() {
    props.selectedKey - 1 >= 0 && props.setSelectedKey(props.selectedKey - 1);
  }

  function nextEvent() {
    props.selectedKey + 1 < events.length && props.setSelectedKey(props.selectedKey + 1);
  }

  const dotGap = events.length === 1 ? 50 : 0;

  return (
    <div className={styles.componentRef} ref={componentRef}>
      <div className={styles.bgClickActionContainer}>
        <div className={styles.bgClickActionLeft} onClick={() => previousEvent()}></div>
        <div className={styles.bgClickActionRight} onClick={() => nextEvent()}></div>
      </div>
      {props.events.length > 0 ? (
        <div className={styles.displayPreviewContainer}>
          <div className={styles.containerEvent}>
            <div className={styles.bgClickActionContainer}>
              <div className={styles.bgClickActionLeft} onClick={() => previousEvent()}></div>
              <div className={styles.bgClickActionRight} onClick={() => nextEvent()}></div>
            </div>
            {events.map((event, key) => {
              return (
                <div
                  className={styles.event}
                  key={Number(event.event.id)}
                  style={{ left: `${leftWithCardIncluded(event.position)}px` }}
                >
                  <EventCardPreview
                    {...event.event}
                    selected={key === props.selectedKey}
                    position={position(key)}
                    removeEvent={props.removeEvent}
                    index={key}
                    setIndex={props.setIndex}
                  />
                </div>
              );
            })}
          </div>
          <NavigationLinePreview
            cursorPosition={
              dotGap + leftWithoutCardIncluded(events[props.selectedKey] ? events[props.selectedKey].position : 0)
            }
          >
            {events.map((event, key) => {
              return (
                // eslint-disable-next-line react/jsx-key
                <>
                  <NavigationIndicatorPreview
                    key={key}
                    keyNumber={key}
                    isSelected={props.selectedKey === key}
                    isBeforeSelected={props.selectedKey > key}
                    left={dotGap + leftWithoutCardIncluded(event.position)}
                    onClick={() => props.setSelectedKey(key)}
                    onHover={(e) => (e.type === 'mouseenter' ? setHoverKey(key) : setHoverKey(null))}
                  />
                  {props.selectedKey === key && (
                    <div
                      className={styles.dotDateContainer}
                      style={{
                        left: `${dotGap + leftWithoutCardIncluded(event.position)}px`,
                        transform: `translate(${generateDateTranslate(event.event)}px, 20px)`,
                      }}
                    >
                      <div className={styles.dotDate}>
                        {generateDateString(
                          event.event.year,
                          event.event.month,
                          event.event.day,
                          event.event.display_roman_numbers,
                        )}
                      </div>
                    </div>
                  )}
                  {hoverKey === key && (
                    <div
                      className={styles.dotDateContainer}
                      style={{
                        left: `${dotGap + leftWithoutCardIncluded(event.position)}px`,
                        transform: `translate(${generateDateTranslate(event.event)}px, 20px)`,
                      }}
                    >
                      <div className={styles.dotDate}>
                        {generateDateString(
                          event.event.year,
                          event.event.month,
                          event.event.day,
                          event.event.display_roman_numbers,
                        )}
                      </div>
                    </div>
                  )}
                </>
              );
            })}
          </NavigationLinePreview>
        </div>
      ) : (
        <h1 className={styles.noEvent}>No Events for this timeline</h1>
      )}
    </div>
  );
};
