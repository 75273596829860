import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import { useLocation } from 'react-router-dom';
import styles from './about.module.css';
import tree from '../../assets/img/tree.svg';
import rating from '../../assets/img/rating.svg';
import network from '../../assets/img/network.svg';
import bgEstory from '../../assets/img/about-bg.jpeg';
import { CustomCard } from '../../components/CustomCard/CustomCard';
import { Profil } from '../../components/Profil/Profil';
import { Helmet } from 'react-helmet';
import TrackVisibility from 'react-on-screen';
import { Animated } from 'react-animated-css';

export function About(): JSX.Element {
  const location = useLocation();
  useEffect(() => {
    if (window.location.hostname === 'estory.io') ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  return (
    <>
      <Helmet>
        <title>eStory | About</title>
        <meta property="og:type" content="siteweb" />
        <meta name="twitter:title" content="eStory | About" />
        <meta
          name="description"
          content="Create and discover timelines on history, inspiring people, sciences, arts, sports and other topics."
        />
        <meta property="og:title" content="eStory | About" />
        <meta
          property="og:description"
          content="Create and discover timelines on history, inspiring people, sciences, arts, sports and other topics."
        />
        <meta
          property="og:image"
          content="https://res.cloudinary.com/dk2ghb1pg/image/upload/v1531942668/Logo_eStory_simple_klalco.png"
        />
        <meta property="og:url" content="https://estory.io/about" />
        <link rel="canonical" href={`https://estory.io${window.location.pathname}`} />
      </Helmet>
      <div className={styles.aboutContainer}>
        <div className={styles.heading}>
          <h1 className={`${styles.headingText}`}>
            We foster a community of enthusiasts narrating and discovering interesting stories!
          </h1>
        </div>
        <div className={styles.ourTeam}>
          <h2 className={styles.ourTeamText}>Our Team</h2>
          <div className={styles.people}>
            <TrackVisibility once partialVisibility>
              {({ isVisible }) =>
                isVisible && (
                  <Animated animationIn="fadeInDown" animationOut="fadeInUp" isVisible={true} animationInDelay={500}>
                    <Profil
                      imgUrl="https://res.cloudinary.com/dk2ghb1pg/image/upload/w_120,h_120,c_scale/v1525975885/lpcjeax8jjfwoeajwoh0.jpg"
                      name="Jean-Benoit MALZAC"
                      job="Founder, Product"
                      description="Is the founder of eStory. Passionate about storytelling, design and user experience."
                      mail="jb@estory.io"
                      linkedin="https://www.linkedin.com/in/jeanbenoitmalzac/"
                    />
                  </Animated>
                )
              }
            </TrackVisibility>
          </div>
          <div className={styles.people}>
            <TrackVisibility once partialVisibility>
              {({ isVisible }) =>
                isVisible && (
                  <Animated animationIn="fadeInDown" animationOut="fadeInUp" isVisible={true} animationInDelay={500}>
                    <Profil
                      imgUrl="https://res.cloudinary.com/dk2ghb1pg/image/upload/v1636048921/57155611_1143366735824359_2252058881817575424_n_jnci76.jpg"
                      name="Jean Bruté de Rémur"
                      job="Lead Developer"
                      description="Is the lead developer of the project. Loves working on projects that makes a difference."
                      mail="jean@estory.io"
                      linkedin="https://www.linkedin.com/in/jean-brut%C3%A9-de-r%C3%A9mur/"
                    />
                  </Animated>
                )
              }
            </TrackVisibility>
          </div>
        </div>
        <div className={styles.what}>
          <h2 className={styles.whatTitle}>What is eStory?</h2>
          <div className={styles.whatDesc}>
            <p>
              eStory is a free, simple and dynamic platform to explore and create stories! Our database provides an
              extensive range of historical timelines covering topics such as Art, Sciences, History, Lifestyle,
              Celebrities and Sports, as well as related media content for each event. With the new AI-assisted feature,
              you can create your own fictions, giving you the ultimate freedom of storytelling. Become an eStorian and
              share your stories with the world!
            </p>
          </div>
        </div>
        <div className={styles.cardGrid}>
          <div className={styles.card}>
            <TrackVisibility once partialVisibility>
              {({ isVisible }) =>
                isVisible && (
                  <Animated animationIn="fadeInDown" animationOut="fadeInUp" isVisible={true} animationInDelay={500}>
                    <CustomCard
                      imgUrl={tree}
                      title="Unlimitted and diverse"
                      description="For any particular topic, you can find many eStories shared by the eStorian community. For each of them, you can also find related eStories and relevant media content, such as Articles, Books and Films."
                    />
                  </Animated>
                )
              }
            </TrackVisibility>
          </div>
          <div className={styles.card}>
            <TrackVisibility once partialVisibility>
              {({ isVisible }) =>
                isVisible && (
                  <Animated animationIn="fadeInDown" animationOut="fadeInUp" isVisible={true} animationInDelay={500}>
                    <CustomCard
                      imgUrl={network}
                      title="Clear understanding on related events"
                      description="These innovative and dynamic eStory timelines help you to easily process information through timelines and gives you a clear view of each event that makes up the full eStory. "
                    />
                  </Animated>
                )
              }
            </TrackVisibility>
          </div>
          <div className={styles.card}>
            <TrackVisibility once partialVisibility>
              {({ isVisible }) =>
                isVisible && (
                  <Animated animationIn="fadeInDown" animationOut="fadeInUp" isVisible={true} animationInDelay={500}>
                    <CustomCard
                      imgUrl={rating}
                      title="Rate the quality of each eStory"
                      description="You can evaluate and rank the quality and accuracy of contributions made by our eStorian community by upvoting or downvoting their submission. The most accurate, relevant or trending eStories will be displayed first. "
                    />
                  </Animated>
                )
              }
            </TrackVisibility>
          </div>
        </div>
      </div>
      <div className={styles.once} style={{ backgroundImage: `url(${bgEstory})` }}>
        <TrackVisibility once partialVisibility>
          {({ isVisible }) =>
            isVisible && (
              <Animated animationIn="fadeInUp" animationOut="fadeInUp" isVisible={true} animationInDelay={500}>
                <div className={styles.onceText}>
                  <div className={styles.onceTextTitle}>
                    <h2>Once upon a time</h2>
                  </div>
                  <div>
                    <p className={styles.onceTextDesc}>
                      eStory is not only a design-friendly storytelling tool; but also a new way to access, visualise,
                      understand and share information.
                    </p>
                    <p className={styles.onceTextDesc}>
                      If you read this text, it means you belong to the lucky 50% of people who has access to the
                      internet today, against only 1% in 1995. We have a responsibility to access information since not
                      everyone can, as we strive to become more knowledgeable about our surroundings. We are constantly
                      exposed to a flood of data and information from various sources, all with their own unique
                      perspectives or viewpoints.
                    </p>
                    <p className={styles.onceTextDesc}>
                      eStory will make all of these versions available to you in one place, helping you to get a better
                      understanding.
                    </p>
                  </div>
                </div>
              </Animated>
            )
          }
        </TrackVisibility>
        <TrackVisibility once partialVisibility>
          {({ isVisible }) =>
            isVisible && (
              <Animated animationIn="fadeInDown" animationOut="fadeInUp" isVisible={true} animationInDelay={500}>
                <div className={styles.oncecharts}>
                  <div>
                    <p className={styles.onceChartQuote}>
                      “We are just an advanced breed of monkeys on a minor planet of a very average star. But we can
                      understand the Universe. That makes us something very special.”
                    </p>
                    <p className={styles.onceChartQuoteAuthor}>- Stephen Hawking </p>
                    <div className={styles.progress}>
                      <div className={`${styles.progressBar} ${styles.progressBar88}`}>1988</div>
                    </div>
                  </div>
                  <div>
                    <p className={styles.onceChartQuote}>
                      “We are not makers of history. We are made by history.” - Martin Luther King, Jr.
                    </p>
                    <p className={styles.onceChartQuoteAuthor}>- Martin Luther King, Jr.</p>
                    <div className={styles.progress}>
                      <div className={`${styles.progressBar} ${styles.progressBar95}`}>1963</div>
                    </div>
                  </div>
                  <div className={styles.oncecharts}>
                    <p className={styles.onceChartQuote}>
                      “History is the version of past events that people have decided to agree upon.“
                    </p>
                    <p className={styles.onceChartQuoteAuthor}>- Napoleon Bonaparte</p>
                    <div className={styles.progress}>
                      <div className={`${styles.progressBar} ${styles.progressBar34}`}>1798</div>
                    </div>
                  </div>
                </div>
              </Animated>
            )
          }
        </TrackVisibility>
      </div>
    </>
  );
}
