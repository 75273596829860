import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { fetchTimelines, fetchUsers } from '../../../api/timeline.api';
import { Timeline_Interface } from '../../../interfaces/Timeline.interface';
import { User_Interface } from '../../../interfaces/User.interface';
import styles from './SearchDropDown.module.css';
import { SearchResultTimeline } from './SearchResultTimeline';
import { SearchResultUser } from './SearchResultUser';
import { removeSearchActionCreator } from '../../../reducers/SearchBar';
export type SearchDropDownProps = {
  search: string;
};

export const SearchDropDown: React.FC<SearchDropDownProps> = (props: SearchDropDownProps): ReactElement => {
  const [timelines, setTimelines] = useState<Timeline_Interface[]>([]);
  const [fictions, setFictions] = useState<Timeline_Interface[]>([]);
  const [users, setUsers] = useState<User_Interface[]>([]);
  const [loading, setLoading] = useState<boolean>();
  const [searched, setSearched] = useState<string>('');
  const dispatch = useDispatch();
  const dropDownRef = useRef<HTMLDivElement>(null);
  useOutsideAlerter(dropDownRef);

  /**
   * Hook that alerts clicks outside of the passed ref
   */
  function useOutsideAlerter(ref: React.RefObject<HTMLDivElement>) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event: any) {
        if (ref.current && !ref.current.contains(event.target)) {
          dispatch(removeSearchActionCreator());
        }
      }
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [dropDownRef]);
  }

  useEffect(() => {
    if (props.search !== searched) {
      setSearched(props.search);
      setLoading(true);
    }
    if (loading) {
      setLoading(false);
      fetchTimelines(0, 4, 'like', null, props.search).then((timelineList) => {
        setTimelines([]);
        setFictions([]);

        timelineList.timelines.forEach((timeline) => {
          if (timeline.type_story === '' || timeline.type_story === null) {
            setTimelines((prevItems) => [...prevItems, timeline]);
          } else {
            setFictions((prevItems) => [...prevItems, timeline]);
          }
        });
      });
      fetchUsers(props.search).then((users) => {
        setUsers([]);
        users.forEach((user) => {
          setUsers((prevUsers) => [...prevUsers, user]);
        });
      });
    }
  }, [loading, props.search]);

  return (
    <div className={styles.searchDropDown} ref={dropDownRef}>
      <Link
        className={`${styles.searchedWord} ${styles.listElem}`}
        to={`/search?query=${props.search}`}
        onClick={() => dispatch(removeSearchActionCreator())}
      >
        {props.search}
      </Link>

      <div className={`${styles.searchTitle} ${styles.listElem}`}>
        <div>
          {timelines.length > 0
            ? `${timelines.length} Timeline${timelines.length > 1 ? 's' : ''}`
            : `No Timeline found`}
        </div>
      </div>

      {timelines.map((timeline, key) => (
        <Link
          key={key}
          to={`/timeline/${timeline.id}/${timeline.title}`}
          className={styles.searchResultContainer}
          onClick={() => dispatch(removeSearchActionCreator())}
        >
          <SearchResultTimeline timeline={timeline} />
        </Link>
      ))}

      <div className={`${styles.searchTitle} ${styles.listElem}`}>
        <div>
          {fictions.length > 0 ? `${fictions.length} Fiction${fictions.length > 1 ? 's' : ''}` : `No Fiction found`}
        </div>
      </div>

      {fictions.map((fiction, key) => (
        <Link
          key={key}
          to={`/fiction/${fiction.id}/${fiction.title}`}
          className={styles.searchResultContainer}
          onClick={() => dispatch(removeSearchActionCreator())}
        >
          <SearchResultTimeline timeline={fiction} />
        </Link>
      ))}

      <div className={`${styles.searchTitle} ${styles.listElem}`}>
        <div>{users.length > 0 ? `${users.length} User${users.length > 1 ? 's' : ''}` : `No User found`}</div>
      </div>

      {users.map((user, key) => (
        <a
          key={key}
          href={`/user/${user.id}/${user.firstname.replaceAll(' ', '-')}-${user.lastname.replaceAll(' ', '-')}`}
          className={styles.searchResultContainer}
          onClick={() => dispatch(removeSearchActionCreator())}
        >
          <SearchResultUser key={key} user={user} />
        </a>
      ))}
    </div>
  );
};
