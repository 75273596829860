import React, { useCallback, useRef } from 'react';
import ReactGA from 'react-ga';
import { useLocation } from 'react-router-dom';
import styles from './UserProfile.module.css';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import UserIcon from '../../assets/img/User_Icon.png';
import backgroundImg from '../../assets/img/about-bg.jpeg';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import { getUser, updateUser, self } from '../../api/user.api';
import { useParams } from 'react-router-dom';
import { User_Interface } from '../../interfaces/User.interface';
import { Helmet } from 'react-helmet';
import { setLoggedUserActionCreator } from '../../reducers/LoggedUser';
import { useDispatch } from 'react-redux';
import { Loading } from '../../components/Loading/Loading';
import { useHistory } from 'react-router-dom';
import useUserTimelineSearch from '../../hooks/useUserTimelineSearch';
import { TimelineCard } from '../../components/TimelineCard/TimelineCard/TimelineCard';
import { CreateTimelineCard } from '../../components/TimelineCard/CreateTimelineCard/CreateTimelineCard';
import { FictionCard } from '../../components/FictionCard/FictionCard';

declare global {
  interface Window {
    cloudinary: any;
  }
}

export function UserProfile(): JSX.Element {
  const location = useLocation();
  useEffect(() => {
    if (window.location.hostname === 'estory.io') ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  const loggedUser = useSelector((state: RootState) => state.loggedUser);
  const [user, setUser] = useState<User_Interface>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { id } = useParams<{ id: string }>();
  const [mode, setMode] = useState<boolean>(false);
  const [description, setDescription] = useState<string>('');
  const [plan, setPlan] = useState<string>('');
  const isCurrentUser: boolean = loggedUser ? loggedUser.id.toString() === id : false;
  const dispatch = useDispatch();
  const history = useHistory();
  const imageWidget = window.cloudinary.createUploadWidget(
    {
      cloudName: process.env.REACT_APP_CLOUD_NAME,
      uploadPreset: process.env.REACT_APP_UPLOAD_PRESET,
      sources: ['local', 'url', 'camera', 'image_search'],
      styles: {
        palette: {
          window: '#FFF',
          windowBorder: '#208775',
          tabIcon: '#0E2F5A',
          menuIcons: '#5A616A',
          textDark: '#000000',
          textLight: '#FFFFFF',
          link: '#208775',
          action: '#FF620C',
          inactiveTabIcon: '#0E2F5A',
          error: '#F44235',
          inProgress: '#208775',
          complete: '#20B832',
          sourceBg: '#E4EBF1',
        },
      },
    },
    (error: any, result: any) => {
      if (result && result.event === 'success') {
        handleSaveImage(result.info.secure_url);
      }
    },
  );
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'Nomvember',
    'December',
  ];

  useEffect(() => {
    getUser(parseInt(id)).then(
      (result) => {
        setUser(result);
        setIsLoading(false);
        if (result?.description) {
          setDescription(result.description);
        } else {
          setDescription('No description');
        }
      },
      () => history.push('/home'),
    );
  }, []);

  useEffect(() => {
    getUser(parseInt(id)).then(
      (result) => {
        setUser(result);
        setIsLoading(false);
        if (result?.plan_type) {
          setPlan(result.plan_type);
        } else {
          setPlan('Free Plan - Unlimited');
        }
      },
      () => history.push('/home'),
    );
  }, []);

  const handleCancel = () => {
    setMode(false);
    if (!user?.description) {
      setDescription('No description');
    } else {
      setDescription(user.description);
    }
  };

  async function handleSaveImage(img: string) {
    if (user) {
      await updateUser(parseInt(id), user.description, img);
      await getUser(parseInt(id)).then((result) => setUser(result));
      await self().then((loggedUser) => dispatch(setLoggedUserActionCreator(loggedUser)));
      imageWidget.close();
    }
  }

  const handleSave = () => {
    setMode(false);
    if (user) {
      updateUser(parseInt(id), description, user.picture);
      getUser(parseInt(id)).then((result) => {
        setUser(result);
      });
    }
  };

  const nbrToFetch = 100;
  const [last, setLast] = useState<number>(0);
  const { timelines, hasMore, loading, error } = useUserTimelineSearch(id, last, nbrToFetch, isCurrentUser);

  const observer: any = useRef();
  const lastTimelineElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setLast((prevLast) => prevLast + nbrToFetch + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore],
  );

  const handleImage = () => imageWidget.open();

  useEffect(() => {
    const userName = `${user?.firstname.replaceAll(' ', '-')}-${user?.lastname.replaceAll(' ', '-')}`;
    const currentURL = `${window.location.pathname}`;
    const canonicalURL = `/user/${id}/${userName}`;
    if (user === undefined) {
      return;
    } else if (currentURL !== canonicalURL) {
      //window.location.replace(canonicalURL);
    }
  }, [id, user, history]);

  return (
    <>
      <Helmet>
        <title>{!!user ? user.firstname + ' ' + user.lastname + ' Profile | eStory' : 'Profile | eStory'}</title>
        <meta property="og:type" content="siteweb" />
        <meta
          name="twitter:title"
          content={!!user ? user.firstname + ' ' + user.lastname + ' Profile | eStory' : 'Profile | eStory'}
        ></meta>
        <meta name="description" content={user?.description ? user.description : 'Profile | eStory'} />
        <meta
          property="og:title"
          content={!!user ? user.firstname + ' ' + user.lastname + ' Profile | eStory' : 'Profile | eStory'}
        />
        <meta property="og:image" content={user?.picture ? user?.picture : UserIcon} />
        <meta
          property="og:url"
          content={`https://${window.location.hostname}/user/${id}/${user?.firstname}-${user?.lastname}`}
        />
        <link
          rel="canonicals"
          href={`https://${window.location.hostname}/user/${id}/${user?.firstname}-${user?.lastname}`}
        />
      </Helmet>
      {isLoading ? (
        <div className={styles.loaderContainer}>
          <Loading />
        </div>
      ) : (
        <>
          <div className={styles.bannerProfile}>
            <img className={styles.blurImg} src={backgroundImg} />
            {!!user ? (
              <div className={styles.userProfile}>
                <div>
                  <h1 className={styles.userName}>{user.firstname + ' ' + user.lastname}</h1>
                  {isCurrentUser ? (
                    !!loggedUser?.picture ? (
                      <img className={styles.profilePictureLogged} src={loggedUser?.picture} onClick={handleImage} />
                    ) : (
                      <img className={styles.profilePictureLogged} src={UserIcon} onClick={handleImage} />
                    )
                  ) : !!user.picture ? (
                    <img className={styles.profilePicture} src={user.picture} />
                  ) : (
                    <img className={styles.profilePicture} src={UserIcon} />
                  )}
                </div>
              </div>
            ) : (
              <div></div>
            )}
            <div>
              {!!user ? (
                <div>
                  <p className={styles.validation}>
                    {user.validation_date ? (
                      'Member since ' +
                      months[new Date(user?.validation_date).getMonth()] +
                      ' ' +
                      new Date(user?.validation_date).getFullYear()
                    ) : (
                      <></>
                    )}
                  </p>
                  {isCurrentUser ? (
                    <div className={styles.buttonsProfile}>
                      <h3 className={plan === 'Free Plan - Unlimited' ? styles.noPlan : styles.plan}>{plan}</h3>
                      <div className={styles.buttonDiv}>
                        <Button className={styles.updateButton} variant="dark" href="/pricing">
                          Update
                        </Button>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}

                  <div className={styles.buttonsProfile}></div>
                </div>
              ) : (
                <p>No such user</p>
              )}
            </div>
            {user && isCurrentUser ? (
              <>
                {mode ? (
                  <div className={styles.formEdit}>
                    <textarea
                      className={styles.edit}
                      name="description"
                      placeholder="Who are you? Please introduce yourself in a few words..."
                      onChange={(event) => setDescription(event.target.value)}
                    >
                      {description !== 'No description' ? description : user.description}
                    </textarea>
                    <Button className={styles.editButton} variant="dark" onClick={() => handleCancel()}>
                      Cancel
                    </Button>
                    <Button className={styles.saveButton} variant="success" onClick={() => handleSave()}>
                      Save
                    </Button>
                  </div>
                ) : (
                  <div className={styles.buttonsProfile}>
                    <h2 className={description === 'No description' ? styles.noDescription : styles.description}>
                      {description}
                    </h2>
                    <div className={styles.buttonDiv}>
                      <Button className={styles.editButton} variant="dark" onClick={() => setMode(true)}>
                        Edit
                      </Button>
                    </div>
                  </div>
                )}
              </>
            ) : (
              <div></div>
            )}
          </div>
          <div className={styles.AppTimelineList}>
            <Container fluid={true}>
              <Row>
                {timelines.map((timeline, index) => {
                  if (timelines.length === index + 1) {
                    return (
                      <Col xs={12} sm={6} md={4} lg={3} key={index} className={styles.myCol}>
                        <div ref={lastTimelineElementRef} key={Number(timeline.id)}>
                          {timeline.type_story === 'fiction' ? (
                            <FictionCard
                              id={Number(timeline.id)}
                              title={timeline.title}
                              backgroundImageUrl={timeline.img_background}
                              author={
                                timeline.UserModels[0]
                                  ? `${timeline.UserModels[0].firstname} ${timeline.UserModels[0].lastname}`
                                  : `Unknown User`
                              }
                              nbLike={timeline.nbLike ? timeline.nbLike : 0}
                              status={timeline.status}
                              isCurrentUserTimeline={isCurrentUser}
                            />
                          ) : (
                            <TimelineCard
                              id={Number(timeline.id)}
                              title={timeline.title}
                              backgroundImageUrl={timeline.img_background}
                              author={
                                timeline.UserModels[0]
                                  ? `${timeline.UserModels[0].firstname} ${timeline.UserModels[0].lastname}`
                                  : `Unknown User`
                              }
                              nbLike={timeline.nbLike ? timeline.nbLike : 0}
                              status={timeline.status}
                              isCurrentUserTimeline={isCurrentUser}
                            />
                          )}
                        </div>
                      </Col>
                    );
                  } else {
                    return (
                      <Col xs={12} sm={6} md={4} lg={3} key={index} className={styles.myCol}>
                        <div key={Number(timeline.id)}>
                          {timeline.type_story === 'fiction' ? (
                            <FictionCard
                              id={Number(timeline.id)}
                              title={timeline.title}
                              backgroundImageUrl={timeline.img_background}
                              author={
                                timeline.UserModels[0]
                                  ? `${timeline.UserModels[0].firstname} ${timeline.UserModels[0].lastname}`
                                  : `Unknown User`
                              }
                              nbLike={timeline.nbLike ? timeline.nbLike : 0}
                              status={timeline.status}
                              isCurrentUserTimeline={isCurrentUser}
                            />
                          ) : (
                            <TimelineCard
                              id={Number(timeline.id)}
                              title={timeline.title}
                              backgroundImageUrl={timeline.img_background}
                              author={
                                timeline.UserModels[0]
                                  ? `${timeline.UserModels[0].firstname} ${timeline.UserModels[0].lastname}`
                                  : `Unknown User`
                              }
                              nbLike={timeline.nbLike ? timeline.nbLike : 0}
                              status={timeline.status}
                              isCurrentUserTimeline={isCurrentUser}
                            />
                          )}
                        </div>
                      </Col>
                    );
                  }
                })}
                {isCurrentUser ? (
                  <>
                    <Col xs={12} sm={6} md={4} lg={3} className={styles.myCol}>
                      <CreateTimelineCard
                        borderColor={'transparent'}
                        color={'white'}
                        text={'Create Timeline'}
                        url={'/create'}
                        backgroundColor={'#208775'}
                        margin={'4px'}
                        height={'100%'}
                      />
                    </Col>
                    <Col xs={12} sm={6} md={4} lg={3} className={styles.myCol}>
                      <CreateTimelineCard
                        borderColor={'transparent'}
                        color={'white'}
                        text={'Create Fiction'}
                        url={'/createFiction'}
                        backgroundColor={'#5823E2'}
                        height={'100%'}
                        margin={'4px'}
                      />
                    </Col>
                  </>
                ) : null}
              </Row>
            </Container>
            <div>{loading && <Loading />}</div>
            <div>{error && 'Error'}</div>
          </div>
        </>
      )}
    </>
  );
}
