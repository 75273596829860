import React, { ReactElement } from 'react';
import { User_Interface } from '../../../interfaces/User.interface';
import styles from './SearchDropDown.module.css';

export type SearchResultProps = {
  user: User_Interface;
};

export const SearchResultUser: React.FC<SearchResultProps> = (props: SearchResultProps): ReactElement => {
  return (
    <div className={styles.searchResult}>
      <img
        src={
          props.user.picture
            ? props.user.picture
            : 'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg'
        }
        className={`${styles.searchResultUserImage} ${styles.searchResultImage}`}
      />
      <div>
        <div className={styles.searchResultText}>{`${props.user.firstname} ${props.user.lastname}`}</div>
      </div>
    </div>
  );
};
