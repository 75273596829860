export function convertToRoman(num: number): string {
  if (num === 0) return '0';
  if (num === 1) return 'I';
  if (num === -100) return '-I';
  if (num > 10000 || num < -10000) return `${num}`;
  if (num % 100 !== 0) return `${num}`;
  function convertToRomanRec(num: number, acc: string): string {
    if (num >= 10000) return convertToRomanRec(num - 10000, acc + 'C');
    if (num >= 9000) return convertToRomanRec(num - 9000, acc + 'XC');
    if (num >= 5000) return convertToRomanRec(num - 5000, acc + 'L');
    if (num >= 4000) return convertToRomanRec(num - 4000, acc + 'XL');
    if (num >= 1000) return convertToRomanRec(num - 1000, acc + 'X');
    if (num >= 900) return convertToRomanRec(num - 900, acc + 'IX');
    if (num >= 500) return convertToRomanRec(num - 500, acc + 'V');
    if (num >= 400) return convertToRomanRec(num - 400, acc + 'IV');
    if (num >= 100) return convertToRomanRec(num - 100, acc + 'I');
    return acc;
  }
  if (num < 0) return '-' + convertToRomanRec(-num, '');
  return convertToRomanRec(num + 100, '');
}

export function romanToNumber(roman: string): number | undefined {
  const yearMap: { [key: string]: number } = {
    I: 1,
    II: 100,
    III: 200,
    IV: 300,
    V: 400,
    VI: 500,
    VII: 600,
    VIII: 700,
    IX: 800,
    X: 900,
    XI: 1000,
    XII: 1100,
    XIII: 1200,
    XIV: 1300,
    XV: 1400,
    XVI: 1500,
    XVII: 1600,
    XVIII: 1700,
    XIX: 1800,
    XX: 1900,
    XXI: 2000,
    XXII: 2100,
    XXIII: 2200,
    XXIV: 2300,
    XXV: 2400,
  };
  const negative = roman.startsWith('-');
  if (negative) roman = roman.slice(1);
  if (roman in yearMap) {
    if (negative) {
      return roman === 'I' ? -100 : -yearMap[roman] - 100;
    }
    return yearMap[roman];
  }
  return undefined;
}

export function generateDateString(
  year?: bigint,
  month?: number,
  day?: number,
  display_roman_numbers?: boolean,
): string {
  if (display_roman_numbers) {
    let year_string: string;
    if (year) {
      year_string = convertToRoman(Number(year));
      return `${year_string}`;
    }
    return `${year}`;
  } else {
    if (year && month && day) {
      if (String(day).length === 1 && String(month).length === 1) return `0${day}/0${month}/${year}`;
      if (String(day).length === 1) return `0${day}/${month}/${year}`;
      if (String(month).length === 1) return `${day}/0${month}/${year}`;
      return `${day}/${month}/${year}`;
    } else if (year && month && !day) {
      if (Number(month) !== 0) {
        const month_string: { [key: number]: string } = {
          1: 'January',
          2: 'February',
          3: 'March',
          4: 'April',
          5: 'May',
          6: 'June',
          7: 'July',
          8: 'August',
          9: 'September',
          10: 'October',
          11: 'November',
          12: 'December',
        };
        return `${month_string[Number(month)]} ${year}`;
      } else {
        return `${year}`;
      }
    } else if (year && !month && day) return `${year}`;
    else if (year && !month && !day) return `${year}`;
    else return 'unknown date';
  }
}

export function generateDateStringFromDateFormat(date: Date): string {
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  let dayToPrint = `${day}`;
  let monthToPrint = `${month}`;
  if (day < 10) dayToPrint = day.toString().padStart(2, '0');
  if (month < 10) monthToPrint = month.toString().padStart(2, '0');
  return `${dayToPrint}/${monthToPrint}/${year}`;
}
