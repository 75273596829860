import axios, { AxiosRequestConfig } from 'axios';
import { Like_Interface } from '../interfaces/Like.interface';

export async function getUsersLikedTimeline(id_timeline: number): Promise<Like_Interface[]> {
  return new Promise((resolve, reject) => {
    try {
      const url = `${process.env.REACT_APP_SERV_HOST}/liketimelines/timeline/${id_timeline}`;
      const config: AxiosRequestConfig = {
        method: 'get',
        headers: { 'Content-Type': 'application/json' },
      };
      axios.get(url, config).then((result) => {
        const likes: Like_Interface[] = result.data.data;
        resolve(likes);
      });
    } catch (err) {
      reject(err);
    }
  });
}

export async function getTimelinesLikedByUser(id_user: number): Promise<Like_Interface[]> {
  return new Promise((resolve, reject) => {
    try {
      const url = `${process.env.REACT_APP_SERV_HOST}/liketimelines/user/${id_user}`;
      const config: AxiosRequestConfig = {
        method: 'get',
        headers: { 'Content-Type': 'application/json' },
      };
      axios.get(url, config).then((result) => {
        const likes: Like_Interface[] = result.data.data;
        resolve(likes);
      });
    } catch (err) {
      reject(err);
    }
  });
}

export async function postLikeTimeline(id_timeline: number): Promise<Like_Interface> {
  return new Promise((resolve, reject) => {
    try {
      const config: AxiosRequestConfig = {
        method: 'post',
        url: `${process.env.REACT_APP_SERV_HOST}/liketimelines`,
        withCredentials: true,
        data: { id_timeline },
      };
      axios(config).then((result) => {
        const event: Like_Interface = result.data.data[0];
        resolve(event);
      });
    } catch (err) {
      reject(err);
    }
  });
}

export async function removeLike(id: number): Promise<Like_Interface> {
  return new Promise((resolve, reject) => {
    try {
      const url = `${process.env.REACT_APP_SERV_HOST}/liketimelines/${id}`;
      const config: AxiosRequestConfig = {
        method: 'delete',
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true,
      };
      axios.delete(url, config).then((result) => {
        const event: Like_Interface = result.data.data[0];
        resolve(event);
      });
    } catch (err) {
      reject(err);
    }
  });
}
