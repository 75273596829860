import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import { useLocation, useHistory, NavLink } from 'react-router-dom';
import styles from './NotFound.module.css';
import { Helmet } from 'react-helmet';

export function NotFound(): JSX.Element {
  const location = useLocation();
  useEffect(() => {
    if (window.location.hostname === 'estory.io') ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  const history = useHistory();

  return (
    <>
      <Helmet>
        <title>eStory | 404</title>
        <meta property="og:title" content={`eStory`} />
        <meta
          property="og:image"
          content={`https://res.cloudinary.com/dk2ghb1pg/image/upload/v1531942668/Logo_eStory_simple_klalco.png`}
        />
        <meta property="og:url" content={`${window.location.hostname}/not-found`} />
      </Helmet>
      <div className={styles.page404}>
        <div className={styles.dialog404}>
          <div className={styles.wrapper404}>
            <div className={styles.number}>
              <div className={`${styles.line} ${styles.hori} ${styles.top}`}></div>
              <div className={`${styles.active404} ${styles.line} ${styles.vert} ${styles.top} ${styles.left}`}></div>
              <div className={`${styles.active404} ${styles.line} ${styles.vert} ${styles.top} ${styles.right}`}></div>
              <div className={`${styles.active404} ${styles.line} ${styles.hori} ${styles.middle}`}></div>
              <div className={`${styles.line} ${styles.vert} ${styles.bottom} ${styles.left}`}></div>
              <div
                className={`${styles.active404} ${styles.line} ${styles.vert} ${styles.bottom} ${styles.right}`}
              ></div>
              <div className={`${styles.line} ${styles.hori} ${styles.bottom}`}></div>
            </div>
            <div className={styles.number}>
              <div className={`${styles.active404} ${styles.line} ${styles.hori} ${styles.top}`}></div>
              <div className={`${styles.active404} ${styles.line} ${styles.vert} ${styles.top} ${styles.left}`}></div>
              <div className={`${styles.active404} ${styles.line} ${styles.vert} ${styles.top} ${styles.right}`}></div>
              <div className={`${styles.line} ${styles.hori} ${styles.middle}`}></div>
              <div
                className={`${styles.active404} ${styles.line} ${styles.vert} ${styles.bottom} ${styles.left}`}
              ></div>
              <div
                className={`${styles.active404} ${styles.line} ${styles.vert} ${styles.bottom} ${styles.right}`}
              ></div>
              <div className={`${styles.active404} ${styles.line} ${styles.hori} ${styles.bottom}`}></div>
            </div>
            <div className={styles.number}>
              <div className={`${styles.line} ${styles.hori} ${styles.top}`}></div>
              <div className={`${styles.active404} ${styles.line} ${styles.vert} ${styles.top} ${styles.left}`}></div>
              <div className={`${styles.active404} ${styles.line} ${styles.vert} ${styles.top} ${styles.right}`}></div>
              <div className={`${styles.active404} ${styles.line} ${styles.hori} ${styles.middle}`}></div>
              <div className={`${styles.line} ${styles.vert} ${styles.bottom} ${styles.left}`}></div>
              <div
                className={`${styles.active404} ${styles.line} ${styles.vert} ${styles.bottom} ${styles.right}`}
              ></div>
              <div className={`${styles.line} ${styles.hori} ${styles.bottom}`}></div>
            </div>
          </div>
          <h1 className={styles.title}>Lost in time...</h1>
          <p className={styles.text}>The page you were looking for does not exist.</p>
          <button className={styles.button} onClick={() => history.replace('/')}>
            Travel back!
          </button>
        </div>
      </div>
    </>
  );
}
