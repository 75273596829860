import React, { useCallback, useRef, useState, useEffect } from 'react';
import ReactGA from 'react-ga';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import styles from './TimelineList.module.css';
import TimelineListPopular from './TimelineListPopular';
import TimelineListRecent from './TimelineListRecent';
import { Drift } from 'react-drift';
import useTimelineSearch from '../../hooks/useTimelineSearch';
import { Loading } from '../../components/Loading/Loading';
import { Col, Container, Row } from 'react-bootstrap';
import { TimelineCard } from '../../components/TimelineCard/TimelineCard/TimelineCard';
import { FictionCard } from '../../components/FictionCard/FictionCard';
import { generateDateStringFromDateFormat } from '../../utils/date.util';
const drift_key = `${process.env.REACT_APP_DRIFT_KEY}`;

export const TimelineListContainer: React.FunctionComponent = function () {
  const location = useLocation();
  useEffect(() => {
    if (window.location.hostname === 'estory.io') ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  const nbrToFetch = 16;
  const [last, setLast] = useState<number>(4);
  const { timelines, hasMore, loading, error } = useTimelineSearch(last, nbrToFetch, '', '', '');

  const observer: any = useRef();
  const lastTimelineElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setLast((prevLast) => prevLast + nbrToFetch + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore],
  );

  return (
    <>
      <Helmet>
        <title>eStory | Visualise the key events of stories</title>
        <meta property="og:type" content="siteweb" />
        <meta name="twitter:title" content="eStory | Visualise the key events of stories" />
        <meta
          name="description"
          content="Create and discover timelines on history, inspiring people, sciences, arts, sports and other topics."
        />
        <meta property="og:title" content="eStory | Visualise the key events of stories" />
        <meta
          property="og:description"
          content="Create and discover timelines on history, inspiring people, sciences, arts, sports and other topics."
        />
        <meta
          property="og:image"
          content="https://res.cloudinary.com/dk2ghb1pg/image/upload/v1531942668/Logo_eStory_simple_klalco.png"
        />
        <meta property="og:url" content="https://estory.io/home" />
      </Helmet>
      <TimelineListPopular />
      <TimelineListRecent />

      <div className={styles.AppTimelineList}>
        <h3 className={styles.title}>Other stories</h3>
        <Container fluid={true}>
          <Row>
            {timelines.map((timeline, index) => {
              if (timelines.length === index + 1) {
                return (
                  <Col xs={12} sm={6} md={4} lg={3} key={index} className={styles.myCol}>
                    <div ref={lastTimelineElementRef} key={Number(timeline.id)}>
                      {timeline.type_story === 'fiction' ? (
                        <FictionCard
                          id={Number(timeline.id)}
                          title={timeline.title}
                          backgroundImageUrl={timeline.img_background}
                          author={
                            timeline.UserModels[0]
                              ? `${timeline.UserModels[0].firstname} ${timeline.UserModels[0].lastname}`
                              : `Unknown User`
                          }
                          nbLike={timeline.nbLike ? timeline.nbLike : 0}
                        />
                      ) : (
                        <TimelineCard
                          id={Number(timeline.id)}
                          title={timeline.title}
                          backgroundImageUrl={timeline.img_background}
                          author={
                            timeline.UserModels[0]
                              ? `${timeline.UserModels[0].firstname} ${timeline.UserModels[0].lastname}`
                              : `Unknown User`
                          }
                          lastUpdate={
                            timeline.timeline_updated_at
                              ? generateDateStringFromDateFormat(new Date(timeline.timeline_updated_at))
                              : 'Unknown date'
                          }
                          nbLike={timeline.nbLike ? timeline.nbLike : 0}
                        />
                      )}
                    </div>
                  </Col>
                );
              } else {
                return (
                  <Col xs={12} sm={6} md={4} lg={3} key={index} className={styles.myCol}>
                    <div key={Number(timeline.id)}>
                      {timeline.type_story === 'fiction' ? (
                        <FictionCard
                          id={Number(timeline.id)}
                          title={timeline.title}
                          backgroundImageUrl={timeline.img_background}
                          author={
                            timeline.UserModels[0]
                              ? `${timeline.UserModels[0].firstname} ${timeline.UserModels[0].lastname}`
                              : `Unknown User`
                          }
                          nbLike={timeline.nbLike ? timeline.nbLike : 0}
                        />
                      ) : (
                        <TimelineCard
                          id={Number(timeline.id)}
                          title={timeline.title}
                          backgroundImageUrl={timeline.img_background}
                          author={
                            timeline.UserModels[0]
                              ? `${timeline.UserModels[0].firstname} ${timeline.UserModels[0].lastname}`
                              : `Unknown User`
                          }
                          lastUpdate={
                            timeline.timeline_updated_at
                              ? generateDateStringFromDateFormat(new Date(timeline.timeline_updated_at))
                              : 'Unknown date'
                          }
                          nbLike={timeline.nbLike ? timeline.nbLike : 0}
                        />
                      )}
                    </div>
                  </Col>
                );
              }
            })}
          </Row>
        </Container>
        <div>{loading && <Loading />}</div>
        <div>{error && 'Error'}</div>
      </div>
      <Drift appId={drift_key} />
    </>
  );
};
