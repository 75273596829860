import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import mail from '../../assets/img/message.svg';
import styles from './EmailValidation.module.css';

export function EmailValidation(): JSX.Element {
  const location = useLocation();
  useEffect(() => {
    if (window.location.hostname === 'estory.io') ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  return (
    <div className={styles.container}>
      <Helmet>
        <title>eStory | Waiting validation</title>
      </Helmet>
      <div className="container">
        <div>
          <h2 className={styles.title}>You have received a confirmation email!</h2>
        </div>
      </div>

      <div className={styles.message}>
        <div className="row">
          <div className="col-sm-12 animated fadeInDown">
            <div>
              <img src={mail} width="120"></img>
            </div>
            <div>
              <h3 className={styles.subtitle}>Go to your mailbox to activate your eStory account.</h3>
              <h3 className={styles.subtitle}>
                If you didn&apos;t receive it after a few minutes, please check your spam folder.
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
