import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { fetchTimelines } from '../../api/timeline.api';
import { TimelineCard } from '../../components/TimelineCard/TimelineCard/TimelineCard';
import { Timeline_Interface } from '../../interfaces/Timeline.interface';
import Carousel from 'react-bootstrap/Carousel';
import styles from './TimelineList.module.css';
import next from '../../assets/img/next-2.png';
import { FictionCard } from '../../components/FictionCard/FictionCard';
import { generateDateStringFromDateFormat } from '../../utils/date.util';

const TimelineListRecent: React.FunctionComponent = function () {
  const [timelines, setTimelines] = useState<Array<Timeline_Interface>>([]);
  const [loading, setLoading] = useState<boolean>(true);

  function addTimeline(timeline: Timeline_Interface) {
    setTimelines((prevItems) => [...prevItems, timeline]);
  }

  useEffect(() => {
    if (loading) {
      setLoading(false);
      fetchTimelines(0, 12, 'date', null, null).then((timelineList) => {
        timelineList.timelines.forEach((timeline) => {
          addTimeline(timeline);
        });
      });
    }
  }, [loading]);
  return (
    <div className={styles.AppTimelineList}>
      {timelines.length > 0 && (
        <>
          <h3 className={styles.title}>Most recent stories</h3>
          <Carousel
            interval={null}
            nextIcon={
              <div className="carousel-control-next-ico">
                <img src={next} className={styles.next} />
              </div>
            }
            prevIcon={
              <div className="carousel-control-next-ico">
                <img src={next} className={styles.previous} />
              </div>
            }
            style={{ zIndex: 1 }}
          >
            {[0, 4, 8].map((i) => (
              <Carousel.Item key={i}>
                <Container fluid={true}>
                  <Row>
                    {timelines?.slice(i, i + 4).map((timeline) => (
                      <Col xs={12} sm={6} md={6} lg={3} key={Number(timeline.id)} className={styles.myCol}>
                        {timeline.type_story === 'fiction' ? (
                          <FictionCard
                            id={Number(timeline.id)}
                            title={timeline.title}
                            backgroundImageUrl={timeline.img_background}
                            author={
                              timeline.UserModels[0]
                                ? `${timeline.UserModels[0].firstname} ${timeline.UserModels[0].lastname}`
                                : `Unknown User`
                            }
                            nbLike={timeline.nbLike ? timeline.nbLike : 0}
                          />
                        ) : (
                          <TimelineCard
                            id={Number(timeline.id)}
                            title={timeline.title}
                            backgroundImageUrl={timeline.img_background}
                            author={
                              timeline.UserModels[0]
                                ? `${timeline.UserModels[0].firstname} ${timeline.UserModels[0].lastname}`
                                : `Unknown User`
                            }
                            lastUpdate={
                              timeline.timeline_updated_at
                                ? generateDateStringFromDateFormat(new Date(timeline.timeline_updated_at))
                                : 'Unknown date'
                            }
                            nbLike={timeline.nbLike ? timeline.nbLike : 0}
                          />
                        )}
                      </Col>
                    ))}
                  </Row>
                </Container>
              </Carousel.Item>
            ))}
          </Carousel>
        </>
      )}
    </div>
  );
};

export default TimelineListRecent;
