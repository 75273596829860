import { useEffect } from 'react';
import ReactGA from 'react-ga';

const usePageTracking = (): void => {
  useEffect(() => {
    console.log('initialisation');
    if (window.location.hostname === 'estory.io') ReactGA.initialize('UA-92770397-1');
  }, []);
};

export default usePageTracking;
