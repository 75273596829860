import React from 'react';
import styles from './SubscriptionCard.module.css';

export type SubscriptionCardProps = {
  type: string;
  onChange?: () => void;
  link?: string;
  userPlan?: string;
  recommended?: boolean;
  subExpiration?: string;
  cancelSub?: () => void;
};

export const SubscriptionCard: React.FunctionComponent<SubscriptionCardProps> = (props: SubscriptionCardProps) => {
  if (props.type === 'Premium') {
    if (props.userPlan === 'premium') {
      return (
        <div className={styles.card}>
          <div className={styles.inProgress}>
            <span>Subscribed</span>
          </div>
          <div>
            <div className={styles.cardHeader}>
              <h3 className={styles.cardTitle}>{props.type}</h3>
              <h3 className={styles.cardSubtitle}>For SMEs and creative agencies </h3>
              <h3 className={styles.cardPrice}>$19.99 p/m </h3>
            </div>
            <div className={styles.cardContent}>
              <div className={styles.cardFirstDescription}>✔️ 180 tokens ✨ every month </div>
              <div className={styles.cardDescription}>✔️ Everything in Standard</div>
              <div className={styles.cardDescription}>✔️ Unlimited timeline</div>
              <div className={styles.cardDescription}>✔️ Workspace to add users</div>
              <div className={styles.cardDescription}>✔️ Analytics & performance dashboard</div>
            </div>
            <div className={styles.textRenew}>Renews on : {props.subExpiration}</div>
            <a onClick={props.cancelSub} className={styles.cancelSubButton}>
              Cancel subscription
            </a>
          </div>
        </div>
      );
    } else {
      return (
        <div className={styles.card}>
          <div className={styles.cardHeader}>
            <h3 className={styles.cardTitle}>{props.type}</h3>
            <h3 className={styles.cardSubtitle}>For SMEs and creative agencies </h3>
            <h3 className={styles.cardPrice}>$19.99 p/m </h3>
          </div>
          <div className={styles.cardContent}>
            <div className={styles.cardFirstDescription}>✔️ 180 tokens ✨ every month</div>
            <div className={styles.cardDescription}>✔️ Everything in Standard</div>
            <div className={styles.cardDescription}>✔️ Unlimited timeline</div>
            <div className={styles.cardDescription}>✔️ Workspace to add users</div>
            <div className={styles.cardDescription}>✔️ Analytics & performance dashboard</div>
          </div>
          <div className={styles.divButton}>
            <a href={props.link} className={styles.cardGetButton}>
              Get Premium
            </a>
          </div>
        </div>
      );
    }
  } else if (props.type === 'Standard') {
    if (props.userPlan === 'standard') {
      return (
        <div className={styles.card}>
          <div className={styles.inProgress}>
            <span>Subscribed</span>
          </div>
          <div>
            <div className={styles.cardHeader}>
              <h3 className={styles.cardTitle}>{props.type}</h3>
              <h3 className={styles.cardSubtitle}>For schools & storytellers </h3>
              <h3 className={styles.cardPrice}>$4.99 p/m </h3>
            </div>
            <div className={styles.cardContent}>
              <div className={styles.cardFirstDescription}>✔️ 35 tokens ✨ every month</div>
              <div className={styles.cardDescription}>✔️ Everything in Basic</div>
              <div className={styles.cardDescription}>✔️ Unlimited timeline</div>
              <div className={styles.cardDescription}>✔️ Autoplay timelines</div>
              <div className={styles.cardDescription}>✔️ Export to PDF & Print</div>
              <div className={styles.cardDescription}>✔️ Support</div>
            </div>
            <div className={styles.textRenew}>Renews on : {props.subExpiration}</div>
          </div>
          <a onClick={props.cancelSub} className={styles.cancelSubButton}>
            Cancel subscription
          </a>
        </div>
      );
    } else if (props.recommended) {
      return (
        <div className={styles.card}>
          <div className={styles.inProgress}>
            <span>Recommended</span>
          </div>
          <div>
            <div className={styles.cardHeader}>
              <h3 className={styles.cardTitle}>{props.type}</h3>
              <h3 className={styles.cardSubtitle}>For schools & storytellers </h3>
              <h3 className={styles.cardPrice}>$4.99 p/m </h3>
            </div>
            <div className={styles.cardContent}>
              <div className={styles.cardFirstDescription}>✔️ 35 tokens ✨ every month</div>
              <div className={styles.cardDescription}>✔️ Everything in Basic</div>
              <div className={styles.cardDescription}>✔️ Unlimited timeline</div>
              <div className={styles.cardDescription}>✔️ Autoplay timelines</div>
              <div className={styles.cardDescription}>✔️ Export to PDF & Print</div>
              <div className={styles.cardDescription}>✔️ Support</div>
            </div>
          </div>
          <div className={styles.divButton}>
            <a href={props.link} className={styles.cardGetButton}>
              Get Standard
            </a>
          </div>
        </div>
      );
    } else {
      return (
        <div className={styles.card}>
          <div>
            <div className={styles.cardHeader}>
              <h3 className={styles.cardTitle}>{props.type}</h3>
              <h3 className={styles.cardSubtitle}>For schools & storytellers </h3>
              <h3 className={styles.cardPrice}>$4.99 p/m </h3>
            </div>
            <div className={styles.cardContent}>
              <div className={styles.cardFirstDescription}>✔️ 35 tokens ✨ every month</div>
              <div className={styles.cardDescription}>✔️ Everything in Basic</div>
              <div className={styles.cardDescription}>✔️ Unlimited timeline</div>
              <div className={styles.cardDescription}>✔️ Autoplay timelines</div>
              <div className={styles.cardDescription}>✔️ Export to PDF & Print</div>
              <div className={styles.cardDescription}>✔️ Support</div>
            </div>
          </div>
          <div className={styles.divButton}>
            <a href={props.link} className={styles.cardGetButton}>
              Get Standard
            </a>
          </div>
        </div>
      );
    }
  } else {
    if (props.userPlan === 'basic') {
      return (
        <div className={styles.card}>
          <div className={styles.inProgress}>
            <span>Subscribed</span>
          </div>
          <div className={styles.cardHeader}>
            <h3 className={styles.cardTitle}>{props.type}</h3>
            <h3 className={styles.cardSubtitle}>For curious explorers </h3>
            <h3 className={styles.cardPrice}>$1.99 p/m </h3>
          </div>
          <div className={styles.cardContent}>
            <div className={styles.cardFirstDescription}>✔️ 10 tokens ✨ every month</div>
            <div className={styles.cardDescription}>✔️ Unlimited timelines</div>
            <div className={styles.cardDescription}>✔️ Private/Public timelines </div>
            <div className={styles.cardDescription}>✔️ Embedded and share option</div>
          </div>
          <div className={styles.textRenew}>Renews on : {props.subExpiration}</div>
          <a onClick={props.cancelSub} className={styles.cancelSubButton}>
            Cancel subscription
          </a>
        </div>
      );
    } else {
      return (
        <div className={styles.card}>
          <div className={styles.cardHeader}>
            <h3 className={styles.cardTitle}>{props.type}</h3>
            <h3 className={styles.cardSubtitle}>For curious explorers </h3>
            <h3 className={styles.cardPrice}>$1.99 p/m </h3>
          </div>
          <div className={styles.cardContent}>
            <div className={styles.cardFirstDescription}>✔️ 10 tokens ✨ every month</div>
            <div className={styles.cardDescription}>✔️ Unlimited timelines</div>
            <div className={styles.cardDescription}>✔️ Private/Public timelines </div>
            <div className={styles.cardDescription}>✔️ Embedded and share option</div>
          </div>
          <div className={styles.divButton}>
            <a href={props.link} className={styles.cardGetButton}>
              Get Basic
            </a>
          </div>
        </div>
      );
    }
  }
};
