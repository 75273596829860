import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import achievement from '../../assets/img/achievement.svg';
import styles from './AccountValidation.module.css';
import { Button } from 'react-bootstrap';
import { getUrlParam } from '../../utils/url.util';
import { validate } from '../../api/auth.api';
import { Loading } from '../Loading/Loading';
import { useHistory } from 'react-router-dom';
import { setLoggedUserActionCreator } from '../../reducers/LoggedUser';
import { useDispatch } from 'react-redux';

export function AccountValidation(): JSX.Element {
  const location = useLocation();
  useEffect(() => {
    if (window.location.hostname === 'estory.io') ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  const [validated, setValidated] = useState(false);
  const dispatch = useDispatch();

  const history = useHistory();

  useEffect(() => {
    const rand = getUrlParam('key');
    const id = getUrlParam('id');

    if (!rand || !id) history.push('/home');

    validate(rand, id).then((loggedUser) => {
      localStorage.setItem('estory_user', loggedUser.id.toString());
      dispatch(setLoggedUserActionCreator(loggedUser));
      setValidated(true);
    });
  }, []);

  if (!validated) return <Loading />;

  return (
    <div className={styles.container}>
      <Helmet>
        <title>eStory | Account Validated</title>
      </Helmet>
      <div className={styles.container}>
        <div className="heading animated fadeInUp">
          <div>
            <div className={styles.congratulations}>
              <h2>Congratulations!</h2>
              <h2>You are now ready to take part in eStory.</h2>
              <p></p>
            </div>
          </div>

          <div className="text-center our-services">
            <div className="row">
              <div className="col-sm-12 animated fadeInDown">
                <div>
                  <img className={styles.image} src={achievement} width="120" />
                </div>
                <div>
                  <h3 className={styles.message}>Click the button below to start browsing.</h3>
                  <div className="btn btn-start2 animated fadeInUpBig">
                    <Button className={styles.access} variant="success" data-scroll href="/role">
                      Access to eStory
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
