import React, { useState, useEffect } from 'react';
import ReactGA from 'react-ga';
import { useLocation } from 'react-router-dom';
import { StepBarFiction } from './StepBarFiction/StepBarFiction';
import { FictionStep } from './FictionStep/FictionStep';
import { EventFictionPreviewStep } from './EventFictionPreviewStep/EventFictionPreviewStep';
import { Helmet } from 'react-helmet';
import styles from './FictionCreation.module.css';
import { Event_Interface } from '../../interfaces/Event.interface';
import { BrowserRouter as Router, Redirect, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { ConfirmModal } from '../../components/Modal/ConfirmModal/ConfirmModal';
import { Spinner } from 'react-bootstrap';
import { deleteEvent, postEvent, putEvent } from '../../api/timeline.api';
import { deleteFiction, postFiction, putFiction } from '../../api/fiction.api';
import { NoPublishModal } from '../TimelineModification/Modals/NoPublishModal';

const fictionType = (type: string) => {
  let typeFiction = type;
  if (type === '🤠 Adventure') {
    typeFiction = 'Adventure';
  }
  if (type === '💞 Romance') {
    typeFiction = 'Romance';
  }
  if (type === '🤣 Comedy') {
    typeFiction = 'Comedy';
  }
  if (type === '🎭 Drama') {
    typeFiction = 'Drama';
  }
  if (type === '🧐 Mystery') {
    typeFiction = 'Mystery';
  }
  if (type === '🧜‍♀️ Fantasy') {
    typeFiction = 'Fantasy';
  }
  if (type === '🤴 Historical') {
    typeFiction = 'Historical';
  }
  if (type === '😱 Horror') {
    typeFiction = 'Horror';
  }
  if (type === '🤖 Science Fiction') {
    typeFiction = 'Science Fiction';
  }
  return typeFiction;
};

const fictionTone = (tone: string) => {
  let toneFiction = tone;
  if (tone === '😃 Happy') {
    toneFiction = 'Happy';
  }
  if (tone === '😢 Sad') {
    toneFiction = 'Sad';
  }
  if (tone === '😱 Scary') {
    toneFiction = 'Scary';
  }
  if (tone === '😮 Surprising') {
    toneFiction = 'Surprising';
  }
  if (tone === '😐 Neutral') {
    toneFiction = 'Neutral';
  }
  return toneFiction;
};

export const FictionCreation: React.FunctionComponent = () => {
  const location = useLocation();

  useEffect(() => {
    if (window.location.hostname === 'estory.io') ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  const [title, setTitle] = useState<string>('');

  const [type, setType] = useState<string>('');

  const [tone, setTone] = useState<string>('');

  const [imgUrl, setImgUrl] = useState<string>('');

  const [selectedKey, setSelectedKey] = useState(0);

  const [visible, setVisible] = useState<boolean>(false);

  const [newFictionId, setFictionId] = useState<number>();

  const loggedUser = useSelector((state: RootState) => state.loggedUser);

  const [modalConfirmShow, setModalConfirmShow] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);

  const history = useHistory();

  const goToFictionModified = () => {
    const url = '/fiction/' + newFictionId + '/' + title;
    history.push(url);
  };

  const onSetTitle = (title: string) => {
    setTitle(title);
  };

  const onSetType = (type: string) => {
    setType(type);
  };

  const onSetTone = (tone: string) => {
    setTone(tone);
  };

  const onSetVisible = (visible: boolean) => {
    setVisible(visible);
  };
  const onSetImage = (image: string) => {
    setImgUrl(image);
  };

  const fictionPost = (title: string, type: string, tone: string, imgUrl: string, visible: boolean, step: number) => {
    setLoading(true);
    const typeFic = fictionType(type);
    const toneFic = fictionTone(tone);
    postFiction(title, typeFic, imgUrl, visible, step, 'in progress', 'fiction', toneFic).then((fiction) => {
      setFictionId(Number(fiction.id));
      changeStep(numStep);
      setLoading(false);
    });
  };

  const modifyFiction = (
    id: number,
    title: string,
    type: string,
    tone: string,
    imgUrl: string,
    visible: boolean,
    step: number,
  ) => {
    setLoading(true);
    const typeFic = fictionType(type);
    const toneFic = fictionTone(tone);
    putFiction(id, title, typeFic, imgUrl, visible, step, 'in progress', 'fiction', toneFic).then(() => {
      changeStep(numStep);
      setLoading(false);
    });
  };

  const publishFiction = (
    id: number,
    title: string,
    type: string,
    tone: string,
    imgUrl: string,
    visible: boolean,
    step: number,
  ) => {
    setLoading(true);
    const typeFic = fictionType(type);
    const toneFic = fictionTone(tone);
    putFiction(id, title, typeFic, imgUrl, visible, step, 'published', 'fiction', toneFic)
      .then(() => {
        <Router>
          <Redirect
            to={{
              pathname: '/home',
            }}
          />
        </Router>;
      })
      .then(() => goToFictionModified());
  };
  const [modalShow, setModalShow] = useState<boolean>(false);

  const [numStep, setNumStep] = useState<number>(1);

  const changeStep = (step: number) => {
    if (step == 1 || step == 2) {
      setNumStep(step + 1);
    }
  };

  const goToStep = (step: number) => {
    {
      newFictionId !== null || newFictionId !== undefined ? setNumStep(step) : null;
    }
  };

  const [events, setEvents] = useState<Event_Interface[]>([]);

  useEffect(() => {
    if (selectedKey >= events.length && selectedKey) setSelectedKey(events.length - 1);
  }, [events, selectedKey]);

  const addEvent = (
    id_timeline: number,
    description: string,
    display_roman_numbers: boolean,
    x_position: number,
    y_position: number,
    img: string,
  ) => {
    postEvent(id_timeline, '', description, 0, 0, 0, display_roman_numbers, x_position, y_position, img, '0px').then(
      (event) => {
        events.push(event);
        const sortedEvents = events.sort((a, b) => (a.id > b.id ? 1 : -1));
        setEvents(sortedEvents.slice(0));
        setSelectedKey(sortedEvents.findIndex((e) => e.id === event.id));
      },
    );
  };

  const eventModification = (event: Event_Interface, index: number) => {
    putEvent(
      Number(event.id),
      event.title,
      event.description,
      event.day,
      event.month,
      Number(event.year),
      event.display_roman_numbers,
      event.x_position,
      event.y_position,
      event.img,
      '',
    ).then((result: Event_Interface) => {
      events[index] = result;
      setEvents(events.slice(0));
    });
  };

  const removeEvent = (id: bigint) => {
    deleteEvent(Number(id)).then(() => {
      const ids = events.map((event) => event.id);
      const index = ids.indexOf(id);
      events.splice(index, 1);
      setEvents(events.slice(0));
      if (selectedKey >= events.length && selectedKey) setSelectedKey(events.length - 1);
    });
  };

  return (
    <>
      <Helmet>
        <title>eStory | Create Fiction</title>
      </Helmet>
      <div className={styles.backgroundImage}>
        <div>
          <div className={styles.formContainer}>
            <div className={styles.stepBarContainer}>
              <StepBarFiction stepNumber={numStep} goToStep={(numStep: number) => goToStep(numStep)} />
            </div>
            {numStep === 2 && newFictionId ? (
              <div className={styles.stepContainer2}>
                <EventFictionPreviewStep
                  timelineId={newFictionId}
                  title={title}
                  imgUrl={imgUrl}
                  events={events}
                  type={fictionType(type)}
                  tone={fictionTone(tone)}
                  selectedKey={selectedKey}
                  setSelectedKey={setSelectedKey}
                  addEvent={addEvent}
                  modifyEvent={eventModification}
                  removeEvent={removeEvent}
                />
              </div>
            ) : (
              <div className={styles.stepContainer}>
                <FictionStep
                  title={title}
                  onSetTitle={(title) => onSetTitle(title)}
                  onSetImage={(image) => onSetImage(image)}
                  imgUrl={imgUrl}
                  visible={visible}
                  onSetVisible={(visible) => onSetVisible(visible)}
                  types={type}
                  onSetType={(type) => onSetType(type)}
                  tones={tone}
                  onSetTone={(tone) => onSetTone(tone)}
                />
              </div>
            )}
            {loading ? (
              <div className={styles.bottomContainer}>
                <Spinner animation="grow" className={styles.loader} />
              </div>
            ) : numStep == 1 ? (
              <div className={styles.bottomContainer}>
                {newFictionId && (
                  <button className={styles.deleteButton} onClick={() => setModalConfirmShow(true)} disabled={!title}>
                    <FontAwesomeIcon icon={faTrashAlt} className={styles.trashIcon} />
                    Delete
                  </button>
                )}
                <button
                  className={styles.button}
                  disabled={title === ''}
                  onClick={() => {
                    if (newFictionId) {
                      modifyFiction(newFictionId, title, type, tone, imgUrl, visible, numStep);
                    } else {
                      fictionPost(title, type, tone, imgUrl, visible, numStep);
                    }
                  }}
                >
                  Save and continue
                </button>
              </div>
            ) : (
              <div className={styles.bottomContainer}>
                {newFictionId && (
                  <button className={styles.deleteButton} onClick={() => setModalConfirmShow(true)} disabled={!title}>
                    <FontAwesomeIcon icon={faTrashAlt} className={styles.trashIcon} />
                    Delete
                  </button>
                )}
                <button
                  className={styles.button2}
                  onClick={() => {
                    if (newFictionId && events.length > 3) {
                      publishFiction(newFictionId, title, type, tone, imgUrl, visible, numStep);
                    } else {
                      setModalShow(true);
                    }
                  }}
                >
                  Publish
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      <ConfirmModal
        show={modalConfirmShow}
        handleClose={() => setModalConfirmShow(false)}
        text={'Are you sure to delete this fiction ?'}
        closeButtonText={'Cancel'}
        confirmButtonText={'Delete'}
        handleConfirm={() =>
          deleteFiction(Number(newFictionId)).then(() =>
            history.push(
              `/user/${loggedUser?.id}/${loggedUser?.firstname.replaceAll(' ', '-')}-${loggedUser?.lastname.replaceAll(
                ' ',
                '-',
              )}`,
            ),
          )
        }
      />
      <NoPublishModal show={modalShow} onHide={() => setModalShow(false)} />
    </>
  );
};
