import React, { useState } from 'react';
import styles from './ImagePickerFiction.module.css';
import { FaFolderOpen } from 'react-icons/fa';
import { BsX } from 'react-icons/bs';
import wand from '../../assets/img/wand_white.png';
import { ImageOpenAiModal } from '../Modal/ImageOpenAiModal/ImageOpenAiModal';

declare global {
  interface Window {
    cloudinary: any;
  }
}

export type ImageChoiceFictionProps = {
  imgUrl: string;
  setImage: (img: string) => void;
  title: string;
  titleChoiceImage?: string;
};

export const ImageChoiceFiction: React.FunctionComponent<ImageChoiceFictionProps> = (
  props: ImageChoiceFictionProps,
) => {
  const [modalShow, setModalShow] = useState<boolean>(false);
  const openWidget = () => {
    window.cloudinary
      .createUploadWidget(
        {
          cloudName: process.env.REACT_APP_CLOUD_NAME,
          uploadPreset: process.env.REACT_APP_UPLOAD_PRESET,
        },
        (error: any, result: any) => {
          if (result && result.event === 'success') {
            props.setImage(result.info.secure_url);
          }
        },
      )
      .open();
  };

  return (
    <div className={styles.backgroundImage}>
      {props.titleChoiceImage ? <label>Event image</label> : <label>Background image</label>}
      <br />
      {props.imgUrl === '' || props.imgUrl === null ? (
        <div className={styles.table}>
          <div className={styles.colonne}>
            <div className={styles.buttonDiv}>
              <button className={styles.buttonUpload} onClick={openWidget}>
                <FaFolderOpen />
                <span>Folder</span>
              </button>
            </div>
          </div>
          <div className={styles.colonne2}>OR</div>
          <div className={styles.colonne}>
            <div className={styles.buttonDiv}>
              <button className={styles.buttonGenerate} onClick={() => setModalShow(true)}>
                <img className={styles.wandlogo} src={wand} />
                <span>Generate</span>
              </button>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className={styles.image}>
            <img className={styles.imageSearched} src={props.imgUrl} />
            <BsX className={styles.deleteImageChoice} onClick={() => props.setImage('')} />
          </div>
        </>
      )}

      <ImageOpenAiModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        onSetImage={(image) => props.setImage(image)}
      />
    </div>
  );
};
