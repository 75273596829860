import React, { useState } from 'react';
import styles from './ChooseDescription.module.css';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { useHistory } from 'react-router-dom';
import { updateUser } from '../../api/user.api';
import { Button } from 'react-bootstrap';
import festivalEmoji from '../../assets/img/emoji_party.png';

export function ChooseDescription(): JSX.Element {
  const [description, setDescription] = useState<string>('');
  const loggedUser = useSelector((state: RootState) => state.loggedUser);

  const history = useHistory();
  const url = `/user/${loggedUser?.id}/${loggedUser?.firstname.replaceAll(' ', '-')}-${loggedUser?.lastname.replaceAll(
    ' ',
    '-',
  )}`;

  const handleSave = async () => {
    if (loggedUser) {
      if (description) {
        await updateUser(loggedUser.id, description, '');
        history.push(url);
      }
    }
  };

  return (
    <>
      <div className={styles.header}>
        <div className="mb-4">
          <h1 className={styles.textTitle}>
            Welcome to eStory!
            <span>
              <img src={festivalEmoji} height="50" />
            </span>
          </h1>
        </div>
        <div className={styles.progressBar}>
          <div className={styles.progression}></div>
        </div>
        <div className="mb-4">
          <h3 className={styles.textBold}>Let others know who you are! </h3>
        </div>
        <input
          onChange={(event) => setDescription(event.target.value)}
          className={styles.textInput}
          placeholder="Write a short description about yourself..."
          type="text"
          maxLength={280}
        />
        <div>
          <Button
            disabled={description === ''}
            className={styles.button}
            onClick={() => handleSave()}
            style={{ borderRadius: '15px', background: '#428576', borderColor: 'transparent', fontSize: '25px' }}
          >
            Continue
          </Button>
        </div>
        <div>
          <a className={styles.later} href={url} style={{ color: '#252628' }}>
            Later
          </a>
        </div>
      </div>
    </>
  );
}
