import React, { useState } from 'react';
import styles from './ImagePicker.module.css';
import { GiEarthAmerica } from 'react-icons/gi';
import { FaFolderOpen } from 'react-icons/fa';
import { ImageSearch } from '../Modal/ImageSearchModal/ImageSearchModal';
import { BsX } from 'react-icons/bs';

declare global {
  interface Window {
    cloudinary: any;
  }
}

export type ImageChoiceProps = {
  imgUrl: string;
  setImage: (img: string) => void;
  title: string;
};

export const ImageChoice: React.FunctionComponent<ImageChoiceProps> = (props: ImageChoiceProps) => {
  const [modalShow, setModalShow] = useState<boolean>(false);
  const openWidget = () => {
    // create the widget
    window.cloudinary
      .createUploadWidget(
        {
          cloudName: process.env.REACT_APP_CLOUD_NAME,
          uploadPreset: process.env.REACT_APP_UPLOAD_PRESET,
        },
        (error: any, result: any) => {
          if (result && result.event === 'success') {
            props.setImage(result.info.secure_url);
          }
        },
      )
      .open(); // open up the widget after creation
  };

  return (
    <div className={styles.backgroundImage}>
      <label>Background image</label>
      <br />
      {props.imgUrl === '' || props.imgUrl === null ? (
        <div className={styles.table}>
          <div className={styles.colonne}>
            <div className={styles.buttonDiv}>
              <button className={styles.buttonUpload} onClick={openWidget}>
                <FaFolderOpen />
                <span>Folder</span>
              </button>
            </div>
          </div>
          <div className={styles.colonne2}>OR</div>
          <div className={styles.colonne}>
            <div className={styles.buttonDiv}>
              <button className={styles.buttonUpload} onClick={() => setModalShow(true)}>
                <GiEarthAmerica />
                <span>Find</span>
              </button>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className={styles.image}>
            <img className={styles.imageSearched} src={props.imgUrl} />
            <BsX className={styles.deleteImageChoice} onClick={() => props.setImage('')} />
          </div>
        </>
      )}
      <ImageSearch
        title={props.title}
        show={modalShow}
        onHide={() => setModalShow(false)}
        onSetImage={(image) => props.setImage(image)}
      />
    </div>
  );
};
