import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './index.css';
import App from './App';
import { store } from './store/store';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import './custom.scss';
import 'hover.css';
import { self } from './api/user.api';
import { setLoggedUserActionCreator } from './reducers/LoggedUser';
import { Loading } from './features/Loading/Loading';

const currentUserId = localStorage.getItem('estory_user');

const renderApp = () => {
  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <Router>
          <Switch>
            <Route path={'/'} render={() => <App />} />;
          </Switch>
        </Router>
      </Provider>
    </React.StrictMode>,
    document.getElementById('root'),
  );
};

if (!!currentUserId) {
  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <Loading />
      </Provider>
    </React.StrictMode>,
    document.getElementById('root'),
  );

  self().then(
    (loggedUser) => {
      store.dispatch(setLoggedUserActionCreator(loggedUser));
      renderApp();
    },
    () => {
      localStorage.removeItem('estory_user');
      renderApp();
    },
  );
} else {
  renderApp();
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
