import React, { ReactElement, useEffect, useState } from 'react';
import styles from './SearchBar.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { Link, Redirect } from 'react-router-dom';

type SearchBarMenuprops = {
  closeMenu: () => void;
};

export const SearchBarMenu: React.FC<SearchBarMenuprops> = (props: SearchBarMenuprops): ReactElement => {
  const [search, setSearch] = useState<string>('');
  const [enter, setEnter] = useState<boolean>(false);

  useEffect(() => {
    if (location.pathname === `/?search=${search}`) {
      setEnter(false);
    }
  }, [enter]);

  const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  function onKeyUp(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.charCode === 13) {
      event?.preventDefault();
      setEnter(true);
      props.closeMenu();
    }
  }

  if (enter && search && search.length > 0) {
    return <Redirect to={`/search/${search}`} />;
  }

  return (
    <div className={styles.searchBar}>
      <form className={styles.searchForm}>
        <input
          placeholder="Search..."
          className={`js-search`}
          type="text"
          onChange={handleInput}
          onKeyPress={onKeyUp}
        ></input>
        {search ? (
          <Link to={`/search?query=${search}`} onClick={() => search && search.length > 1 && props.closeMenu()}>
            <FontAwesomeIcon icon={faSearch} className={styles.searchIcon} />
          </Link>
        ) : (
          <FontAwesomeIcon icon={faSearch} className={styles.searchIcon} />
        )}
      </form>
    </div>
  );
};
