import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import styles from '../PrivacyPolicy/PrivacyPolicy.module.css';

export function TermsOfService(): JSX.Element {
  const location = useLocation();
  useEffect(() => {
    if (window.location.hostname === 'estory.io') ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  return (
    <div>
      <Helmet>
        <title>eStory | Terms of services</title>
        <meta property="og:type" content="siteweb" />
        <meta name="twitter:title" content="eStory | Terms of services" />
        <meta
          name="description"
          content="Discover the best timelines on history, inspiring people, sciences, arts, sports and other topics."
        />
        <meta property="og:title" content="eStory | Terms of services" />
        <meta
          property="og:description"
          content="Discover the best timelines on history, inspiring people, sciences, arts, sports and other topics."
        />
        <meta
          property="og:image"
          content="https://res.cloudinary.com/dk2ghb1pg/image/upload/v1531942668/Logo_eStory_simple_klalco.png"
        />
        <meta property="og:url" content="https://estory.io/privacy-policy" />
      </Helmet>
      <div className={styles.container}>
        <div>
          <div>
            <h1 className={styles.title}>Term of Service</h1>
          </div>
        </div>
        <p>
          The following terms and conditions govern all use of the eStory.io website and all content, services and
          products available at or through the website, including, but not limited to, eStory.io (“the Website”). The
          Website is owned and operated by eStory Limited (eStory.io”). The Website is offered subject to your
          acceptance without modification of all of the terms and conditions contained herein and all other operating
          rules, policies and procedures that may be published from time to time on this Site by eStory.io
          (collectively, the “Agreement”). Please read this Agreement carefully before accessing or using the web site.
          By accessing or using any part of the web site, you agree to become bound by the terms and conditions of this
          agreement. If you do not agree to all the terms and conditions of this agreement, then you may not access the
          website or use any services. If these terms and conditions are considered an offer by eStory.io, acceptance is
          expressly limited to these terms.
        </p>
        <h1>Your eStory account and site</h1>
        <p>
          If you create an account on the Website, you are responsible for maintaining the security of your account and
          any timelines you create, and you are fully responsible for all activities that occur under the account and
          any other actions taken in connection with the account. You must not describe or assign keywords to your
          timelines in a misleading or unlawful manner, including in a manner intended to trade on the name or
          reputation of others, and eStory.io may change or remove any description or keyword that it considers
          inappropriate or unlawful, or otherwise likely to cause eStory.io liability. You must immediately notify
          eStory.io of any unauthorized uses of your account or any other breaches of security. eStory.io will not be
          liable for any acts or omissions by You, including any damages of any kind incurred as a result of such acts
          or omissions.
        </p>

        <h1>Responsibility of contributors</h1>
        <p>
          If you operate an account, create any timelines, comment on any Website content, post material to the Website,
          post links on the Website, or otherwise make (or allow any third party to make) material available by means of
          the Website (any such material, “Content”), You are entirely responsible for the content of, and any harm
          resulting from, that Content. That is the case regardless of whether the Content in question constitutes text,
          graphics, an audio file, or computer software. By making Content available, you represent and warrant that:
          <ul>
            <li>
              the downloading, copying and use of the Content will not infringe the proprietary rights, including but
              not limited to the copyright, patent, trademark or trade secret rights, of any third party;
            </li>
            <li>
              if your employer has rights to intellectual property you create, you have either (i) received permission
              from your employer to post or make available the Content, including but not limited to any software, or
              (ii) secured from your employer a waiver as to all rights in or to the Content;
            </li>
            <li>
              you have fully complied with any third-party licenses relating to the Content, and have done all things
              necessary to successfully pass through to end users any required terms;
            </li>
            <li>
              the Content does not contain or install any viruses, worms, malware, Trojan horses or other harmful or
              destructive content;
            </li>
            <li>
              the Content is not spam, and does not contain unethical or unwanted commercial content designed to drive
              traffic to third party sites or boost the search engine rankings of third party sites, or to further
              unlawful acts (such as phishing) or mislead recipients as to the source of the material (such as
              spoofing);
            </li>
            <li>
              the Content is not obscene, libelous or defamatory (
              <a href="https://www.eff.org/issues/bloggers/legal/liability/defamation" target="_blank" rel="noreferrer">
                more on what that means
              </a>
              ), hateful or racially or ethnically objectionable, and does not violate the privacy or publicity rights
              of any third party; and
            </li>
            <li>
              you have, in the case of Content that includes computer code, accurately categorized and/or described the
              type, nature, uses and effects of the materials, whether requested to do so by eStory.io or otherwise.
            </li>
          </ul>
          By submitting Content to the eStory.io you grant eStory.io a world-wide, royalty-free, and non-exclusive
          license to reproduce, modify, adapt and publish the Content solely for the purpose of displaying, distributing
          and promoting your content, as well as the Website. If you delete Content, eStory.io will use reasonable
          efforts to remove it from the Website, but you acknowledge that caching or references to the Content may not
          be made immediately unavailable. Without limiting any of those representations or warranties, eStory.io has
          the right (though not the obligation) to, in eStory.io’s sole discretion (i) refuse or remove any content
          that, in eStory.io’s reasonable opinion, violates any eStory.io policy or is in any way harmful or
          objectionable, or (ii) terminate or deny access to and use of the Website to any individual or entity for any
          reason, in eStory.io’ s sole discretion. eStory.io will have no obligation to provide a refund of any amounts
          previously paid.
        </p>

        <h1>Responsibility of Website Visitors</h1>
        <p>
          eStory.io has not reviewed, and cannot review, all of the material, including computer software, posted to the
          Website, and cannot therefore be responsible for that material’s content, use or effects. By operating the
          Website, eStory.io does not represent or imply that it endorses the material there posted, or that it believes
          such material to be accurate, useful or non-harmful. You are responsible for taking precautions as necessary
          to protect yourself and your computer systems from viruses, worms, Trojan horses, and other harmful or
          destructive content. The Website may contain content that is offensive, indecent, or otherwise objectionable,
          as well as content containing technical inaccuracies, typographical mistakes, and other errors. The Website
          may also contain material that violates the privacy or publicity rights, or infringes the intellectual
          property and other proprietary rights, of third parties, or the downloading, copying or use of which is
          subject to additional terms and conditions, stated or unstated. eStory.io disclaims any responsibility for any
          harm resulting from the use by visitors of the Website, or from any downloading by those visitors of content
          there posted.
        </p>

        <h1>Content posted on other websites</h1>
        <p>
          We have not reviewed, and cannot review, all of the material, including computer software, made available
          through the websites and webpages to which eStory.io links, and that link to eStory.io. eStory.io does not
          have any control over those non-eStory.io websites and webpages, and is not responsible for their contents or
          their use. By linking to a non-eStory.io website or webpage, eStory.io does not represent or imply that it
          endorses such website or webpage. You are responsible for taking precautions as necessary to protect yourself
          and your computer systems from viruses, worms, Trojan horses, and other harmful or destructive content.
          eStory.io disclaims any responsibility for any harm resulting from your use of non-eStory.io websites and
          webpages.
        </p>

        <h1>Copyright infringement and DMCA Policy</h1>
        <p>
          As eStory.io asks others to respect its intellectual property rights, it respects the intellectual property
          rights of others. If you believe that material located on or linked to by eStory.io violates your copyright,
          you are encouraged to notify eStory.io in accordance with the Digital Millennium Copyright Act (”DMCA”)
          Policy. eStory.io will respond to all such notices, including as required or appropriate by removing the
          infringing material or disabling all links to the infringing material. In the case of a visitor who may
          infringe or repeatedly infringes the copyrights or other intellectual property rights of eStory.io or others,
          eStory.io may, in its discretion, terminate or deny access to and use of the Website. In the case of such
          termination, eStory.io will have no obligation to provide a refund of any amounts previously paid to
          eStory.io.
        </p>

        <h1>Intellectual property</h1>
        <p>
          This Agreement does not transfer from eStory.io to you any eStory.io or third party intellectual property, and
          all right, title and interest in and to such property will remain (as between the parties) solely with
          eStory.io. eStory.io, eStory, the eStory logo, and all other trademarks, service marks, graphics and logos
          used in connection with eStory.io, or the Website are trademarks or registered trademarks of eStory.io or
          eStory.io’s licensors. Other trademarks, service marks, graphics and logos used in connection with the Website
          may be the trademarks of other third parties. Your use of the Website grants you no right or license to
          reproduce or otherwise use any eStory.io or third-party trademarks.
        </p>

        <h1>Changes</h1>
        <p>
          eStory.io reserves the right, at its sole discretion, to modify or replace any part of this Agreement. It is
          your responsibility to check this Agreement periodically for changes. Your continued use of or access to the
          Website following the posting of any changes to this Agreement constitutes acceptance of those changes.
          eStory.io may also, in the future, offer new services and/or features through the Website (including, the
          release of new tools and resources). Such new features and/or services shall be subject to the terms and
          conditions of this Agreement.
        </p>

        <h1>Termination</h1>
        <p>
          eStory.io may terminate your access to all or any part of the Website at any time, with or without cause, with
          or without notice, effective immediately. If you wish to terminate this Agreement or your eStory.io account
          (if you have one), you may simply discontinue using the Website.
        </p>

        <h1>Disclaimer of warranties</h1>
        <p>
          The Website is provided “as is”. eStory.io and its suppliers and licensors hereby disclaim all warranties of
          any kind, express or implied, including, without limitation, the warranties of merchantability, fitness for a
          particular purpose and non-infringement. Neither eStory.io nor its suppliers and licensors, makes any warranty
          that the Website will be error free or that access thereto will be continuous or uninterrupted. You understand
          that you download from, or otherwise obtain content or services through, the Website at your own discretion
          and risk.
        </p>

        <h1>Limitation of liability</h1>
        <p>
          In no event will eStory.io, or its suppliers or licensors, be liable with respect to any subject matter of
          this agreement under any contract, negligence, strict liability or other legal or equitable theory for: (i)
          any special, incidental or consequential damages; (ii) the cost of procurement or substitute products or
          services; (iii) for interruption of use or loss or corruption of data; or (iv) for any amounts that increase
          the fees paid by you to eStory.io under this agreement during the twelve (12) month period prior to the date
          the cause of the action accrues. eStory.io shall have no liability for any failure or delay due to matters
          beyond their reasonable control. The foregoing shall not apply to the extent prohibited by applicable law.
        </p>

        <h1>General representation and warranty</h1>
        <p>
          You represent and warrant that (i) your use of the Website will be in strict accordance with the eStory.io
          Privacy Policy, with this Agreement and with all applicable laws and regulations (including without limitation
          any local laws or regulations in your country, state, city, or other governmental area, regarding online
          conduct and acceptable content, and including all applicable laws regarding the transmission of technical data
          exported from the United States or the country in which you reside) and (ii) your use of the Website will not
          infringe or misappropriate the intellectual property rights of any third party.
        </p>

        <h1>Indemnification</h1>
        <p>
          You agree to indemnify and hold harmless eStory.io, its contractors, and its licensors, and their respective
          directors, officers, employees and agents from and against any and all claims and expenses, including
          attorneys’ fees, arising out of your use of the Website, including but not limited to out of your violation
          this Agreement.
        </p>

        <h1>Age limitation</h1>
        <p>
          eStory requires an email address to create a personal account. As this is personally identifiable information,
          you agree not to sign up for a personal account using an email address if you are under the age of 13 (please
          see
          <a href="http://www.coppa.org/" target="_blank" rel="noreferrer">
            COPPA
          </a>
          ). Generated user accounts created by a master account on a premium subscription plan do not require us to
          collect an email address and are thus exempt from this age limitation. You may also wish to refer to our
          <a href="/privacy-policy" target="_blank">
            privacy policy
          </a>
          for more information.
        </p>
      </div>
    </div>
  );
}
