import React, { useState, useEffect } from 'react';
import ReactGA from 'react-ga';
import { useLocation } from 'react-router-dom';
import { StepBarFiction } from '../fictionCreation/StepBarFiction/StepBarFiction';

import { FictionStep } from '../fictionCreation/FictionStep/FictionStep';
import { EventFictionPreviewStep } from '../fictionCreation/EventFictionPreviewStep/EventFictionPreviewStep';
import { Helmet } from 'react-helmet';
import styles from './FictionModification.module.css';
import {
  postEvent,
  deleteEvent,
  putEvent,
  getEvents,
  getTimeline,
  deleteTimeline,
  getAuthorIdByTimelineId,
} from '../../api/timeline.api';
import { putFiction } from '../../api/fiction.api';
import { Event_Interface } from '../../interfaces/Event.interface';
import { BrowserRouter as Router, Redirect, useHistory, useParams } from 'react-router-dom';
import { NoPublishModal } from '../TimelineModification/Modals/NoPublishModal';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { ConfirmModal } from '../../components/Modal/ConfirmModal/ConfirmModal';

export const FictionModification: React.FunctionComponent = () => {
  const location = useLocation();

  useEffect(() => {
    if (window.location.hostname === 'estory.io') ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  const [type, setType] = useState<string>('');

  const [tone, setTone] = useState<string>('');

  const [title, setTitle] = useState<string>('');

  const [imgUrl, setImgUrl] = useState<string>('');

  const [visible, setVisible] = useState<boolean>(false);

  const [selectedKey, setSelectedKey] = useState(0);

  const { id } = useParams<{ id: string }>();

  const loggedUser = useSelector((state: RootState) => state.loggedUser);

  const [status, setStatus] = useState<string>('in progress');

  const [modalShow, setModalShow] = useState<boolean>(false);

  const [modalConfirmShow, setModalConfirmShow] = useState<boolean>(false);

  const history = useHistory();

  const goToProfilePage = () => {
    const url = `/user/${loggedUser?.id}/${loggedUser?.firstname.replaceAll(
      ' ',
      '-',
    )}-${loggedUser?.lastname.replaceAll(' ', '-')}`;
    history.push(url);
  };

  const goToTimelineModified = () => {
    const url = '/fiction/' + id + '/' + title;
    history.push(url);
  };

  const onSetTitle = (title: string) => {
    setTitle(title);
  };

  const onSetType = (type: string) => {
    setType(type);
  };

  const onSetTone = (tone: string) => {
    setTone(tone);
  };
  const onSetVisible = (visible: boolean) => {
    setVisible(visible);
  };
  const onSetImage = (image: string) => {
    setImgUrl(image);
  };
  const fictionTone = (tone: string) => {
    let toneFiction = tone;
    if (tone === '😃 Happy') {
      toneFiction = 'Happy';
    }
    if (tone === '😢 Sad') {
      toneFiction = 'Sad';
    }
    if (tone === '😱 Scary') {
      toneFiction = 'Scary';
    }
    if (tone === '😮 Surprising') {
      toneFiction = 'Surprising';
    }
    if (tone === '😐 Neutral') {
      toneFiction = 'Neutral';
    }
    return toneFiction;
  };
  const fictionType = (type: string) => {
    let typeFiction = type;
    if (type === '🤠 Adventure') {
      typeFiction = 'Adventure';
    }
    if (type === '💞 Romance') {
      typeFiction = 'Romance';
    }
    if (type === '🤣 Comedy') {
      typeFiction = 'Comedy';
    }
    if (type === '🎭 Drama') {
      typeFiction = 'Drama';
    }
    if (type === '🧐 Mystery') {
      typeFiction = 'Mystery';
    }
    if (type === '🧜‍♀️ Fantasy') {
      typeFiction = 'Fantasy';
    }
    if (type === '🤴 Historical') {
      typeFiction = 'Historical';
    }
    if (type === '😱 Horror') {
      typeFiction = 'Horror';
    }
    if (type === '🤖 Science Fiction') {
      typeFiction = 'Science Fiction';
    }
    return typeFiction;
  };

  const modifyFiction = (
    id: number,
    type: string,
    tone: string,
    title: string,
    imgUrl: string,
    visible: boolean,
    step: number,
  ) => {
    const typeFic = fictionType(type);
    const toneFic = fictionTone(tone);
    putFiction(id, title, typeFic, imgUrl, visible, step, 'in progress', 'fiction', toneFic).then(() =>
      changeStep(numStep),
    );
  };

  const publishFiction = (
    id: number,
    title: string,
    type: string,
    tone: string,
    imgUrl: string,
    visible: boolean,
    step: number,
  ) => {
    const typeFic = fictionType(type);
    const toneFic = fictionTone(tone);
    putFiction(id, title, typeFic, imgUrl, visible, step, 'published', 'fiction', toneFic)
      .then(() => {
        <Router>
          <Redirect
            to={{
              pathname: '/home',
            }}
          />
        </Router>;
      })
      .then(() => {
        if (step === 1) {
          setNumStep(2);
        } else {
          goToTimelineModified();
        }
      });
  };

  /**
   * Change Step
   */
  const [numStep, setNumStep] = useState<number>(1);

  const changeStep = (step: number) => {
    if (step === 1 || step === 2) {
      setNumStep(step + 1);
    }
  };

  const [events, setEvents] = useState<Event_Interface[]>([]);

  const modifyEvent = (event: Event_Interface, index: number) => {
    putEvent(
      Number(event.id),
      event.title,
      event.description,
      event.day,
      event.month,
      Number(event.year),
      event.display_roman_numbers,
      event.x_position,
      event.y_position,
      event.img,
      '',
    ).then((result: Event_Interface) => {
      events[index] = result;
      setEvents(events.slice(0));
    });
  };

  const addEvent = (
    id_timeline: number,
    description: string,
    display_roman_numbers: boolean,
    x_position: number,
    y_position: number,
    img: string,
    year: number,
  ) => {
    postEvent(id_timeline, '', description, 0, 0, year, display_roman_numbers, x_position, y_position, img, '0px').then(
      (event) => {
        events.push(event);
        const sortedEvents = events.sort((a, b) => (a.id > b.id ? 1 : -1));
        setEvents(sortedEvents.slice(0));
        setSelectedKey(sortedEvents.findIndex((e) => e.id === event.id));
      },
    );
  };

  const removeEvent = (id: bigint) => {
    deleteEvent(Number(id)).then(() => {
      const ids = events.map((event) => event.id);
      const index = ids.indexOf(id);
      events.splice(index, 1);
      setEvents(events.slice(0));
      if (selectedKey >= events.length && selectedKey) setSelectedKey(events.length - 1);
    });
  };

  useEffect(() => {
    getAuthorIdByTimelineId(Number(id)).then((idAuthor) => {
      if (idAuthor !== loggedUser?.id) {
        history.push('/home');
      }
    });
    (async () => {
      getEvents(Number(id)).then((events) => {
        setEvents(events);
      });
      getTimeline(Number(id)).then((fiction) => {
        setTitle(fiction.title);
        setType(fiction.category.toString());
        setTone(fiction.tone);
        setImgUrl(fiction.img_background);
        setVisible(fiction.visible);
        setStatus(fiction.status);
      });
    })();
  }, []);

  useEffect(() => {
    if (selectedKey >= events.length && selectedKey) setSelectedKey(events.length - 1);
  }, [events, selectedKey]);

  return (
    <>
      <Helmet>
        <title>eStory | Modify Timeline</title>
      </Helmet>
      <div className={styles.backgroundImage}>
        <div>
          <div className={styles.formContainer}>
            <div className={styles.stepBarContainer}>
              <StepBarFiction stepNumber={numStep} goToStep={setNumStep} />
            </div>
            {numStep === 2 && Number(id) !== null ? (
              <div className={styles.stepContainer2}>
                <EventFictionPreviewStep
                  timelineId={Number(id)}
                  title={title}
                  imgUrl={imgUrl}
                  events={events}
                  selectedKey={selectedKey}
                  setSelectedKey={setSelectedKey}
                  addEvent={addEvent}
                  modifyEvent={modifyEvent}
                  removeEvent={removeEvent}
                  type={fictionType(type)}
                  tone={fictionTone(tone)}
                />
              </div>
            ) : (
              <div className={styles.stepContainer}>
                <FictionStep
                  title={title}
                  onSetTitle={(title) => onSetTitle(title)}
                  onSetType={(type) => onSetType(type)}
                  onSetTone={(tone) => onSetTone(tone)}
                  onSetImage={(image) => onSetImage(image)}
                  imgUrl={imgUrl}
                  visible={visible}
                  onSetVisible={(visible) => onSetVisible(visible)}
                  tones={tone}
                  types={type}
                />
              </div>
            )}
            {numStep == 1 ? (
              <div className={styles.bottomContainer}>
                {id && (
                  <button className={styles.deleteButton} onClick={() => setModalConfirmShow(true)} disabled={!title}>
                    <FontAwesomeIcon icon={faTrashAlt} className={styles.trashIcon} />
                    Delete
                  </button>
                )}
                <button
                  className={styles.button}
                  onClick={() => {
                    if (status === 'Published' || status === 'published') {
                      publishFiction(Number(id), title, type, tone, imgUrl, visible, numStep);
                      setNumStep(2);
                    } else {
                      modifyFiction(Number(id), type, tone, title, imgUrl, visible, numStep);
                    }
                  }}
                  disabled={!title}
                >
                  Save and continue
                </button>
              </div>
            ) : numStep == 2 ? (
              <div className={styles.bottomContainer}>
                {id && (
                  <button className={styles.deleteButton} onClick={() => setModalConfirmShow(true)} disabled={!title}>
                    <FontAwesomeIcon icon={faTrashAlt} className={styles.trashIcon} />
                    Delete
                  </button>
                )}
                <button
                  className={styles.button}
                  onClick={() => {
                    if (Number(id) !== null && events.length > 3) {
                      publishFiction(Number(id), title, type, tone, imgUrl, visible, numStep);
                      goToProfilePage();
                    } else {
                      setModalShow(true);
                    }
                  }}
                >
                  Publish
                </button>
              </div>
            ) : (
              <div className={styles.bottomContainer}>
                {id && (
                  <button className={styles.deleteButton} onClick={() => setModalConfirmShow(true)} disabled={!title}>
                    <FontAwesomeIcon icon={faTrashAlt} className={styles.trashIcon} />
                    Delete
                  </button>
                )}
                <button
                  className={styles.button}
                  onClick={() => {
                    changeStep(numStep);
                  }}
                >
                  Save and continue
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      <ConfirmModal
        show={modalConfirmShow}
        handleClose={() => setModalConfirmShow(false)}
        text={'Are you sure to delete this fiction ?'}
        closeButtonText={'Cancel'}
        confirmButtonText={'Delete'}
        handleConfirm={() =>
          deleteTimeline(Number(id)).then(() =>
            history.push(
              `/user/${loggedUser?.id}/${loggedUser?.firstname.replaceAll(' ', '-')}-${loggedUser?.lastname.replaceAll(
                ' ',
                '-',
              )}`,
            ),
          )
        }
      />
      <NoPublishModal show={modalShow} onHide={() => setModalShow(false)} />
    </>
  );
};
