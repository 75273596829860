import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import styles from './PrivacyPolicy.module.css';

export function PrivacyPolicy(): JSX.Element {
  const location = useLocation();
  useEffect(() => {
    if (window.location.hostname === 'estory.io') ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  return (
    <div>
      <Helmet>
        <title>eStory | Privacy policy</title>
        <meta property="og:type" content="siteweb" />
        <meta name="twitter:title" content="eStory | Privacy policy" />
        <meta
          name="description"
          content="Discover the best timelines on history, inspiring people, sciences, arts, sports and other topics."
        />
        <meta property="og:title" content="eStory | Privacy policy" />
        <meta
          property="og:description"
          content="Discover the best timelines on history, inspiring people, sciences, arts, sports and other topics."
        />
        <meta
          property="og:image"
          content="https://res.cloudinary.com/dk2ghb1pg/image/upload/v1531942668/Logo_eStory_simple_klalco.png"
        />
        <meta property="og:url" content="https://estory.io/explore" />
      </Helmet>
      <div className={styles.container}>
        <div>
          <div>
            <h1 className={styles.title}>Privacy Policy</h1>
          </div>
        </div>
        <h1>Website Visitors</h1>
        <p>
          Like most website operators, eStory collects non-personally-identifying information of the sort that web
          browsers and servers typically make available, such as the browser type, language preference, referring site,
          and the date and time of each visitor request. eStory’s purpose in collecting non-personally identifying
          information is to better understand how eStory’s visitors use its website. From time to time, eStory may
          release non-personally-identifying information in the aggregate, e.g., by publishing a report on trends in the
          usage of its website. eStory also collects potentially personally-identifying information like Internet
          Protocol (IP) addresses. eStory does not use such information to identify its visitors, however, and does not
          disclose such information, other than under the same circumstances that it uses and discloses
          personally-identifying information, as described below.
        </p>

        <h1>Gathering of Personally-Identifying Information</h1>
        <p>
          Certain visitors to eStory’s websites choose to interact with eStory in ways that require eStory to gather
          personally-identifying information. The amount and type of information that eStory gathers depends on the
          nature of the interaction. For example, we ask visitors who sign up for a timeline at
          <a href="/home" target="_blank">
            eStory.io
          </a>
          to provide a username and email address.
          <br />
          If you want to delete your data - contact us at team@estory.io
        </p>

        <h1>Aggregated Statistics</h1>
        <p>
          eStory may collect statistics about the behavior of visitors to its websites. For instance, eStory may monitor
          the most popular timelines on the eStory.io site. eStory may display this information publicly or provide it
          to others. However, eStory does not disclose personally-identifying information other than as described below.
        </p>

        <h1>Protection of Certain Personally-Identifying Information</h1>
        <p>
          eStory discloses potentially personally-identifying and personally-identifying information only to those of
          its employees, contractors and affiliated organizations that (i) need to know that information in order to
          process it on eStory’s behalf or to provide services available at eStory’s websites, and (ii) that have agreed
          not to disclose it to others. Some of those employees, contractors and affiliated organizations may be located
          outside of your home country; by using eStory’s websites, you consent to the transfer of such information to
          them. eStory will not rent or sell potentially personally-identifying and personally-identifying information
          to anyone. Other than to its employees, contractors and affiliated organizations, as described above, eStory
          discloses potentially personally-identifying and personally-identifying information only when required to do
          so by law, or when eStory believes in good faith that disclosure is reasonably necessary to protect the
          property or rights of eStory, third parties or the public at large. If you are a registered user of the eStory
          website and have supplied your email address, eStory may occasionally send you an email to tell you about new
          features, solicit your feedback, or just keep you up to date with what’s going on with eStory and our
          products. We expect to keep this type of email to a minimum. If you send us a request (for example via a
          support email or via one of our feedback mechanisms), we reserve the right to publish it in order to help us
          clarify or respond to your request or to help us support other users. eStory takes all measures reasonably
          necessary to protect against the unauthorized access, use, alteration or destruction of potentially
          personally-identifying and personally-identifying information.
        </p>

        <h1>Cookies</h1>
        <p>
          A cookie is a string of information that a website stores on a visitor’s computer, and that the visitor’s
          browser provides to the website each time the visitor returns. eStory uses cookies to help eStory identify and
          track visitors, their usage of eStory website, and their website access preferences. eStory visitors who do
          not wish to have cookies placed on their computers should set their browsers to refuse cookies before using
          eStory’s websites, with the drawback that certain features of eStory’s websites may not function properly
          without the aid of cookies.
        </p>

        <h1>Advertising</h1>
        <p>
          We use third-party advertising companies to serve ads when you visit our website. These companies may use
          information (not including your name, address, email address, or telephone number) about your visits to this
          and other websites in order to provide advertisements about goods and services of interest to you. If you
          would like more information about this practice and to know your choices about not having this information
          used by these companies,
          <a href="https://affiliate-program.amazon.co.uk/help/operating/agreement" target="_blank" rel="noreferrer">
            click here
          </a>
          .
        </p>
        <h1>Privacy Policy Changes</h1>
        <p>
          Although most changes are likely to be minor, eStory may change its Privacy Policy from time to time, and in
          eStory’s sole discretion. eStory encourages visitors to frequently check this page for any changes to its
          Privacy Policy. Your continued use of this site after any change in this Privacy Policy will constitute your
          acceptance of such change.
        </p>
      </div>
    </div>
  );
}
