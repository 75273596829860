import React from 'react';

export interface NavigationIndicatorPreviewProps {
  keyNumber: number;
  isSelected: boolean;
  isBeforeSelected: boolean;
  left: number;
  onClick: ((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void) | undefined;
  onHover: ((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void) | undefined;
}

export const NavigationIndicatorPreview: React.FunctionComponent<NavigationIndicatorPreviewProps> = (
  props: NavigationIndicatorPreviewProps,
) => {
  return (
    <div
      key={props.keyNumber}
      style={{
        borderRadius: '50%',
        position: 'absolute',
        left: `${props.left}px`,
        backgroundColor: props.isSelected || props.isBeforeSelected ? '#208775' : '#F8F8F8',
        width: '20px',
        height: '20px',
        transform: 'translateY(-50%)',
        cursor: 'pointer',
        zIndex: 1,
      }}
      onClick={props.onClick}
      onMouseEnter={props.onHover}
      onMouseLeave={props.onHover}
    ></div>
  );
};
