import pictureConfucius from '../assets/img/famousPeople/Confucius.png';
import pictureAbrahamLincoln from '../assets/img/famousPeople/Abraham Lincoln.png';
import picturePlautus from '../assets/img/famousPeople/Plautus.png';
import pictureJeanJacquesRousseau from '../assets/img/famousPeople/Jean-Jacques Rousseau.png';
import pictureMahatmaGandhi from '../assets/img/famousPeople/Mahatma Gandhi.png';
import pictureSimoneVeil from '../assets/img/famousPeople/Simone Veil.png';
import pictureWilliamShakespeare from '../assets/img/famousPeople/William Shakespeare.png';

export const patienceQuotes = [
  { author: 'Abraham Lincoln', quote: 'I am a slow Walker, but i never walk back.' },
  { author: 'Confucius', quote: 'It does not matter how slowly you go as long as you do not stop' },
  { author: 'Plautus', quote: 'Patience is the best remedy for every trouble' },
  { author: 'Jean-Jacques Rousseau', quote: 'Patience is bitter, but its fruit is sweet' },
  { author: 'Mahatma Gandhi', quote: 'To lose patience is to lose the battle.' },
  { author: 'Simone Veil', quote: 'Humility is attentive patience' },
  { author: 'William Shakespeare', quote: 'How poor are they that have not patience' },
];

const pictureMap: { [key: string]: string } = {
  Confucius: pictureConfucius,
  'Abraham Lincoln': pictureAbrahamLincoln,
  Plautus: picturePlautus,
  'Jean-Jacques Rousseau': pictureJeanJacquesRousseau,
  'Mahatma Gandhi': pictureMahatmaGandhi,
  'Simone Veil': pictureSimoneVeil,
  'William Shakespeare': pictureWilliamShakespeare,
};

export const getImageFromAuthor = (author: string) => pictureMap[author] || '';
