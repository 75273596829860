import React, { ReactElement } from 'react';
import { Timeline_Interface } from '../../../interfaces/Timeline.interface';
import styles from './SearchDropDown.module.css';

export type SearchResultProps = {
  timeline: Timeline_Interface;
};

export const SearchResultTimeline: React.FC<SearchResultProps> = (props: SearchResultProps): ReactElement => {
  return (
    <div className={styles.searchResult}>
      <img
        className={`${styles.searchResultTimelineImage} ${styles.searchResultImage}`}
        style={{ backgroundImage: `url(${props.timeline.img_background})` }}
      />
      <div className={styles.searchResultTextContainer}>
        <div className={styles.searchResultText}>{props.timeline.title}</div>
        <div className={styles.searchResultText}>
          By {props.timeline.UserModels[0]?.firstname} {props.timeline.UserModels[0]?.lastname}
        </div>
      </div>
    </div>
  );
};
