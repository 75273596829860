import { Event_Interface } from '../interfaces/Event.interface';

export class Event implements Event_Interface {
  id: bigint;
  title: string;
  description: string;
  day: number;
  month: number;
  year: bigint;
  display_roman_numbers: boolean;
  x_position: number;
  y_position: number;
  img: string;
  img_offset: string;
  event_created_at: Date;
  event_updated_at: Date;

  constructor(
    id: bigint,
    title: string,
    description: string,
    day: number,
    month: number,
    year: bigint,
    display_roman_numbers: boolean,
    x_position: number,
    y_position: number,
    img: string,
    img_offset: string,
    event_created_at: Date,
    event_updated_at: Date,
  ) {
    this.id = id;
    this.title = title;
    this.description = description;
    this.day = day;
    this.month = month;
    this.year = year;
    this.display_roman_numbers = display_roman_numbers;
    this.x_position = x_position;
    this.y_position = y_position;
    this.img = img;
    this.img_offset = img_offset;
    this.event_created_at = event_created_at;
    this.event_updated_at = event_updated_at;
  }
}
