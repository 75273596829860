import React, { useState, useEffect } from 'react';
import style from './RequestTimeline.module.css';
import wand from '../../../assets/img/wand_white.png';
import { setLoggedUserActionCreator } from '../../../reducers/LoggedUser';
import { useDispatch } from 'react-redux';
import { getTimelineGeneratedWithOpenAI } from '../../../api/timeline.api';
import { self } from '../../../api/user.api';
import { TokensModal } from '../../../components/Modal/TokensModal/TokensModal';
import { RootState } from '../../../store/store';
import { useSelector } from 'react-redux';
import { getUser } from '../../../api/user.api';

export type RequestTimelineProps = {
  setTimelineId: (id: number) => void;
  setTitle: (title: string) => void;
  setCategories: (categories: string[]) => void;
  setVisibility: (visible: boolean) => void;
  setStep: (step: number) => void;
  setImgUrl: (imgUrl: string) => void;
  events: any[];
  setEvents: (events: any[]) => void;
  setLoading: (loading: boolean) => void;
};

export const RequestTimeline: React.FunctionComponent<RequestTimelineProps> = (props: RequestTimelineProps) => {
  const [requestText, setRequestText] = useState('');
  const dispatch = useDispatch();
  const loggedUser = useSelector((state: RootState) => state.loggedUser);
  const [modalShow, setModalShow] = useState(false);

  const handleGenerateRequest = async () => {
    if (requestText === '') {
      return;
    }
    await getUser(Number(loggedUser?.id)).then(async (result) => {
      if (Number(result.nb_tokens) > 0) {
        props.setLoading(true);
        getTimelineGeneratedWithOpenAI(requestText).then((res) => {
          props.setTimelineId(Number(res.timeline.id));
          props.setTitle(res.timeline.title);
          props.setCategories(res.timeline.category);
          props.setVisibility(res.timeline.visible);
          props.setStep(res.timeline.step);
          props.setImgUrl(res.timeline.img_background);
          for (const event of res.events) {
            props.events.push(event);
            const sortedEvents = props.events.sort((a, b) => (a.year > b.year ? 1 : -1));
            props.setEvents(sortedEvents.slice(0));
          }
          self().then((loggedUser) => dispatch(setLoggedUserActionCreator(loggedUser)));
          props.setLoading(false);
        });
        setRequestText('');
      } else {
        setModalShow(true);
        return;
      }
    });
  };

  return (
    <>
      {modalShow ? (
        <TokensModal
          show={modalShow}
          handleClose={() => setModalShow(false)}
          handleConfirm={() => setModalShow(false)}
        />
      ) : (
        <div className={style.input_container}>
          <label className={style.input_label}>Would you like to generate the timeline using your ✨ AI tokens ?</label>
          <div className={style.input_generate}>
            <input
              placeholder="ex : Write a timeline about the World War 2 with a special focus on the role of France"
              type="text"
              value={requestText}
              onChange={(e) => setRequestText(e.target.value)}
              className={style.input}
              name="text"
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleGenerateRequest();
                }
              }}
            />
            <button className={style.button_generate} onClick={handleGenerateRequest}>
              ✨ Generate
            </button>
          </div>
        </div>
      )}
    </>
  );
};
