import React, { useState, useEffect } from 'react';
import ReactGA from 'react-ga';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import styles from './ForgotPassword.module.css';
import { Button } from 'react-bootstrap';
import { forgotPassword } from '../../api/auth.api';
import { useHistory } from 'react-router-dom';

export function ForgotPassword(): JSX.Element {
  const location = useLocation();
  useEffect(() => {
    if (window.location.hostname === 'estory.io') ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  const [email, setEmail] = useState<string>('');
  const [error, setError] = useState<string>('');

  const history = useHistory();

  return (
    <div className={styles.backgroundImage}>
      <Helmet>
        <title>eStory | Forgot password</title>
      </Helmet>
      <div className={styles.log}>
        <h2>Write your email to choose a new password:</h2>
        <div className={styles.field}>
          <input
            className={styles.input}
            type="text"
            name="email"
            id="email"
            required
            value={email}
            onChange={(event) => setEmail(event.target.value)}
          />
          <label htmlFor="email" className={styles.label}>
            Email
          </label>
        </div>
        {error && (
          <div className={styles.errorContainer}>
            <div className={styles.error}>{error}</div>
          </div>
        )}
        <div className={styles.buttonHolder}>
          <a>
            <Button
              className={styles.button}
              variant="success"
              onClick={() => {
                if (email) {
                  forgotPassword(email).then(
                    () => history.push('/password-email-sent'),
                    (error) => (error.response.status === 409 ? setError('No user registered with this email') : null),
                  );
                  return;
                }
                setError('You should type an email before try to send it');
              }}
            >
              Send
            </Button>
          </a>
        </div>
      </div>
    </div>
  );
}
