import React, { useState } from 'react';
import styles from './ChooseRoleOther.module.css';
import { UserRole } from '../../models/UserRole.model';
import { Button } from 'react-bootstrap';
import { updateUserRole } from '../../api/user.api';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { useHistory } from 'react-router-dom';
import festivalEmoji from '../../assets/img/emoji_party.png';

export function ChooseRoleOther(): JSX.Element {
  const loggedUser = useSelector((state: RootState) => state.loggedUser);

  const [role, setRole] = useState<string>('');
  const history = useHistory();

  const handleSave = () => {
    if (loggedUser) {
      updateUserRole(loggedUser.id, role);
      history.push('/description');
    }
  };

  return (
    <>
      <div className={styles.header}>
        <div className="mb-4">
          <h1 className={styles.textTitle}>
            Welcome to eStory!
            <span>
              <img src={festivalEmoji} height="50" />
            </span>
          </h1>
        </div>
        <div className={styles.progressBar}>
          <div className={styles.progression}></div>
        </div>
        <div className="mb-4">
          <h3 className={styles.textBold}>What do you need timelines for? </h3>
        </div>

        <div className="container">
          <div className={styles.blockCheck}>
            <div className={styles.checkRow}>
              <label className={styles.customradiobtn}>
                <input type="radio" name="sample" onChange={() => setRole(UserRole.OtherPerso)} />
                <span className={styles.checkmark}></span>
              </label>
              <h3>A personal project </h3>
            </div>
            <div className={styles.checkRow}>
              <label className={styles.customradiobtn}>
                <input type="radio" name="sample" onChange={() => setRole(UserRole.OtherPro)} />
                <span className={styles.checkmark}></span>
              </label>
              <h3>A professional project </h3>
            </div>
          </div>
        </div>
        <div>
          <Button
            disabled={role === ''}
            className={styles.button}
            onClick={() => handleSave()}
            style={{ borderRadius: '15px', background: '#428576', borderColor: 'transparent', fontSize: '25px' }}
          >
            Continue
          </Button>
        </div>
      </div>
    </>
  );
}
